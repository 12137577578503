<template>
  <v-app
    style="background-repeat: no-repeat;
    background-size: auto 100%; background-color: var(--v-background-base)"
    v-bind:style="{ 'background-image': `url(${require('@/assets/images/project/bg.svg')})` }"
  >
    <v-main style="min-width: 300px">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style></style>
