require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#5577fd',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        bgContainer: '#eae9e9',
        backgroundPrCard: '#2F3241',
        backgroundSdCard: '#efefef',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        gray3: '#828282',
      },
      dark: {
        primary: '#1966F7',
        accent: '#1174ff',
        secondary: '#8A8D93',
        bgContainer: '#1E1F25',
        bgListItem: '#2F3241',
        backgroundPrCard: '#2F3241',
        backgroundSdCard: '#2b2641',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        gray2: '#efefef',
        gray3: '#828282',
        background: '#000621',
      },
    },
  },
}
