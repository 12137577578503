export default {
  namespaced: true,
  state: {
    base64Image: undefined,
    savedLayers: [],
    compare: false,
    currentMap: undefined,
    legends: [],
    currentLayerId: undefined,
    currentBaseMap: {
      avatar: require('@/assets/images/map/mapboxbg.png'),
      name: 'Mapbox Satellite',
      id: 'mapbox-satellite',
    },
  },

  mutations: {},
}
