<template>
  <v-menu
    content-class="user-profile-menu-content"
    style="z-index: 1000;"
    left
    min-width="230"
    nudge-bottom="14"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom class="ms-4" color="success" dot offset-x="12" offset-y="12" overlap>
        <v-avatar size="40px" v-bind="attrs" v-on="on">
          <img :src="currentUser.avatar || AvatarImg" />
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2" style="cursor: pointer" @click="goToSettingAccount">
        <v-badge bottom class="ms-4" color="success" dot offset-x="12" offset-y="12" overlap>
          <v-avatar size="40px">
            <img :src="currentUser.avatar || AvatarImg" />
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align:middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ currentUser.name }}
          </span>
          <small class="text--disabled">{{ currentUser.email }}</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>
      <v-list-item  v-if="currentUser.organization && currentUser.role === 'admin'" link @click="() => $router.push('/users')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-account-multiple
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Users Management</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            icon-logout
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { mapState } from '@/store/ults'
import { authLogout } from '@/api/auth-api'
import AvatarImg from '@/assets/images/user/avatar.png'

export default {
  setup() {
    return {
      AvatarImg,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    goToSettingAccount() {
      this.$store.commit('auth/SET_CURRENT_TAB', 0) // go to account tab
      // TODO: check if the current route is already the account settings page: for all router push
      const path = '/pages/account-settings'
      if (path != this.$router.currentRoute.fullPath) this.$router.push(path)
    },
    async logout() {
      try {
        await authLogout()
      } catch (e) {
      } finally {
      }
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
