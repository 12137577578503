<template>
  <v-layout>
    <v-tooltip v-if="isTruncated" v-bind:[position]="true">
      <template v-slot:activator="{ on, attrs }">
        <div class="text-truncate" :style="styles" ref="textElement" v-bind="attrs" v-on="on">
          <slot name="truncate">
            {{ title }}
          </slot>
          </div>
      </template>
      <slot name="description">
        <span>{{ title }}</span>
      </slot>
    </v-tooltip>
    <div v-else ref="textElement" :style="styles" class="no-w text-truncate">
      <slot name="truncate">
        {{ title }}
      </slot>
    </div>
  </v-layout>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    triggerChange: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: String,
      default: 'bottom',
      validator(value) {
        return ['top', 'bottom', 'left', 'right'].includes(value);
      }
    }
  },
  data() {
    return {
      isTruncated: false
    }
  },
  mounted() {
    this.checkTruncation();
  },
  methods: {
    checkTruncation() {
      const element = this.$refs.textElement
      this.isTruncated = element.scrollWidth > element.clientWidth
    }
  },
  watch: {
    title() {
      this.$nextTick(() => {
        this.checkTruncation()
      })
    },
    triggerChange() {
      this.$nextTick(() => {
        this.checkTruncation()
      })
    }
  }
}
</script>
<style scoped>
.text-truncate {
  width: fit-content;
  line-height: 20px;
}
.no-w {
  text-wrap: nowrap;
}
</style>
