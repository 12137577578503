<template>
  <div class="handle-find">
    <v-row>
      <v-col cols="10">
        <v-text-field v-model="search" style="width: 100%;" append-icon="mdi-magnify" dense hide-details
          label="Search by descriptions" outlined color="primary"
          @input="searchData" @click.stop></v-text-field>
      </v-col>
      <v-col cols="2" class="pt-4">
        <FilterNotification :listFilters="listFilters" @filterData="searchData"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import FilterNotification from './filter-notification.vue'
import FILTER_NOTIFICATIONS from '@/constants/filter-notification';

export default {
  components: {
    FilterNotification
  },
  computed: {
    search: {
      get() {
        return this.$store.getters['notification/searchNotification']
      },
      set(value) {
        this.$store.commit('notification/SET_STATE_PROPERTY', {property: 'searchNotification', value})
      }
    }
  },
  data: () => {
    return {
      listFilters: FILTER_NOTIFICATIONS
    }
  },
  methods: {
    searchData: debounce(function (){
      this.$emit('searchData')
    }, 500),
  }
}
</script>
