import { cloneDeep } from 'lodash'
import notificationApi from '@/api/notification-api'
import { getMenus } from '@/api/menu-api'

export default {
  namespaced: true,
  state: {
    listNotifications: [],
    currentDataPageNotifications: {},
    unreadNotificationsCountAll: undefined,
    searchNotification: '',
    filterNotification: {},
    alertNotification: {},
    perPage: 30,
    currentTab: 0,
    selectedEvent: null,
    menuServices: {},
    currentQuery: {},
    isOpenChildElement: false,
    timer: undefined,
  },
  getters: {
    isOpenChildElement: state => state.isOpenChildElement,
    currentQuery: state => state.currentQuery,
    menuServices: state => state.menuServices,
    selectedEvent: state => state.selectedEvent,
    currentTab: state => state.currentTab,
    perPage: state => state.perPage,
    listNotifications: state => state.listNotifications,
    unreadNotificationsCountAll: state => state.unreadNotificationsCountAll,
    alertNotification: state => state.alertNotification,
    listUnreadNotifications(state) {
      return state.listNotifications.filter(notification => !notification.read)
    },
    listAlertNotifications(state) {
      return state.listNotifications.filter(notification => notification.is_alert)
    },
  },
  mutations: {
    SET_STATE_PROPERTY(state, { property, value }) {
      state[property] = value
    },
    SET_CURRENT_DATA_PAGE(state, { property, dataPage }) {
      const tempData = cloneDeep(dataPage)
      delete tempData.data
      state[property] = tempData
    },

    APPEND_LIST_NOTIFICATIONS(state, { property, newNotifications }) {
      let oldNotifications = state[property]

      const uniqueValuesMap = new Map()
      oldNotifications.forEach(notification => {
        uniqueValuesMap.set(notification.uuid, notification)
      })
      newNotifications.forEach(notification => {
        uniqueValuesMap.set(notification.uuid, notification)
      })
      const mergedNotifications = Array.from(uniqueValuesMap.values())

      state[property] = mergedNotifications
    },
  },
  actions: {
    async getNotification({ state }, uuid) {
      let notification = state.listNotifications.find(notification => notification.uuid == uuid)
      if (!notification) {
        const res = await notificationApi.getNotification(uuid)
        notification = res.data
      }
      return notification
    },
    async getUnreadNotificationsCount({ commit }) {
      try {
        const result = await notificationApi.getUnreadNotificationsCount()
        commit('SET_STATE_PROPERTY', { property: 'unreadNotificationsCountAll', value: result.data.data })
      } catch (e) {
        console.log('error when get unread notifications count: ', e)
      }
    },
    async getListNotifications({ state, commit }, params = {}) {
      params = {
        unread: state.currentTab == 1 ? true : false,
        alert: state.currentTab == 2 ? true : false,
        search: state.searchNotification,
        per_page: state.perPage,
        ...state.filterNotification,
        ...params,
      }
      const result = await notificationApi.getListNotifications(params)
      // check alert notification - TODO: if alert not in page ??
      const alertNoti = result.data.data.find(notification => notification.is_alert && !notification.read)
      if (alertNoti) {
        commit('SET_STATE_PROPERTY', { property: 'alertNotification', value: alertNoti })
      } else {
        commit('SET_STATE_PROPERTY', { property: 'alertNotification', value: {} })
      }
      commit('SET_STATE_PROPERTY', { property: 'listNotifications', value: result.data.data })
      commit('SET_CURRENT_DATA_PAGE', { property: 'currentDataPageNotifications', dataPage: result.data })
    },
    async getMoreNotifications({ state, commit }, params = {}) {
      params = {
        unread: state.currentTab == 1 ? true : false,
        alert: state.currentTab == 2 ? true : false,
        search: state.searchNotification,
        per_page: state.perPage,
        page: state.currentDataPageNotifications?.current_page + 1 || 1,
        ...state.filterNotification,
        ...params,
      }
      const result = await notificationApi.getListNotifications(params)
      commit('APPEND_LIST_NOTIFICATIONS', { property: 'listNotifications', newNotifications: result.data.data })
      commit('SET_CURRENT_DATA_PAGE', { property: 'currentDataPageNotifications', dataPage: result.data })
      return result.data.data
    },

    async fetchNotifications({ state, dispatch }) {
      // TODO: improve each of this: handle
      if (state.timer) clearTimeout(state.timer)
      state.timer = setTimeout(() => {
        dispatch('getUnreadNotificationsCount')
        dispatch('getListNotifications')
        dispatch('project/getListProjects', { per_page: 'all', sort: '-starred,name' }, { root: true })
      }, 1000)
    },

    async markAllAsRead({ state, commit, dispatch }) {
      const newListNotifications = state.listNotifications.map(notification => {
          notification.read = true
          return notification
        }
      )
      commit('SET_STATE_PROPERTY', { property: 'listNotifications', value: newListNotifications })
      commit('SET_STATE_PROPERTY', { property: 'unreadNotificationsCountAll', value: 0 })
      await notificationApi.markAllAsRead()
      dispatch('fetchNotifications')
    },
    async markAsRead({ state, commit, dispatch }, notificationId) {
      const newListNotifications = state.listNotifications.map(notification => {
          if (notification.uuid == notificationId) {
            notification.read = true
          }
          return notification
        }
      )
      commit('SET_STATE_PROPERTY', { property: 'listNotifications', value: newListNotifications })
      commit('SET_STATE_PROPERTY', { property: 'unreadNotificationsCountAll', value: state.unreadNotificationsCountAll - 1 })
      await notificationApi.markAsRead(notificationId)
      dispatch('fetchNotifications')
    },
    async markAsUnread({ state, commit, dispatch }, notificationId) {
      const newListNotifications = state.listNotifications.map(notification => {
          if (notification.uuid == notificationId) {
            notification.read = false
          }
          return notification
        }
      )
      commit('SET_STATE_PROPERTY', { property: 'listNotifications', value: newListNotifications })
      commit('SET_STATE_PROPERTY', { property: 'unreadNotificationsCountAll', value: state.unreadNotificationsCountAll + 1 })
      await notificationApi.markAsUnread(notificationId)
      dispatch('fetchNotifications')
    },
    async deleteNotification({ state, commit, dispatch }, notificationId) {
      const newListNotifications = state.listNotifications.filter(notification => {
        if (notification.uuid == notificationId && !notification.read) {
          commit('SET_STATE_PROPERTY', { property: 'unreadNotificationsCountAll', value: state.unreadNotificationsCountAll - 1 })
        }
        return notification.uuid !== notificationId
      })
      commit('SET_STATE_PROPERTY', { property: 'listNotifications', value: newListNotifications })
      await notificationApi.delete(notificationId)
      dispatch('fetchNotifications')
    },

    // services
    async getMenuServices({ commit }) {
      const res = await getMenus({ per_page: 'all' })
      const menus = res.data
      const menuServices = {}
      menus.forEach(menu => {
        if (!menu.services) return
        menu.services.forEach(service => {
          menuServices[service] = menu.code
        })
      })
      commit('SET_STATE_PROPERTY', { property: 'menuServices', value: menuServices })
    }
  },
}
