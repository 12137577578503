<template>
  <v-form ref="formData" v-model="valid" onSubmit="return false;" style="height: 100%">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout column>
      <v-text-field v-model="name" :rules="[rules.required]" label="Name" outlined placeholder="Name"></v-text-field>
      <v-layout>
        <div style="flex: 1">
          <v-select
            v-model="form.order_info.offeringSku"
            :items="offerings"
            :rules="[rules.required]"
            item-text="name"
            item-value="sku"
            label="Offering"
            outlined
            placeholder="Offering"
          ></v-select>
        </div>
      </v-layout>
      <div class="mb-4">
        <v-layout>
          <div style="flex: 1">
            <v-text-field
              v-model.number="coordinates[1]"
              :dense="false"
              class="mr-2"
              label="Latitude"
              outlined
              type="number"
              @change="changeCoordinate"
            ></v-text-field>
          </div>
          <div style="flex: 1">
            <v-text-field
              v-model.number="coordinates[0]"
              :dense="false"
              label="Longitude"
              outlined
              type="number"
              @change="changeCoordinate"
            ></v-text-field>
          </div>
        </v-layout>
      </div>
      <v-layout>
        <div style="flex: 1">
          <v-select
            v-model="form.order_info.offeringParamValues.productType"
            :items="products"
            disabled
            item-text="name"
            item-value="value"
            label="Product"
            outlined
            placeholder="Product"
            readonly
          ></v-select>
        </div>
      </v-layout>
      <v-layout align-center @click="toi = !toi">
        <div class="px-3">TIME OF INTEREST</div>
        <v-divider />
        <v-btn icon small>
          <v-icon>{{ toi ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-layout>
      <v-layout v-show="toi" class="pa-4" column>
        <v-layout column>
          <v-layout>
            <div class="mr-1" style="flex: 1">
              <DatePicker
                v-model="form.order_info.lowerEndpoint"
                :dense="false"
                :min="convertDate(new Date())"
                label="Starts time"
                @input="changeStartTime"
              ></DatePicker>
            </div>
            <div class="ml-1" style="flex: 1">
              <DatePicker
                v-model="form.order_info.upperEndpoint"
                :dense="false"
                :min="
                  convertDate(
                    new Date(form.order_info.lowerEndpoint).setDate(
                      new Date(form.order_info.lowerEndpoint).getDate() + 1,
                    ),
                  )
                "
                label="Ends time"
              ></DatePicker>
            </div>
            <div class="ml-1" style="flex: 1">
              <v-select
                v-model="form.order_info.frequency"
                :items="freqs"
                class="ml-2"
                item-text="name"
                item-value="value"
                label="Frequency"
                outlined
                placeholder="Frequency"
              ></v-select>
            </div>
          </v-layout>
        </v-layout>
      </v-layout>
      <v-layout align-center @click="advSetting = !advSetting">
        <div class="px-3">ADVANCED PRODUCT SETTING</div>
        <v-divider />
        <v-btn icon small>
          <v-icon>{{ advSetting ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-layout>
      <v-layout v-show="advSetting" class="pa-4" column>
        <v-layout column>
          <v-layout v-for="(set, ind) in advSettings" :key="ind" column>
            <v-checkbox v-model="set.status" :label="set.name" dense hide-details></v-checkbox>
            <v-layout v-if="set.status" align-center class="pt-7">
              <div class="mr-3 pl-4" style="flex: none">
                {{ set.min }}
              </div>
              <v-slider
                v-if="!set.range"
                v-model="set.value"
                :max="set.max"
                :min="set.min"
                :step="set.step"
                hide-details
                thumb-label="always"
                thumb-size="28"
                ticks
              ></v-slider>
              <v-range-slider
                v-else
                v-model="set.value"
                :max="set.max"
                :min="set.min"
                :step="set.step"
                class="align-center"
                hide-details
                thumb-label="always"
                thumb-size="28"
                ticks
              />
              <div class="ml-3" style="flex: none">
                {{ set.max }}
              </div>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
  </v-form>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import sleep from '@/utils/sleep'
import DatePicker from '@/components/DatePicker.vue'
import planetTaskingApi from '@/utils/planet/planetTaskingApi'
import ConvertDate from '@/utils/convertDate'
import turf from 'turf'
import BlackskyTasking from '@/utils/blacksky/blackskyTasking'

export default {
  components: { DatePicker, CardMapView, DialogHeader },
  data() {
    return {
      coordinates: [],
      valid: false,
      loading: false,
      offerings: [],
      toi: true,
      advSetting: false,
      requirement: true,
      angles: true,
      is_ref: true,
      days: [
        { name: 'Monday', value: 'Mo' },
        { name: 'Tuesday', value: 'Tu' },
        { name: 'Wednesday', value: 'We' },
        { name: 'Thursday', value: 'Th' },
        { name: 'Friday', value: 'Fr' },
        { name: 'Saturday', value: 'Sa' },
        { name: 'Sunday', value: 'Su' },
        { name: 'Day', value: 'Mo, Tu, We, Th, Fr, Sa, Su' },
      ],
      freqs: [
        { name: 'Once', value: 'ONCE' },
        { name: 'Once per day', value: 'DAILY' },
        { name: 'Once per week', value: 'WEEKLY' },
        { name: 'Every two weeks', value: 'BIWEEKLY' },
        { name: 'Every three weeks', value: 'TRIWEEKLY' },
        { name: 'Monthly', value: 'MONTHLY' },
        { name: 'All available opportunities', value: 'ALL_INCLUSIVE' },
      ],
      products: [
        {
          name: 'Daytime',
          value: 'STANDARD',
        },
        {
          name: 'Area 2x1',
          value: 'AREA',
        },
        {
          name: 'Burst',
          value: 'BURST',
        },
        {
          name: '2-Frame Stereo',
          value: 'STEREO',
        },
      ],
      advSettings: [
        {
          name: 'Off-nadir angle',
          pro: 'offNadirAngleDegrees',
          value: [0, 30],
          tooltip: 'Angle between the sub-satellite point, satellite, and target',
          status: false,
          min: 0,
          max: 30,
          step: 5,
          range: true,
        },
        {
          name: 'Maximum cloud cover',
          pro: 'maxCloudPercent',
          value: 30,
          tooltip: 'Maximum allowable cloud cover in percent',
          status: false,
          min: 30,
          max: 100,
          step: 10,
          range: false,
        },
        {
          name: 'Sun elevation',
          pro: 'sunElevation',
          value: [-4, 90],
          tooltip:
            'Angle between the target ground plane and Sun. Images collected in low-light conditions have quality outside standard specifications.',
          status: false,
          min: -4,
          max: 90,
          step: 1,
          range: true,
        },
        {
          name: 'Satellite elevation',
          pro: 'satelliteElevation',
          value: [60, 90],
          tooltip: 'Angle between the target ground plane and satellite',
          status: false,
          min: 60,
          max: 90,
          step: 5,
          range: true,
        },
        {
          name: 'Satellite azimuth',
          pro: 'satelliteAzimuth',
          value: [0, 360],
          tooltip: 'Angle between True North, target, and sub-satellite point (clockwise)',
          status: false,
          min: 0,
          max: 360,
          step: 1,
          range: true,
        },
        {
          name: 'Sun azimuth',
          pro: 'sunAzimuth',
          value: [0, 360],
          tooltip: 'Angle between True North, target, and sub-Sun point (clockwise)',
          status: false,
          min: 0,
          max: 360,
          step: 1,
          range: true,
        },
        {
          name: 'Minimum eNIIRS',
          pro: 'minimumNiirsScore',
          value: 3,
          tooltip:
            'NIIRS Score is a function of the GSD, Signal-to-Noise Ratio (SNR), and Relative Edge Response (RER) of an image. This NIIRS constraint will use a predicted SNR and RER based off an aggregation of previously taken and analyzed images.',
          status: false,
          min: 3,
          max: 4,
          step: 0.1,
          range: false,
        },
      ],
      name: '',
      form: {
        blacksky_tasking_key: BlackskyTasking.getApikey(),
        order_info: {
          lowerEndpoint: ConvertDate.dateFormatted(new Date()),
          upperEndpoint: ConvertDate.dateFormatted(new Date().setDate(new Date().getDate() + 1)),
          frequency: 'ONCE',
          offeringSku: '',
          offeringParamValues: {
            productType: 'STANDARD',
          },
        },
      },
      rules: {
        required: value => !!value || 'Item is required',
      },
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    // The current geometry is referenced by a Change Detection Result
  },
  mounted() {
    this.initData()
  },
  watch: {},
  computed: {
    currentData: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      },
    },
  },
  methods: {
    changeCoordinate() {
      this.$emit('addDrawToMap', {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: this.coordinates,
              type: 'Point',
            },
          },
        ],
      })
    },
    convertDate(date) {
      return ConvertDate.dateFormatted(date)
    },
    transformDate(date) {
      return ConvertDate.localDateString(date)
    },
    changeStartTime() {
      if (
        ConvertDate.dateFormatted(
          new Date(this.form.order_info.lowerEndpoint).setDate(
            new Date(this.form.order_info.lowerEndpoint).getDate() + 1,
          ),
        ) > this.form.order_info.upperEndpoint
      ) {
        this.form.order_info.upperEndpoint = ConvertDate.dateFormatted(
          new Date(this.form.order_info.lowerEndpoint).setDate(
            new Date(this.form.order_info.lowerEndpoint).getDate() + 1,
          ),
        )
      }
    },
    async initData() {
      await this.getOffering()
      await sleep(10)
      if (!this.currentData.geojson) return null
      if (this.currentData.geojson.features[0].geometry.type.includes('Point')) {
        this.$emit('addDrawToMap', this.currentData.geojson)
        this.coordinates = this.currentData.geojson.features[0].geometry.coordinates
      } else {
        this.coordinates = turf.center(this.currentData.geojson).geometry.coordinates
        this.$emit('addDrawToMap', {
          type: 'FeatureCollection',
          features: [turf.center(this.currentData.geojson)],
        })
      }
    },
    async displayMap() {
      await sleep(250)
      this.showMap = true
      await sleep(350)
      this.$emit('addDrawToMap', this.currentData.geojson)
    },
    async getOffering() {
      try {
        this.loading = true
        let res = await BlackskyTasking.getOffering()
        this.offerings = res.data.data
        this.form.order_info.offeringSku = res.data.data.length ? res.data.data[0].sku : undefined
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeProduct() {},
    async getPreview() {
      try {
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getParam() {
      let params = {
        image_source: null,
        ref_id: this.currentData.id,
        ref_type: this.currentData.dataType,
        ref_properties: null,
        name: this.name,
        geometry: {},
      }
      let tmpForm = JSON.parse(JSON.stringify(this.form))
      params = { ...params, ...tmpForm }
      this.advSettings.forEach(set => {
        if (set.status) params.order_info.offeringParamValues[set.pro] = set.value
      })
      return params
    },
    addHours(date, hours, minutes = 0, seconds = 0) {
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000 + 1000 * 60 * minutes + 1000 * seconds))
      return date
    },
    getData() {
      try {
        this.loading = true
        this.$refs.formData.validate()
        if (!this.valid) return
        return this.getParam()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
