import { getServices } from '@/api/services'

export default {
  namespaced: true,
  state: {
    services: [],
    projections: [],
    firstLoad: true,
    sources:[],
    source:'Sentinel2 L2A'
  },
  getters: {
    services(state) {
      return state.services
    },
    projections(state) {
      return state.projections
    },
    firstLoad(state) {
      return state.firstLoad
    },
  },
  mutations: {
    SET_PROPERTY_VALUE(state, { property, value }) {
      state[property] = value
    },
    SET_SOURCES(state, sources) {
      state.sources = sources;
      state.source = sources[0]
    },
  },
  actions: {
    async getListServices({ commit }, payload={}) {
      try {
        const res = await getServices(payload)
        commit('SET_PROPERTY_VALUE', { property: 'services', value: res.data })
      } catch(e) {
        console.warn('Error when fetching services', e)
      }
    }
  }
}
