<template>
  <div class="chat-gpt">
    <div class="icon-platform clickable" @click="openChatBox">
      <v-tooltip bottom color="#000000c4">
        <template #activator="{ on, attrs }">
          <v-img :src="sourceIconChat" width="26" v-on="on" v-bind="attrs"></v-img>
        </template>
        <span>Platform Assistant</span>
      </v-tooltip>

      <ChatgptBox ref="dialogChatBox" :sourceLogo="sourceLogo"/>
    </div>
  </div>
</template>
<script>
import ChatgptBox from './ChatgptBox.vue'

export default {
  components: {
    ChatgptBox
  },
  data() {
    return {
      expand: false,
      showChat: false,
      sourceLogo: require('@/assets/images/logos/logo.svg'),
      sourceIconChat: require('@/assets/svg/chat-ai.svg')
    }
  },
  methods: {
    openChatBox() {
      this.$refs.dialogChatBox.open()
      this.$store.dispatch('AOI/getListAllAOI', { projectId: this.$route.params.id })
    }
  },
}
</script>
<style scoped lang="scss">
.chat-gpt {
  margin-right: -6px;
  color: white;
  .icon-platform {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 12px;
      margin-left: 8px;
      text-wrap: nowrap;
    }
  }
}
.clickable:hover {
  filter: brightness(1) !important;
}
</style>
