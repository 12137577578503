<template>
  <v-menu v-model="menuFilter" bottom left offset-x open-on-focus transition="slide-x-transition" :nudge-width="200" class="fixed-width" max-width="300px">
    <template v-slot:activator="{ ds }">
      <v-tooltip color="#000000c4" left>
        <template v-slot:activator="{ on, attrs }" v-on="ds">
          <v-badge :value="hasFilter" bottom color="success" dot overlap v-bind="attrs" v-on="on">
            <v-btn small icon @click="menuFilter = true">
              <v-icon :color="hasFilter ? 'accent' : ''" size="26">icon-filter</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Filter</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text>
        <div class="filter-field mb-2" v-for="(f, ind) in listFilters" :key="ind">
          <v-select
            @click.stop
            :items="f.items"
            v-model="filters[f.value]"
            :label="f.name"
            solo
            dense
            hide-details
            item-text="name"
            item-value="value"
          ></v-select>
        </div>
        <div @click.stop class="d-flex align-end" style="width: 250px; min-width: 250px;">
          <v-spacer></v-spacer>
          <span @click="clearFilter" class="clear-filter">Clear filter</span>
          <!-- <v-btn class="text-none" dense color="red" @click="">Apply</v-btn> -->
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    listFilters: {
      type: Array,
      default: () => [],
    }
  },
  data: () => {
    return {
      menuFilter: false,
      filters: {}
    }
  },
  computed: {
    hasFilter() {
      return Object.keys(this.filters).length > 0
    }
  },
  watch: {
    filters: {
      async handler() {
        await this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'filterNotification', value: this.filters })
        this.filterData()
      },
      deep: true
    },
    menuFilter(val) {
      this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'isOpenChildElement', value: val })
    }
  },
  mounted() {
    this.clearFilter()
  },
  methods: {
    filterData() {
      this.$emit('filterData')
    },
    clearFilter() {
      this.filters = {}
      this.filterData()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  &.theme--dark.v-sheet {
    background-color: #434659 !important;
  }
  &.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background-color: #666c86 !important;
  }
}
.clear-filter {
  cursor: pointer;
  text-decoration: underline;
}
</style>
