<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :clearable="$attrs['clearable']"
        :dense="dense"
        :disabled="disabled"
        :hide-details="$attrs['hide-details']"
        :label="label"
        :outlined="outlined"
        :placeholder="$attrs['placeholder']"
        :prepend-inner-icon="$attrs['prepend-inner-icon']"
        :append-icon="$attrs['append-icon']"
        :rules="rules"
        readonly
        @click:append="dateMenu = !dateMenu"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" :max="max" :min="min" @change="dateMenu = false"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: { type: String, default: undefined },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: 'YYYY-MM-DD format' },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
  },
  watch: {
    value(val) {
      this.dateModel = this.value
    },
  },
  computed: {
    date: {
      get() {
        return this.dateModel
      },
      set(val) {
        this.dateModel = val
        this.$emit('input', this.dateModel)
      },
    },
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined,
  }),

  created() {
    this.dateModel = this.value
  },
}
</script>
