<template>
  <div style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: transparent;">
    <div
      style="width: 12px; height: 12px; position: absolute; top: 0; left: 0; border-top-left-radius: 6px"
      v-bind:style="{ 'border-top': '2px solid ' + color, 'border-left': '2px solid ' + color }"
    ></div>
    <div
      style="width: 12px; height: 12px; position: absolute; top: 0; right: 0;  border-top-right-radius: 6px"
      v-bind:style="{ 'border-top': '2px solid ' + color, 'border-right': '2px solid ' + color }"
    ></div>
    <div
      style="width: 12px; height: 12px; position: absolute; bottom: 0; left: 0; border-bottom-left-radius: 6px"
      v-bind:style="{ 'border-bottom': '2px solid ' + color, 'border-left': '2px solid ' + color }"
    ></div>
    <div
      style="width: 12px; height: 12px; position: absolute; bottom: 0; right: 0; border-bottom-right-radius: 6px"
      v-bind:style="{ 'border-bottom': '2px solid ' + color, 'border-right': '2px solid ' + color }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BorderArrow',
  props: {
    color: { type: String, default: 'lightgrey' },
  },
}
</script>

<style scoped></style>
