import { LiteEvent } from './LiteEvent'
import { copyByJson, getUUIDv4 } from '@/utils/helper'

import { MARKER_DRAW } from './index.js'
import { MarkerDraw } from './MarkerDraw.js'

export function MarkerDrawControl(map, draw) {
  let _marker_draw = new MarkerDraw(map)
  let _eventCreated = new LiteEvent()
  let _eventUpdated = new LiteEvent()
  let _eventDeleted = new LiteEvent()
  let _draw = null
  let _features = []
  let _layers = []
  if (draw) {
    this.setDraw(draw)
  }
  let _id = `${getUUIDv4()}`

  this.color = '#3bb2d0'
  let _source_id = `origin-marker-draw-source-${_id}`

  let _addLayer = layer => {
    layer = Object.assign({}, layer)
    layer.id = `marker-draw-layer-${getUUIDv4()}`
    layer.source = _source_id
    map.addLayer(layer)
    _layers.push(layer)
    return layer
  }
  let _removeLayer = layer => {
    if (map.getLayer(layer.id)) {
      map.removeLayer(layer.id)
    }
    _layers.filter(x => x.id != layer.id)
  }
  map.addSource(_source_id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })
  this.setDraw = draw => {
    draw.onCancel = () => {
      this.add({
        type: 'FeatureCollection',
        features: _features,
      })
      this.changeMode('SimpleMarkerSelect')
    }
    draw.onCreate = features => {
      _features = _features.concat(features)
      this.add({
        type: 'FeatureCollection',
        features: _features,
      })
      _eventCreated.trigger({ features })
      this.changeMode('SimpleMarkerSelect')
    }
    draw.onUpdate = features => {
      features.forEach(feature => {
        let index = _features.findIndex(x => x.id == feature.id || x.properties.id == feature.properties.id)
        if (index !== -1) {
          _features[index] = feature
        }
      })
      this.add({
        type: 'FeatureCollection',
        features: _features,
      })
      _eventUpdated.trigger({ features })
      this.changeMode('SimpleMarkerSelect')
    }
    draw.onDelete = features => {
      _features = _features.filter(
        feature => !features.find(x => x.id == feature.id || x.properties.id == feature.properties.id),
      )

      this.add({
        type: 'FeatureCollection',
        features: _features,
      })
      _eventDeleted.trigger({ features })
      this.changeMode('SimpleMarkerSelect')
    }
    _draw = draw
  }

  this.on = (key, cb) => {
    switch (key) {
      case 'draw.create':
        _eventCreated.on(cb)
        break
      case 'draw.update':
        _eventUpdated.on(cb)
        break
      case 'draw.delete':
        _eventDeleted.on(cb)
        break
    }
  }

  this.off = (key, cb) => {
    switch (key) {
      case 'draw.create':
        _eventCreated.off(cb)
        break
      case 'draw.update':
        _eventUpdated.off(cb)
        break
      case 'draw.delete':
        _eventDeleted.off(cb)
        break
    }
  }

  this.changeMode = (type = 'SimpleMarkerSelect', options = {}) => {
    if (_draw) {
      _draw.destroy()
    }
    switch (type) {
      case 'simple_select':
      case 'SimpleMarkerSelect':
        type = 'SimpleMarkerSelect'
        // eslint-disable-next-line no-case-declarations
        let feature = options.feature
        // eslint-disable-next-line no-case-declarations
        let onSelect = (draw_type, feature) => {
          if (_draw) _draw.destroy()
          options = options || { layer: _layers[0] }

          let source = map.getSource(_source_id)
          if (source) {
            let geojson = {
              type: 'FeatureCollection',
              features: _features.filter(x => x.id != feature.id && x.properties.id !== feature.properties.id),
            }
            source.setData(copyByJson(geojson))
          }
          this.setDraw(MARKER_DRAW[draw_type](_marker_draw, feature, options))
        }
        if (feature && feature.properties.draw_type) {
          options.draw_type = feature.properties.draw_type
          onSelect(feature.properties.draw_type, feature)
          return
        }
        _draw = MARKER_DRAW[type](map, {
          layer_ids: _layers.map(x => x.id),
          feature: options.feature,
        })
        _draw.onSelect = onSelect
        break

      default:
        if (_draw) {
          this.add({
            type: 'FeatureCollection',
            features: _features,
          })
        }

        this.setDraw(MARKER_DRAW[type](_marker_draw, null, options))
        break
    }
  }
  this.add = (geojson = {}) => {
    geojson = copyByJson(geojson)
    let source = map.getSource(_source_id)
    if (source) {
      source.setData(geojson)
    }
    _features = geojson.features || {}
  }
  this.draw = options => {
    _draw.start(options)
  }

  this.getAll = () => {
    return { type: 'FeatureCollection', features: _features }
  }
  this.deleteAll = () => {
    _features = []
    if (_marker_draw) {
      _marker_draw.clear()
    }
    let source = map.getSource(_source_id)
    if (source) {
      source.setData({ type: 'FeatureCollection', features: [] })
    }
  }

  this.destroy = () => {
    if (_draw) {
      _draw.destroy()
    }

    _layers.slice().forEach(_removeLayer)
    _layers = []
    if (map.getSource(_source_id)) {
      map.removeSource(_source_id)
    }
  }
  this.trash = () => {
    if (!_draw || !_draw.trash) return
    // let features = _draw.trash();
  }
  this.setOption = (options = {}) => {
    let { layers, draw_type } = options

    _layers.slice().forEach(_removeLayer)
    _layers = []
    if (layers && draw_type == 'SymbolMarkerDraw') {
      layers.map(_addLayer)
    } else {
      _addLayer({
        type: 'line',
        paint: {
          'line-color': this.color,
          'line-width': 1,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
      })
      _addLayer({
        type: 'fill', // For fill
        source: _source_id,
        filter: ['==', '$type', 'Polygon'],
        paint: {
          'fill-color': this.color,
          'fill-opacity': 0.5,
        },
      })
      _addLayer({
        type: 'circle',
        source: _source_id,
        filter: ['==', '$type', 'Point'],
        paint: {
          'circle-radius': 5,
          'circle-color': ['coalesce', ['get', 'color'], this.color],
        },
      })
    }
  }
}
