var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"chat-box fill-height",attrs:{"content-class":"bottom-right-dialog","width":"500","fullscreen":_vm.expandWindow},model:{value:(_vm.dialogChat),callback:function ($$v) {_vm.dialogChat=$$v},expression:"dialogChat"}},[_c('v-card',{staticClass:"body-chat"},[_c('v-card-title',[_c('v-img',{attrs:{"src":_vm.sourceLogo,"max-width":"20px"}}),_c('span',{staticClass:"chat-title"},[_vm._v("Platform Assistant")]),_c('div',{staticClass:"chat-handle"},[_c('v-btn',{attrs:{"small":"","color":"#9155fd"},on:{"click":_vm.makeNewConversation}},[_c('v-icon',[_vm._v("mdi-chat-plus-outline")]),_c('span',{staticClass:"chat-handle"},[_vm._v("New chat")])],1),_c('v-menu',{attrs:{"rounded":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","width":"60","height":"60"}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',[_vm._v("Recents")])])]}}]),model:{value:(_vm.menuRecent),callback:function ($$v) {_vm.menuRecent=$$v},expression:"menuRecent"}},[_c('v-card',{attrs:{"width":"400px"}},[_c('v-card-title',[_c('span',[_vm._v("Recent activity")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"list-recent"},[(_vm.loadingRecentActivity)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary secondary"}}):_vm._e(),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedConversation),callback:function ($$v) {_vm.selectedConversation=$$v},expression:"selectedConversation"}},_vm._l((_vm.conversationsAvailable),function(conversation,i){return _c('div',{key:i},[(conversation.isGroup)?_c('v-subheader',{staticClass:"group-name"},[_vm._v(" "+_vm._s(conversation.group)+" ")]):_c('v-list-item',{staticClass:"hover-border-radius",on:{"click":function($event){return _vm.openChat(conversation)},"mouseover":function($event){_vm.expandHandle = i},"mouseleave":function($event){_vm.expandHandle = null}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"16px !important"}},[_vm._v(" "+_vm._s(conversation.description)+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandHandle === i),expression:"expandHandle === i"}]},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-img',_vm._g({attrs:{"src":_vm.sourceLogoHorizontalDots}},Object.assign({}, onMenu, onTooltip)))]}}],null,true)},[_c('span',[_vm._v("Options")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.editConversation(conversation)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")]),_c('span',{staticClass:"ml-2"},[_vm._v("Rename")])],1)]),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteConversation(conversation)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("icon-trash")]),_c('span',{staticClass:"ml-2"},[_vm._v("Delete")])],1)])],1)],1)],1)],1)],1)}),0)],1)],1)],1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"clickable",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.expandWindow = !_vm.expandWindow}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.expandWindow ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.expandWindow ? 'Collapse window' : 'Expand window'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogChat = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-window-close")])],1)]}}])},[_c('span',[_vm._v("Close window")])])],1),_c('v-card-text',[_c('div',{staticClass:"box-area",style:(_vm.stylesBoxArea)},[_c('div',{staticClass:"box-map",style:(_vm.stylesBoxMap)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMap),expression:"hasMap"}],staticClass:"fill-height",staticStyle:{"position":"relative"}},[_c('Map',{ref:"mapBot",attrs:{"current-layers":_vm.layers,"drawType":_vm.drawType,"isCreateText":_vm.displayType === 'text',"isDraw":_vm.isCreating,"isMiniMap":!_vm.expandWindow,"isChat":true,"typeCreateAOI":_vm.CHAT_TYPE_CREATE_AOI},on:{"update:currentLayers":function($event){_vm.layers=$event},"update:current-layers":function($event){_vm.layers=$event},"update:drawType":function($event){_vm.drawType=$event},"update:draw-type":function($event){_vm.drawType=$event},"update:isDraw":function($event){_vm.isCreating=$event},"update:is-draw":function($event){_vm.isCreating=$event},"featureInfo":_vm.onPointClick}})],1)]),_c('div',{staticClass:"box-chat",style:(_vm.stylesBoxChat)},[(_vm.messages.length > 0)?_c('div',{staticClass:"handle-map"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.hasMap = !_vm.hasMap}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.hasMap ? 'mdi-map-minus' : 'mdi-map-plus'))])],1)]}}],null,false,2277750692)},[_c('span',[_vm._v(_vm._s(_vm.hasMap ? 'Hide map' : 'Display map'))])])],1):_vm._e(),_c('ChatgptBoxBody',{ref:"chatgptBoxBody",on:{"addToMap":_vm.addToMap,"startDraw":_vm.startDraw,"cancelDraw":_vm.cancelDraw,"addServiceResult":function (serviceKey, result) { return _vm.addServiceResult(serviceKey, result); }}}),_c('ChatgptBoxFooter',{ref:"chatgptBoxFooter",attrs:{"scroll-to-bottom":_vm.scrollToBottom},on:{"makeNewConversation":_vm.makeNewConversation}})],1)])])],1),_c('CreateOrder',{ref:"createOrder"}),_c('RenameDialog',{ref:"renameDialog",on:{"submit":_vm.renameConversation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }