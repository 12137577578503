<template>
  <div style="position: relative; z-index: 3">
    <v-menu v-model="menu" bottom left offset-x open-on-click transition="slide-x-transition">
      <template v-slot:activator="{ ds }">
        <v-tooltip color="#000000c4" left>
          <template v-slot:activator="{ on, attrs }" v-on="ds">
            <v-btn
              :disabled="disabled"
              class="mt-1"
              color="bgContainer"
              height="38"
              min-height="0"
              min-width="0"
              style=""
              v-bind="attrs"
              width="38"
              @click="menu = true"
              v-on="on"
              small
            >
              <v-icon color="secondary" size="22">mdi-pencil-ruler</v-icon>
            </v-btn>
          </template>
          <span>Map control</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <v-list-item @click="activeTool('Distance')">
          <v-list-item-title>
            <v-icon>mdi-ruler</v-icon>
            Measure Distance
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="activeTool('Area')">
          <v-list-item-title>
            <v-icon>mdi-ruler-square-compass</v-icon>
            Measure Area
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="activeTool('Location')">
          <v-list-item-title>
            <v-icon>mdi-map-marker-radius</v-icon>
            Location
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-card
      v-if="active"
      class="elevation-4"
      height="210"
      style="min-width: 350px; position: absolute; top: 50px; right: 0; border-radius: 8px"
      width="350"
    >
      <dialog-header :title="'Map control (' + mode + ')'" @close="closeMeasureTool" />
      <v-card-text class="px-6">
        <v-layout class="fill-height" column justify-center>
          <v-layout
            align-center
            class="fill-height"
            column
            style="border-bottom-right-radius: 8px; border-bottom-left-radius: 8px"
          >
            <v-layout align-center style="width: 100%">
              <v-select
                v-if="mode === 'Distance'"
                v-model="unit"
                :items="distanceUnits"
                dense
                hide-details
                item-text="name"
                label="Unit"
                outlined
                return-object
                @change="changeUnit"
              >
              </v-select>
              <v-select
                v-else-if="mode === 'Location'"
                v-model="unit"
                :items="locationUnits"
                dense
                hide-details
                item-text="name"
                label="Type"
                outlined
                return-object
                @change="changeUnit"
              >
              </v-select>
              <v-select
                v-else
                v-model="unit"
                :items="areaUnits"
                dense
                hide-details
                item-text="name"
                label="Unit"
                outlined
                return-object
                @change="changeUnit"
              >
              </v-select>
              <v-spacer />
              <v-tooltip bottom color="#000000c4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-3 elevation-2"
                    color="accent"
                    fab
                    outlined
                    v-bind="attrs"
                    x-small
                    @click="reDraw"
                    v-on="on"
                  >
                    <v-icon v-if="mode === 'Distance'" size="20">mdi-ruler</v-icon>
                    <v-icon v-else-if="mode === 'Location'" size="20">mdi-map-marker</v-icon>
                    <v-icon v-else size="20">mdi-ruler-square-compass</v-icon>
                  </v-btn>
                </template>
                <span>Reset</span>
              </v-tooltip>
            </v-layout>
            <div style="width: 100%; height: 30px; min-height: 0; flex: none">
              <v-layout align-center class="fill-height">
                <h3 style="color: #333333">Result:</h3>
              </v-layout>
            </div>
            <v-layout align-center class="px-2" style="width: 100%">
              <div
                v-if="result && mode !== 'Location'"
                class="mt-2 subtitle-1"
                style="color: var(--v-primary-base)"
                v-html="result.toFixed(4) + unit.code"
              ></div>
              <div
                v-if="result && mode === 'Location'"
                class="mt-2 subtitle-1"
                style="color: var(--v-primary-base)"
                v-html="result"
              ></div>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DialogHeader from '@/components/DialogHeader'
import { latDMS, lngDMS } from '@/utils/convertLatLngToDMS'

export default {
  name: 'MeasureTool',
  components: { DialogHeader },
  data() {
    return {
      menu: false,
      unit: {},
      result: undefined,
      originResult: undefined,
      mode: 'Distance',
      locationUnits: [
        {
          name: 'DMS',
          code: 'DMS',
        },
        {
          name: 'Latitude & Longitude',
          code: 'latlng',
        },
      ],
      distanceUnits: [
        {
          name: 'Kilometer',
          code: 'km',
          value: 1,
        },
        {
          name: 'Meter',
          code: 'm',
          value: 1000,
        },
      ],
      areaUnits: [
        {
          name: 'Square Kilometer',
          code: 'sqkm',
          value: 1,
        },
        {
          name: 'Square Meter',
          code: 'sqm',
          value: 1000000,
        },
        {
          name: 'Hecta',
          code: 'ha',
          value: 100,
        },
      ],
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    activeMeasureTool: { type: Boolean, default: false, required: false },
  },
  computed: {
    active: {
      get() {
        return this.activeMeasureTool
      },
      set(val) {
        this.$emit('update:activeMeasureTool', val)
      },
    },
  },
  methods: {
    reDraw() {
      this.resetResult()
      switch (this.mode) {
        case 'Distance':
          this.$emit('activeMeasureTool', this.mode, 'drag-line')
          break
        case 'Area':
          this.$emit('activeMeasureTool', this.mode, 'polygon')
          break
        case 'Location':
          this.$emit('activeMeasureTool', this.mode, 'location')
          break
      }
    },
    activeTool(currentMode) {
      this.active = true
      this.resetResult()
      this.mode = currentMode
      switch (currentMode) {
        case 'Distance':
          this.unit = {
            name: 'Kilometer',
            code: 'km',
            value: 1,
          }
          this.$emit('activeMeasureTool', this.mode, 'drag-line')
          break
        case 'Area':
          this.unit = {
            name: 'Square Kilometer',
            code: 'sqkm',
            value: 1,
          }
          this.$emit('activeMeasureTool', this.mode, 'polygon')
          break
        case 'Location':
          this.unit = {
            name: 'DMS',
            code: 'DMS',
          }
          this.$emit('activeMeasureTool', this.mode, 'location')
          break
      }
    },
    setResult(result) {
      this.originResult = result
      switch (this.mode) {
        case 'Location':
          if (this.unit.name === 'DMS')
            this.result = latDMS(+this.originResult['lat']) + '<br>' + lngDMS(+this.originResult['lng'])
          else
            this.result =
              'Latitude: ' +
              this.originResult['lat'].toFixed(6) +
              '<br>' +
              'Longitude: ' +
              this.originResult['lng'].toFixed(6)
          break
        default:
          this.result = this.unit.value ? this.originResult * this.unit.value : this.originResult
      }
    },
    changeUnit() {
      if (this.originResult) this.setResult(this.originResult)
    },
    resetResult() {
      this.originResult = undefined
      this.result = undefined
    },
    closeMeasureTool() {
      this.resetResult()
      this.active = false
      this.$emit('resetDraw')
    },
  },
}
</script>

<style scoped></style>
