import api from '@/api/api'
import config from '@/config'

export async function tileMapbox(url) {
  return await api.get(url, {}, { auth: false }, false)
}

export async function authLogin(data) {
  let result = await api.post(`${config.backend}/auth/login`, data, {}, true)
  api.setToken('Bearer ' + result.data.access_token)
  return result
}

export async function checkToken() {
  return await api.get(`${config.backend}/auth/me`, {}, {}, false,)
}

export async function authLogout() {
  await api.post(`${config.backend}/auth/logout`)
  localStorage.removeItem('cacheRoute')
  api.deleteToken()
  window.location.href = '/pages/login'
}

export async function resetPassword(data) {
  return await api.post(`${config.backend}/auth/password/reset`, data)
}

export async function register(data) {
  return await api.post(`${config.backend}/auth/register`, data)
}

export async function updateProfile(data) {
  return await api.post(`${config.backend}/profile`, data)
}

export async function getUserSetting(data) {
  return await api.get(`${config.backend}/system/setting-properties`, data.payload)
}

export async function getProjection() {
  return await api.get(`${config.backend}/projections`)
}
