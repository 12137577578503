<template>
  <v-menu style="z-index: 2" rounded top left offset-y offset-x>
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn icon width="60" height="60" v-on="{ ...onMenu, ...onTooltip }" small>
            <v-icon color="#66808d">mdi-layers-plus</v-icon>
          </v-btn>
        </template>

        <span>Add layer</span>
      </v-tooltip>
    </template>

    <v-list shaped width="250">
      <v-subheader class="py-0" style="border-bottom: 1px solid whitesmoke; height: 30px">
        <h4>Layers</h4>
      </v-subheader>
      <template v-for="(item, i) in layers">
        <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

        <v-list-item
          @click="changeDisplayLayer(item)"
          v-else
          :key="`item-${i}`"
          :value="item"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-slot:default="{ active }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      layers: [],
    }
  },
  mounted() {
    this.layers.push({
      name: 'Dams',
      layer: require('@/assets/DefaultLayers/dam.json'),
    })
  },
  methods: {
    changeDisplayLayer(layer) {
      this.$emit('changeDisplayLayer', layer)
    },
  },
}
</script>

<style scoped></style>
