<template>
  <v-form ref="formData" v-model="valid" @submit.prevent="submitData">
    <v-text-field
      v-model="data.name"
      :rules="[rules.nameRequired]"
      label="Name"
      outlined
      placeholder="Name"
      v-bind:autofocus="type === 'name'"
    ></v-text-field>

    <v-combobox
      v-if="isCreateAoi"
      v-model="data.group"
      :items="groupsAOI.map(item => item.name)"
      label="Select group"
      outlined
      v-bind:autofocus="type === 'group'"
      placeholder="Select group"
      small-chips
    >
      <template v-slot:selection="data">
        <v-chip color="primary" small>
          <div class="caption-light">{{ data.item }}</div>
        </v-chip>
      </template>
    </v-combobox>
    <v-checkbox v-if="isSaveLayer" v-model="isCreateAoi" label="Save as AOI"></v-checkbox>
    <v-layout>
      <v-spacer />
      <v-btn type="submit" :loading="loading" color="primary" rounded>
        Submit
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import { mapState } from '@/store/ults'

export default {
  name: 'AOIInfo',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    dialog: { type: Boolean, default: false },
    isSaveLayer: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      valid: false,
      data: {
        name: '',
        group: '',
      },
      isCreateAoi: true,
    }
  },
  computed: {
    ...mapState('AOI', ['groupsAOI', 'currentGroup']),
  },
  watch: {
    dialog() {
      this.setValue()
      this.$refs.formData.resetValidation()
    }
  },
  mounted() {
    this.setValue()
    if (this.isSaveLayer) this.isCreateAoi = false
  },
  methods: {
    setValue() {
      if (Object.keys(this.item).length) {
        this.data = { ...this.item }
      } else {
        this.data = {
          name: '',
          group: '',
        }
        if (Object.keys(this.currentGroup).length > 0) this.data.group = this.currentGroup.name
      }
    },
    async submitData() {
      try {
        this.loading = true
        if (!this.$refs.formData.validate()) return
        if (this.isCreateAoi) {
          await this.$emit('submit', this.data)
        }
        if (this.isSaveLayer) {
          this.$emit('saveLayer', this.data.name)
        }
      } catch (e) {
        console.warn('submit data aoi error', e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
