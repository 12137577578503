<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="submitData">
          <v-text-field
            v-model="currentItem.description"
            :rules="[rules.nameRequired]"
            label="Name"
            required
            outlined
            dense
          ></v-text-field>
          <div class="d-flex">
            <v-spacer />
            <v-btn color="primary" type="submit" rounded>
              Submit
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Update Conversation'
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      currentItem: {},
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
    }
  },
  methods: {
    open(item) {
      this.currentItem = { ...item }
      this.dialog = true
    },
    submitData() {
      try {
        this.$emit('submit', this.currentItem)
      } catch(e) {
        console.warn("Error when submit rename: ", e)
      } finally {
        this.dialog = false
      }
    }
  }
}
</script>
