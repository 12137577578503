<template>
  <v-layout v-click-outside="checkDataExists" column justify-center style="position: relative">
    <v-text-field
      v-model="location"
      clearable
      dense
      hide-details
      label="Location"
      prepend-inner-icon="mdi-map"
      readonly
      solo
      @focus="openSearch"
    >
    </v-text-field>
    <v-layout v-show="isResult" class="pa-3" column style="background: var(--v-backgroundPrCard-base)">
      <v-text-field
        ref="textSearch"
        v-model="search"
        v-click-outside="endSearch"
        :hide-spin-buttons="true"
        :loading="isLoading"
        append-icon="mdi-magnify"
        background-color="secondary"
        clearable
        dense
        label="Search location"
        placeholder="Start typing to Search"
        solo
        @focus="onSearch = true"
        @input="debounceInput"
      ></v-text-field>
      <v-list v-show="results.length" dense max-height="150" style="overflow-y: auto">
        <v-list-item v-for="(item, index) in results" :key="index" @click="selectLocation(item)">
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-layout>
  </v-layout>
</template>

<script>
import { debounce } from 'lodash'
import { searchLocation } from '@/api/map'
import sleep from '@/utils/sleep'
// import {searchLocation} from "@/backend";

export default {
  name: 'SearchLocation',
  data() {
    return {
      onSearch: false,
      isResult: false,
      address_name: undefined,
      isLoading: false,
      search: '',
      location: undefined,
      results: [],
    }
  },
  watch: {
    search(val) {
      if (val) this.debounceInput()
    },
  },
  methods: {
    selectLocation(item) {
      this.location = item.name
      this.changeAddress(item)
    },
    async openSearch() {
      this.isResult = true
      await sleep(100)
      this.$refs.textSearch.focus()
    },
    endSearch() {
      this.onSearch = false
    },
    changeAddress(address) {
      if (!address) return
      this.$emit('onSearch', address.bbox)
      this.isResult = false
    },
    checkDataExists() {
      if (!this.location) this.$emit('close')
    },
    zoomTo() {
      if (/^([-+]?)([\d]{1,3})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,2})((\.)(\d+))?)$/.test(this.search)) {
        this.$emit('zoomToCenter', this.search)
      }
    },
    debounceInput: debounce(function(e) {
      this.searchLocation()
    }, 500),
    async searchLocation() {
      if (!this.search) {
        this.results = []
        return
      }
      try {
        this.isLoading = true
        let res = await searchLocation({ payload: { q: this.search } })
        this.results = []
        this.results = res.data
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
/deep/ .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
</style>
