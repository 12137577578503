<template>
  <v-layout style="position: relative; ">
    <v-layout dense class="fill-height" style="overflow-y: auto; max-height: 250px; overflow-x:hidden" width="100%">
      <div v-for="(item, index) in availableData" :key="index" class="" style="width: 100%; border-radius: 4px;">
        <v-list-item class="custom-item" v-if="!item.children || !item.children.length">
          <TooltipBase :styles="{ width: '240px', fontSize: '16px' }" :title="item.name">
            <template #truncate>
              <span v-html="highlightSearch(item.name)"></span>
            </template>
          </TooltipBase>
        </v-list-item>
        <div v-else>
          <div @click="togglePanel(item.code)" class="d-flex align-center " style="cursor: pointer;">
            <v-icon>{{ isPanelOpen(item.code) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
            <TooltipBase :styles="{ width: '240px', fontSize: '16px' }" :title="item.name">
              <template #truncate>
                <span v-html="highlightSearch(item.name)"></span>
              </template>
            </TooltipBase>
          </div>
          <v-expand-transition>
            <v-card-text v-if="isPanelOpen(item.code)" style="padding: 0; background: transparent">
              <div class="custom-item ml-10" v-for="child in item.children" :key="child.code">
                <div v-if="!child.children || !child.children.length">
                  <v-layout align-center>
                    <div class="d-flex align-center" style="cursor: pointer;" @click.stop="selectItem(child, item)">
                      <v-icon class="mr-1">mdi-arrow-right-bottom</v-icon>
                      <TooltipBase :position="'top'" :styles="{ width: '180px', fontSize: '16px' }" :title="child.name">
                        <template #truncate>
                          <span v-html="highlightSearch(child.name)"></span>
                        </template>
                      </TooltipBase>
                    </div>
                  </v-layout>
                </div>
                <div v-else>
                  <div
                    @click="toggleChildPanel(item.code, child.code)"
                    class="d-flex align-center  "
                    style="cursor: pointer;"
                  >
                    <v-icon>{{
                      isChildPanelOpen(item.code, child.code) ? 'mdi-chevron-down' : 'mdi-chevron-right'
                    }}</v-icon>
                    <v-layout @click.stop="selectItem(child, item)">
                      <TooltipBase :position="'top'" :styles="{ width: '180px', fontSize: '16px' }" :title="child.name">
                        <template #truncate>
                          <span v-html="highlightSearch(child.name)"></span>
                        </template>
                      </TooltipBase>
                    </v-layout>
                  </div>
                  <v-expand-transition>
                    <div v-if="isChildPanelOpen(item.code, child.code)" style="padding: 0; background: transparent">
                      <div class="custom-item ml-10" v-for="grandchild in child.children" :key="grandchild.code">
                        <v-layout align-center>
                          <div
                            class="d-flex align-center"
                            style="cursor: pointer;"
                            @click="selectItem(grandchild, child, item)"
                          >
                            <v-icon class="mr-1">mdi-arrow-right-bottom</v-icon>
                            <TooltipBase
                              :position="'top'"
                              :styles="{ width: '140px', fontSize: '14px' }"
                              :title="grandchild.name"
                            >
                              <template #truncate>
                                <span v-html="highlightSearch(grandchild.name)" style="width: 100px;"></span>
                              </template>
                            </TooltipBase>
                          </div>
                        </v-layout>
                      </div>
                    </div>
                  </v-expand-transition>
                </div>
              </div>
            </v-card-text>
          </v-expand-transition>
        </div>
      </div>
    </v-layout>
    <div class="custom-card-bg-1">
      <v-text-field
        v-model="search"
        style="width: 100%;"
        append-icon="mdi-magnify"
        dense
        hide-details
        label="Search menu"
        outlined
        color="primary"
        @blur="isSearch = false"
        @focus="isSearch = true"
        @input="searchData"
      ></v-text-field>
    </div>
  </v-layout>
</template>
<script>
import sleep from '@/utils/sleep'
import CustomIcon from '@/components/CustomIcon.vue'
import { mapState } from '@/store/ults'
import TooltipBase from '@/components/TooltipBase.vue'

export default {
  components: { CustomIcon, TooltipBase },

  props: {
    value: {},
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    color: { type: String, default: '' },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'name' },
    returnObject: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      if (!val && this.multiple) this.dataModel = []
      else this.setFirstData()
      this.$emit('change', this.value)
    },
    dataMenu(val) {
      this.isSearch = false
      if (val) {
        this.search = ''
        this.availableData = [...this.items]
      }
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('menu', ['menu']),
    ...mapState('project', ['projects']),
    itemsCopy() {
      let currentProject = this.projects.find(project => project.uuid === this.$route.params.id)
      let trueMenu = JSON.parse(JSON.stringify(this.menu)).filter(item => item.code != 'home')
      let projectMenu = currentProject.menus.filter(menu => menu.viewable)
      const filterItems = items => {
        return items.filter(item => {
          if (item.type == 'menu') {
            const projectMenuItem = projectMenu.find(menu => menu.code == item.code)
            if (!projectMenuItem || !projectMenuItem.viewable) {
              return false
            }
          }
          if (item.children) {
            item.children = filterItems(item.children)
          }
          return true
        })
      }
      const filterNullChildItems = items => {
        return items.filter(item => {
          if (item.children) {
            item.children = filterNullChildItems(item.children)
            if ((item.type == 'group' || item.type == 'domain') && item.children.length === 0) {
              return false
            }
          }
          return true
        })
      }

      trueMenu = filterItems(trueMenu)
      trueMenu = filterNullChildItems(trueMenu)
      return trueMenu
    },
    selectedItems() {
      if (this.multiple) {
        if (this.returnObject) {
          if (!this.selectedData) return ''
          let tmpData = ''
          this.selectedData.forEach(val => (tmpData = tmpData + val[this.itemText] + ', '))
          return tmpData
        } else return this.selectedData.toString()
      } else {
        if (this.returnObject) return this.selectedData[this.itemText]
        return this.selectedData
      }
    },
    selectedData: {
      get() {
        return this.dataModel
      },
      set(val) {
        this.dataModel = val
        this.$emit('input', this.dataModel)
      },
    },
  },
  data(vm) {
    return {
      isFirst: true,
      isSearch: true,
      flatItems: [],
      availableData: [],
      search: '',
      dataMenu: false,
      openPanels: [],
      dataModel: undefined,
    }
  },

  created() {
    this.setFirstData()
  },

  methods: {
    selectItem(item, parent, grandParent) {
      if (this.$route.code == item.code) return
      if (this.checkLicense(item)) {
        this.$store.commit('message/SHOW_WARNING', 'Unlicensed category!')
        return
      }
      if (item.type == 'group') {
        let urlGroup = parent.code + '/' + item.code
        this.$router.push({ name: 'group-view', query: { group: urlGroup } })
        return
      }
      let urlGroup = parent.code + '/' + item.code
      if (grandParent) urlGroup = grandParent.code + '/' + parent.code + '/' + item.code
      this.$router.push({ name: item.code, query: { group: urlGroup } })
    },
    highlightSearch(text) {
      if (!this.search) {
        return text
      }
      const regex = new RegExp(`(${this.search})`, 'gi')
      return text.replace(regex, '<span style="background-color: gray;">$1</span>')
    },
    checkLicense(item) {
      if (item.type == 'group') return false
      return this.currentUser.menus.some(menu => menu.code === item.code && !menu.have_license)
    },
    async setFirstData() {
      if (this.isFirst) {
        this.flatItems = []
        this.items.forEach(val => {
          if (!val.children) this.flatItems.push(val)
          else this.flatItems = this.flatItems.concat(val.children)
        })
      }
      await sleep(100)
      this.dataModel = this.value
    },

    searchData() {
      this.openPanels = []
      if (!this.search) {
        this.availableData = []
      } else {
        let availItems = []
        const searchInChildren = (children, parentName) => {
          return children
            .map(child => {
              const itemTextMatches =
                child[this.itemText] &&
                child[this.itemText]
                  .toString()
                  .toUpperCase()
                  .includes(this.search.toUpperCase())
              const codeMatches =
                child.code &&
                child.code
                  .toString()
                  .toUpperCase()
                  .includes(this.search.toUpperCase())

              if (itemTextMatches || codeMatches) {
                // Đưa mã phần tử vào danh sách openPanels
                this.openPanels.push(`${parentName}::${child.code}`)
                return child
              }
              if (child.children) {
                let childCopy = { ...child }
                childCopy.children = searchInChildren(child.children, `${parentName}::${child.code}`)
                if (childCopy.children.length > 0) {
                  // Mở phần tử nếu có con khớp điều kiện
                  this.openPanels.push(`${parentName}::${child.code}`)
                  return childCopy
                }
              }
              return null // Trả về null nếu không khớp điều kiện
            })
            .filter(Boolean) // Lọc bỏ các giá trị null
        }

        this.itemsCopy.forEach(val => {
          const itemTextMatches =
            val[this.itemText] &&
            val[this.itemText]
              .toString()
              .toUpperCase()
              .includes(this.search.toUpperCase())
          const codeMatches =
            val.code &&
            val.code
              .toString()
              .toUpperCase()
              .includes(this.search.toUpperCase())

          if (itemTextMatches || codeMatches) {
            this.openPanels.push(val.code)
            availItems.push({ ...val })
          } else if (val.children) {
            let parent = { ...val }
            parent.children = searchInChildren(val.children, val.code)
            if (parent.children.length > 0) {
              this.openPanels.push(val.code)
              availItems.push({ ...parent })
            }
          }
        })

        this.availableData = [...availItems]
      }
    },
    togglePanel(name) {
      if (this.openPanels.includes(name)) {
        this.openPanels = this.openPanels.filter(i => i !== name)
      } else {
        this.openPanels.push(name)
      }
    },
    isPanelOpen(index) {
      return this.openPanels.includes(index)
    },
    toggleChildPanel(name, childName) {
      const panelKey = `${name}::${childName}`
      if (this.openPanels.includes(panelKey)) {
        this.openPanels = this.openPanels.filter(i => i !== panelKey)
      } else {
        this.openPanels.push(panelKey)
      }
    },
    isChildPanelOpen(index, childName) {
      return this.openPanels.includes(`${index}::${childName}`)
    },
  },
}
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
}
.custom-item :hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-left-radius: 10px;
}
</style>
