<template>
  <v-layout class="fill-height" style="border-radius: inherit">
    <div v-if="!uploadedFiles.length" style="width: 100%; height: 100%; border-radius: inherit">
      <div
        :class="['dropZone', dragging ? 'dropZone-over' : '']"
        @dragenter="dragging = true"
        @dragleave="dragging = false"
      >
        <div class="dropZone-info" @drag="onChange">
          <div>
            <v-icon size="60">mdi-cloud-upload</v-icon>
          </div>
          <span class="dropZone-title">Drop file or click to upload</span>
          <div class="dropZone-upload-limit-info">
            <div v-if="acceptTypes">
              extension support:
              <div>( {{ acceptTypes.replaceAll(',', ', ').replaceAll('.', '*.') }} )</div>
            </div>
            <div v-if="maxSize">maximum file size: {{ maxSize }} MB</div>
          </div>
        </div>
        <input :multiple="multiple" type="file" @change="onChange" />
      </div>
    </div>
    <div v-else class="dropZone-uploaded">
      <div class="dropZone-uploaded-info">
        <span class="dropZone-title">Uploaded</span>
        <div class="py-2">
          <v-icon size="60">mdi-file-document-outline</v-icon>
        </div>
        <v-btn color="error" rounded @click="removeFile">Remove</v-btn>
      </div>
    </div>
  </v-layout>
</template>
<script>
export default {
  name: 'DragBox',
  data() {
    return {
      file: '',
      dragging: false,
    }
  },
  props: {
    multiple: { type: Boolean, default: false },
    acceptTypes: { type: String, default: null },
    maxSize: { type: Number, default: null },
    files: { type: Array, default: () => [] },
  },
  computed: {
    uploadedFiles: {
      get() {
        return this.files
      },
      set(val) {
        this.$emit('update:files', val)
      },
    },
    // extension() {
    //   return this.file ? this.file.name.split('.').pop() : ''
    // },
  },
  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this.dragging = false
        return
      }

      this.createFile(files)
    },
    createFile(files) {
      if (this.acceptTypes) {
        let formats = this.acceptTypes.split(',')
        let check = false
        if (this.multiple) {
          let errors = []
          files.forEach(file => {
            formats.forEach(ext => {
              let type = '.' + file.name.split('.').pop()
              if (type.toUpperCase().match(ext.toUpperCase())) check = true
            })
            if (!check) errors.push(file.name)
            check = false
          })
          if (errors.length) {
            alert('Unsupported file format in \n ' + errors.toString().replaceAll(',', ', \n '))
            this.dragging = false
            return
          }
        } else {
          formats.forEach(ext => {
            let type = '.' + files[0].name.split('.').pop()
            if (type.toUpperCase().match(ext.toUpperCase())) check = true
          })
          if (!check) {
            alert('Unsupported file format')
            this.dragging = false
            return
          }
          if (this.maxSize && files[0].size > this.maxSize * 1000000) {
            alert(`please check file size no over ${this.maxSize} MB.`)
            this.dragging = false
            return
          }
        }
      }
      this.uploadedFiles = [...files]
      this.dragging = false
    },
    removeFile() {
      this.uploadedFiles = []
    },
  },
}
</script>

<style scoped>
.dropZone {
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #eee;
  border-radius: inherit;
}

.dropZone:hover {
  border: 2px dashed #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  font-size: 16px;
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #eee;
  border-radius: inherit;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
