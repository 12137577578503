import api from '@/api/api'
import config from '@/config'

export async function getListConversations(params={}) {
  return await api.get('/conversations', params)
}

export async function createDescriptionConversation(data={}) {
  return await api.post(`${config.upload_url}/chat/title-summary`, data, { notification: false })
}

export async function createConversation(data) {
  return await api.post('/conversations', data, { notification: false })
}

export async function updateConversation(conversationId, data) {
  return await api.put(`/conversations/${conversationId}`, data, { notification: false })
}

export async function deleteConversation(conversationId) {
  return await api.delete(`/conversations/${conversationId}`, {}, { notification: false })
}

export async function getListMessages(conversationId, params={}) {
  return await api.get(`conversations/${conversationId}/chats`, params)
}

export async function sendMessage(conversationId, data) {
  return await api.post(`conversations/${conversationId}/chats`, data, { notification: false })
}
