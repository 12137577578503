import api from '@/api/api'
import config from '@/config'

export async function getListAOI(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois`, data.payload)
}

export async function getDetailAOI(data) {

  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}`, data.payload)
}

export async function createAOI(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois`, data.params, { timeout: 10 * 1000 * 60 })
}

export async function deleteAOI(data) {
  return await api.delete(`${config.backend}/projects/${data.projectId}/aois/${data.id}`)
}

export async function updateAOI(data) {
  return await api.put(`${config.backend}/projects/${data.projectId}/aois/${data.id}`, data.params)
}

export async function getGroupAOI(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/groups`,data.payload)
}

export async function updateGroupAOI(data) {
  return await api.put(`${config.backend}/projects/${data.projectId}/aois/groups/${data.name}`, data.params)
}

export async function deleteGroupAOI(data) {
  return await api.delete(`${config.backend}/projects/${data.projectId}/aois/groups/${data.name}`)
}

export async function saveUploadAOI(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/bulk-store`, data.params, {
    timeout: 10 * 1000 * 60,
  })
}

export async function getSeason(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/seasons`, data.payload)
}

export async function createSeason(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/${data.id}/seasons`, data.params)
}

export async function updateSeason(data) {
  return await api.put(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/seasons/${data.seasonId}`,
    data.params,
  )
}

export async function deleteSeason(data) {
  return await api.delete(`${config.backend}/projects/${data.projectId}/aois/${data.id}/seasons/${data.seasonId}`)
}

export async function registerAlert(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/register-alert`, data.payload)
}

export async function unregisterAlert(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/${data.id}/unregister-alert`, data.payload)
}

export async function downloadVector(data) {
  return await api.post(`${config.upload_url}/convert-geometry`, data.params)
}

export async function uploadAOI(params) {
  return await api.post(`${config.upload_url}/read-vector-files`, params)
}
