export const CLIENT = 'user'
export const BOT = 'assistant'

export const CHAT_TYPE_AOI = 'AOI'
export const CHAT_REQUEST_AOI = 'AOI is'
export const CHAT_CREATED_AOI = 'aoi name is'
export const CHAT_TYPE_CREATE_AOI = 'create AOI for order services'
export const CHAT_TYPE_ONLY_CREATE_AOI = 'only_create_aoi'

// action
export const VIEW_RESULT = 'view_result'
export const GO_TO_PROJECT = 'go_to_project'
export const CREATE_AOI = 'create_aoi'




export const CHAT_REGEX_UUID = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

// map
export const ADD_LAYER_TO_MAP = 'add'
export const DRAW_MAP = 'start draw'
export const CANCEL_DRAW_MAP = 'cancel draw'

// service
export const HOTSPOTS = 'hotspots'
export const EARTHQUAKE = 'earthquake'