function convertUTCToLocal(utcDateStr) {
  // Create a new Date object from the UTC date string
  const utcDate = new Date(utcDateStr + ' UTC');
  // Format the date to the local time zone
  const localDateStr = utcDate.toLocaleString();
  return localDateStr;
}

export default function calculateDateDifference(dateString) {
  const localDateStr = convertUTCToLocal(dateString);
  const givenDate = new Date(localDateStr);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = currentDate - givenDate;

  // Calculate the difference in various units
  const diffInSeconds = diffInMs / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;

  // Calculate the difference in months and years
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const givenYear = givenDate.getFullYear();
  const givenMonth = givenDate.getMonth();

  let diffInYears = currentYear - givenYear;
  let diffInMonths = (currentYear - givenYear) * 12 + (currentMonth - givenMonth);

  if (currentDate < new Date(currentYear, currentMonth, givenDate.getDate())) {
    diffInYears -= 1;
  }

  if (currentDate < new Date(currentYear, currentMonth, givenDate.getDate())) {
    diffInMonths -= 1;
  }

  return {
    seconds: Math.floor(diffInSeconds),
    minutes: Math.floor(diffInMinutes),
    hours: Math.floor(diffInHours),
    days: Math.floor(diffInDays),
    months: Math.floor(diffInMonths),
    years: Math.floor(diffInYears),
  };
}
