export const REFERENCE_TYPE = {
  forest_fire: {
    name: 'Forest Fire',
    styles: {
      backgroundColor: '#e56a00',
    },
  },
  earthquake: {
    name: 'Earthquake',
    styles: {
      backgroundColor: '#5b431f',
    },
  },
  order: {
    name: 'Order',
    styles: {
      backgroundColor: '#025195',
    },
  },
  tasking_order: {
    name: 'Tasking Order',
    styles: {
      backgroundColor: '#81196f',
    },
  },
  result: {
    name: 'result',
    styles: {
      backgroundColor: '#d48b07',
    },
  },
}

export const STATUS_TYPE = {
  new: {
    name: 'New',
    color: 'created',
    icon: 'mdi-check'
  },
  processing: {
    name: 'Processing',
    color: 'processing',
    icon: 'mdi-autorenew'
  },
  completed: {
    name: 'Completed',
    color: 'success',
    icon: 'mdi-check-bold'
  },
}
