<template>
  <v-card-title>
    <v-icon v-if="icon" class="me-2" color="white" size="1.5rem">
      {{ icon }}
    </v-icon>
    <slot name="title">
      {{ title }}
    </slot>
    <v-spacer />
    <v-btn v-if="isClose" icon small style="margin-right: -6px" @click="() => $emit('close')">
      <v-icon>mdi-window-close</v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    isClose: { type: Boolean, default: true },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>
