<template>
  <v-card class="custom-card-bg-0" column>
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-autocomplete
      v-model="country"
      :items="countries"
      class="pt-2"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="code"
      label="Country"
      outlined
      return-object
      @input="changeCountry(country)"
    />
    <v-autocomplete
      v-model="state"
      :items="states"
      class="pt-2"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="code"
      label="State/Province"
      outlined
      return-object
      @input="changeState(state)"
    />
    <v-autocomplete
      v-model="district"
      :items="districts"
      :multiple="multiple"
      class="pt-2"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="code"
      label="District"
      outlined
      return-object
      @change="changeDistrict(district)"
    />
    <v-autocomplete
      v-model="subDistrict"
      :items="sub_districts"
      class="pt-2"
      dense
      hide-details
      item-text="name"
      item-value="code"
      label="Sub District"
      outlined
      return-object
      @input="changeSubDistrict(subDistrict)"
    />
  </v-card>
</template>

<script>
import { mapState } from '@/store/ults'
import { getTerritoryDetail } from '@/api/administrator-boundary-api'
export default {
  name: 'AdministratorBoundary',
  data() {
    return {
      loading: false,
      country: undefined,
      state: undefined,
      district: undefined,
      subDistrict: undefined,
    }
  },
  computed: {
    ...mapState('boundary', ['countries', 'states', 'districts', 'sub_districts']),
  },
  mounted() {
    this.getCountries()
  },
  props: {
    multiple: { type: Boolean, default: true },
  },
  methods: {
    reset() {
      this.country = undefined
      this.province = undefined
      this.subDistrict = undefined
    },
    async getTerritoryList(data) {
      await this.$store.dispatch('boundary/getTerritoryList', data)
    },
    async getTerritoryDetail(data) {
      const res = await getTerritoryDetail(data)
      return res.data
    },
    async getCountries() {
      if (this.countries.length === 0) {
        try {
          this.loading = true
          await this.getTerritoryList({ type: 'countries' })
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      }
    },
    async changeCountry(country) {
      try {
        this.loading = true
        this.state = undefined
        this.districts = []
        this.district = undefined
        this.subDistricts = []
        this.subDistrict = undefined
        await this.getTerritoryList({ type: 'states', payload: { country: country.code } })
        this.$emit('geometry', { data: [] })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async changeState(state) {
      try {
        this.loading = true
        if (state) {
          this.district = undefined
          this.subDistricts = []
          this.subDistrict = undefined
          await this.getTerritoryList({ type: 'districts', payload: { state: state.code } })
          const stateDetail = await this.getTerritoryDetail({ type: 'state', code: state.code })
          this.$emit('geometry', { data: [stateDetail.geojson] })
        } else {
          this.district = undefined
          this.subDistricts = []
          this.subDistrict = undefined
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async changeDistrict(district) {
      try {
        this.loading = true
        if (district) {
          this.subDistricts = []
          this.subDistrict = undefined
          await this.getTerritoryList({ type: 'sub_districts', payload: { district: district.code } })
          const districtDetail = await this.getTerritoryDetail({ type: 'district', code: district.code })
          this.$emit('geometry', { data: [districtDetail.geojson] })
        } else {
          this.subDistricts = []
          this.subDistrict = undefined
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async changeSubDistrict(subDistrict) {
      try {
        this.loading = true
        if (subDistrict) {
          const subDistrictDetail = await this.getTerritoryDetail({ type: 'sub_district', code: subDistrict.code })
          this.$emit('geometry', { data: [subDistrictDetail.geojson] })
        } else {
          this.$emit('geometry', { data: [] })
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
