import Store from '@/store'
import api from '@/api/api'
import { checkToken } from '@/api/auth-api'

export async function checkAuth(to, from, next) {
  try {
    if (to.query.token) {
      const token = to.query.token
      api.setToken(JSON.parse(window.atob(token)).access_token)
      next(to.path)
    }
    if (!api.getToken()) {
      throw 'token'
    }
    if (!Store.state.auth.currentUser) {
      let result = await checkToken()
      Store.commit('auth/SET_USER', result.data)
    }
    if (
      to.fullPath !== '/projects' &&
      to.fullPath !== '/projects/' &&
      to.fullPath.startsWith('/error-404') &&
      !to.fullPath.includes('/group-view') &&
      to.fullPath.startsWith('/projects/')
    ) {
      localStorage.setItem('cacheRoute', to.fullPath)
    }
    const user = Store.state.auth.currentUser
    const menu = user.menus.find(m => m.code === to.name)
    if ((to.meta.roles && to.meta.roles.some(role => role === user.role)) || !to.meta.roles) {
      if (!menu || (menu && menu.have_license && menu.viewable)) next()
      else {
        if (to.params.id) {
          if (to.fullPath == localStorage.getItem('cacheRoute')) {
            localStorage.removeItem('cacheRoute')
          }
          location.href = `${location.origin}/projects/${to.params.id}/unlicensed`
        }
      }
    } else location.href = `${location.origin}/error-401`
  } catch (e) {
    api.deleteToken()
    const host = window.location.href
    location.href = `${location.origin}/pages/login?redirect=${host}`
  } finally {
    next()
  }
}
