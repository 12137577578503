var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mapBound",staticStyle:{"position":"relative","height":"100%","width":"100%","overflow":"hidden","border-radius":"8px"}},[_c('div',{staticClass:"d-flex fill-height"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDrawing),expression:"isDrawing"}],staticStyle:{"position":"absolute","top":"12px","left":"12px","z-index":"2"}},[_c('DrawTool',{on:{"changeMode":_vm.changeMode}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mapSync),expression:"mapSync"}],staticClass:"hard-flex",staticStyle:{"border-right":"3px #d5d5d5 solid"}},[_c('div',{ref:"mapSync",staticClass:"map-container"})]),_c('div',{staticClass:"hard-flex",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"map",attrs:{"id":"wrapper"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMapView),expression:"isMapView"}],ref:"mapContainer",staticClass:"map-container map",on:{"mouseleave":_vm.deSelect}}),_c('div',{ref:"beforeMap",staticClass:"map-container map"})]),(_vm.isCopyRight)?_c('div',{staticClass:"location-detail elevation-1"},[_c('div',{staticClass:"d-flex align-center fill-height",staticStyle:{"padding":"2px 4px"}},[_c('div',{staticClass:"ml-3",staticStyle:{"flex":"none","height":"100%","min-width":"50px","border-right":"1px solid #7c7c7c","padding-top":"1px"}},[_c('v-icon',{attrs:{"color":"#333333","size":"14"}},[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.zoomLevel)+" ")],1),_c('div',{staticClass:"d-flex align-center fill-height pl-2",domProps:{"innerHTML":_vm._s(_vm.currentPoint)}}),_c('v-tooltip',{attrs:{"min-width":"210","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":_vm.changeDisplayTypePixelValue}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#333333"}},[_vm._v("mdi-cached")])],1)]}}],null,false,1290930623)},[(_vm.isDMS)?_c('span',[_vm._v(" DMS "),_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-swap-horizontal")]),_vm._v(" Latitude, Longitude ")],1):_c('span',[_vm._v(" Latitude, Longitude "),_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-swap-horizontal")]),_vm._v(" DMS ")],1)])],1)]):_vm._e()]),(_vm.displaySearch)?_c('div',{staticStyle:{"width":"350px","position":"absolute","top":"10px","right":"6px","z-index":"4"}},[_c('SearchLocation',{on:{"close":function($event){_vm.displaySearch = false},"onSearch":_vm.submitZoom,"zoomToCenter":_vm.zoomToCenter}})],1):_vm._e(),_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"95px"}}),(!_vm.isPreview)?_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({staticClass:"base-layer elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"title":"Base map"}},'v-layout',attrs,false),on),[_c('img',{staticStyle:{"border-radius":"8px"},attrs:{"src":_vm.currentBaseMap.avatar,"alt":"avatar","height":"60","width":"60"}})])]}}],null,false,1698166891)},[_c('v-list',[_vm._l((_vm.mapStyles),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"height":"55px"},on:{"click":function($event){_vm.currentBaseMap = item}}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"width":"49px","height":"48px","border":"2px solid lightslategray","border-radius":"10px"}},[_c('img',{staticStyle:{"border-radius":"8px"},attrs:{"src":item.avatar,"alt":"avatar","height":"45","width":"45"}})]),_c('h5',{staticClass:"ml-3",staticStyle:{"width":"130px"}},[_vm._v(_vm._s(item.name))])])])}),_c('v-subheader',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Show the label"},model:{value:(_vm.isLabel),callback:function ($$v) {_vm.isLabel=$$v},expression:"isLabel"}})],1)],2)],1):_vm._e(),_c('v-layout',{staticStyle:{"width":"33px","height":"200px","position":"absolute","top":"10px","right":"10px"},attrs:{"column":""}},[_c('v-tooltip',{attrs:{"color":"#000000c4","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"color":"bgContainer","height":"38","min-height":"0","min-width":"0","width":"38","small":""},on:{"click":function($event){_vm.displaySearch = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary","size":"22"}},[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Search")])]),(!_vm.isPreview)?_c('MeasureTool',{ref:"measureTool",attrs:{"activeMeasureTool":_vm.isOpenMeasurementTool,"disabled":_vm.isDrawing},on:{"update:activeMeasureTool":function($event){_vm.isOpenMeasurementTool=$event},"update:active-measure-tool":function($event){_vm.isOpenMeasurementTool=$event},"activeMeasureTool":_vm.activeMeasureTool,"resetDraw":_vm.resetDraw}}):_vm._e(),(_vm.isStaticMode)?_c('v-tooltip',{attrs:{"color":"#000000c4","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0 mt-1",attrs:{"color":"bgContainer","height":"38","min-height":"0","min-width":"0","width":"38"},on:{"click":_vm.removeDrawLayer}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error","size":"22"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,false,1474425664)},[_c('span',[_vm._v("Delete")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }