import {
  getTerritoryList,
  getTerritoryDetail
} from '@/api/administrator-boundary-api'
export default {
    namespaced: true,
    state: {
        countries: [],
        states:[],
        districts:[],
        sub_districts:[]
    },
    getters: {
        countries(state) {
        return state.countries
      },
    },
    mutations: {
      SET_COUNTRIES(state, newValue) {
        state.countries = newValue
      },
    },
    actions: {
      async getTerritoryList({ state, commit }, data ) {
        const res = await getTerritoryList(data)
        state[data.type] = res.data
      },
      
    }
  }
  