<template>
  <v-layout
    v-if="config"
    class="fill-height custom-card-bg-gradient"
    column
    style="height: 100vh; position: absolute; top: 0; left: 0"
  >
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <DialogHeader :is-close="true" :title="'Upload AOI'" @close="config = false" />
    <v-form ref="formData" v-model="valid" onSubmit="return false;" style="height: calc(100% - 60px)">
      <v-layout class="px-4 pb-4" style="height: 100%">
        <div style="width: 400px; height: 100%">
          <v-card class="custom-card-bg-2 pa-2 elevation-2" height="100%" width="100%">
            <v-layout class="fill-height overflow-y-hidden" column>
              <v-layout column style="flex: 1; width: 100%; overflow-y: auto; height: calc(100% - 500px)">
                <div v-for="(feature, index) in geojson.features" :key="index" class="py-1">
                  <v-layout @keyup.enter="saveData">
                    <v-layout class="pr-1" style="flex: 5">
                      <v-text-field
                        v-model="feature.properties.name"
                        :rules="[rules.nameRequired]"
                        dense
                        label="Name"
                        outlined
                        placeholder="Name"
                      ></v-text-field>
                    </v-layout>
                    <v-layout class="px-1" style="flex: 5">
                      <v-combobox
                        v-model="feature.properties.group"
                        :items="groups"
                        dense
                        label="Select group"
                        outlined
                        placeholder="Select group"
                        small-chips
                      >
                        <template v-slot:selection="data">
                          <v-chip color="primary" small>
                            <div class="caption-light">{{ data.item }}</div>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-layout>
                    <v-layout class="pl-1 pt-1" style="flex: 1">
                      <v-btn class="mt-1" icon small @click="zoomTo(feature)">
                        <v-icon>icon-gps</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-layout>
                </div>
              </v-layout>
              <div style="width: 100%; flex: none; height: 50px">
                <v-btn color="primary" rounded width="100%" @click="saveData">
                  <v-icon class="mr-1">mdi-content-save-check-outline</v-icon>
                  Save
                </v-btn>
              </div>
            </v-layout>
          </v-card>
        </div>
        <v-layout v-if="config" class="pl-4">
          <MapV2 ref="mapView" />
        </v-layout>
      </v-layout>
    </v-form>
  </v-layout>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import randomColor from '@/utils/randomColor'
import { saveUploadAOI } from '@/api/aoi-api'
import MapV2 from '@/components/MapV2.vue'
import { mapState } from '@/store/ults'

export default {
  components: { MapV2, DialogHeader },
  computed: {
    ...mapState('AOI', ['groups', 'currentGroup']),
  },
  data() {
    return {
      config: false,
      geojson: {},
      loading: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      valid: false,
    }
  },
  methods: {
    handleGroup() {
      this.geojson.features = this.geojson.features.map(feature => {
        if (!feature?.properties?.group) feature.properties.group = this.currentGroup.name
        return feature
      })
    },
    openDialog(geojson) {
      this.geojson = geojson
      if (Object.keys(this.currentGroup).length > 0) this.handleGroup()
      this.config = true
      this.addLayerToMap()
    },
    async saveData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        this.geojson.features.forEach(feature => {
          feature.name = feature.properties.name
          feature.group = feature.properties.group
        })
        await saveUploadAOI({
          projectId: this.$route.params.id,
          params: { features: this.geojson.features },
        })
        this.$emit('fetchAOI')
        this.$emit('closeConfig')
        this.config = false
      } catch (e) {
        console.warn('Error when save upload aoi: ', e)
      } finally {
        this.loading = false
      }
    },
    async addLayerToMap() {
      if (!this.$refs.mapView || !this.$refs.mapView.map) {
        await sleep(100)
        await this.addLayerToMap()
        return
      }
      await sleep(300)
      this.geojson.features.forEach((feature, index) => {
        this.$refs.mapView.addGeoJsonToMap(
          `layer-${index}`,
          feature.geometry,
          randomColor.getColor(index),
          `layer-${index}`,
          'line',
          false,
        )
      })
      this.$refs.mapView.submitZoom(
        bbox({
          type: 'FeatureCollection',
          features: this.geojson.features,
        }),
      )
    },
    zoomTo(feature) {
      this.$refs.mapView.submitZoom(bbox(feature.geometry))
    },
  },
}
</script>

<style scoped></style>
