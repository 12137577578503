<template>
  <div>
    <component :is="resolveLayout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import { mapState } from '@/store/ults'
import api from '@/api/api'
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import CustomBlank from '@/layouts/CustomBlank.vue'

export default {
  components: {
    CustomBlank,
    LayoutBlank,
    LayoutContent,
    CustomIcon
  },
  computed: {
    ...mapState('socket', ['echoChannel']),
    ...mapState('project', ['projects']),
    currentUser() {
      return this.$store.getters['auth/currentUser']
    }
  },
  data() {
    return {
      currentChannel: null,
      currentUserId: null
    }
  },
  methods: {
    initListen() {
      if (this.currentChannel) {
        this.echoChannel.private(this.currentChannel).unsubscribe()
      }

      this.currentChannel = `notification.${this.currentUser?.uuid}`
      this.echoChannel.private(`notification.${this.currentUser?.uuid}`)
        .listen('.notification.new', (e) => {
          this.$store.dispatch('notification/getUnreadNotificationsCount')
          this.$store.dispatch('notification/getListNotifications')
        })
    }
  },
  watch: {
    currentUser(val) {
      if (val) {
        this.initListen()
      }
    },

  },
  async mounted() {
    this.$vuetify.theme.dark = true
    try {
      const token = api.getToken()
      await this.$store.dispatch('socket/initEcho', token)
    } catch(e) {
      console.log('error when init: ', e)
    }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return 'custom-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
