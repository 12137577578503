<template>
  <v-dialog v-model="createDialog" persistent width="500">
    <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader
        :is-close="true"
        :title="isSaveLayer ? 'Create new layer' : 'Create AOI'"
        @close="createDialog = false"
      />
      <v-card-text>
        <div class="d-flex align-center justify-center">
          <div style="width: 275px; height: 220px">
            <v-img :src="require('@/assets/svg/create.svg')" />
          </div>
        </div>
        <AOIInfo
          @submit="submitCreate"
          @saveLayer="name => saveLayer(name)"
          :type="'name'"
          :dialog="createDialog"
          :isSaveLayer="isSaveLayer"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import AOIInfo from '@/views/aoi/AOIInfo.vue'
import AOIWithin from '@/utils/AOIWithin'
import utils from '@/utils/genUUID'
import { mapState } from '@/store/ults'

export default {
  name: 'CreateAOI',
  components: { AOIInfo, DialogHeader },
  data() {
    return {
      loading: false,
      geometry: null,
      createDialog: false,
      layerName: undefined,
      vectorType: undefined,
      typeCreateAOI: '',
      isSaveLayerMenus: ['earthquake', 'forest-fire'],
    }
  },
  computed: {
    ...mapState('AOI', ['currentGroup', 'queryData', 'groupsAOI']),
    isSaveLayer() {
      return this.isSaveLayerMenus.includes(this.$route.name)
    },
  },
  methods: {
    openDialog(geometry, name = undefined, vectorType = undefined, typeCreateAOI = '') {
      this.geometry = geometry
      if (name && vectorType) this.saveLayer(name, vectorType)
      else this.createDialog = true
      this.typeCreateAOI = typeCreateAOI
    },
    saveLayer(name, vectorType = 'line') {
      let features = this.geometry
      if (!features.length) return this.$store.commit('message/SHOW_ERROR', 'Feature is required')
      let vector
      switch (vectorType) {
        case 'polygon':
        case 'line':
          vector = 'line'
          break
        case 'point':
          vector = 'circle'
          break
      }
      if (vectorType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$parent.$parent.addMarkerSymbolToMap(name, featureCollection, 'yellow')
      } else if (vectorType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$parent.$parent.addTextToMap(name, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$parent.$parent.addGeoJsonToMap(name, featureCollection, 'yellow', utils.getUUID(), vector, true)
      }
      this.createDialog = false
      this.$parent.cancelDraw()
      // this.drawType = undefined
      // name = ''
      // this.$parent.$parent.deleteAllDraw()
      // this.isDraw = false
    },
    async submitCreate(info) {
      try {
        this.loading = true
        let geometry = {
          coordinates: [],
          type: 'MultiPolygon',
        }
        let check = true
        this.geometry.forEach(feature => {
          if (!AOIWithin.check(feature)) check = false
          geometry.coordinates.push(feature.geometry.coordinates)
        })
        if (!check) return this.$store.commit('message/SHOW_ERROR', 'AOI is outside the regulated area')
        let data = { params: { ...info, geometry: geometry }, projectId: this.$route.params.id }
        await this.$store.dispatch('AOI/createAOI', { data, typeCreateAOI: this.typeCreateAOI })
        this.$parent.$parent.isCreating = false
        // this.$parent.isCreating = false
        this.$parent.cancelDraw()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.createDialog = false
      }
    },
  },
}
</script>

<style scoped></style>
