import config from "@/config";
import Echo from "laravel-echo";
window.io = require("socket.io-client");

export default {
  namespaced: true,
  state: {
    echoChannel: undefined
  },
  getters: {},
  mutations: {},
  actions: {
    initEcho({ state }, token) {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: `${config.host_socket}`,
        transport: ["websocket", "polling"],
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      })

      state.echoChannel = window.Echo
    },
  },
}
