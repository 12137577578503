import { bearing, bezierSpline, center, length, lineString, polygon } from 'turf/turf'

import { Arrow, convertArrowLineByBottom, createArrowBody, createArrowHead, createArrowHeadTail } from './Arrow'

let RATIO = 0.7
let RATIO_ARROW = 2

export function ArrowSimpleDraw(_draw, _feature = {}) {
  let parent = new Arrow(
    _draw,
    {
      done: coordinates => createArrow(coordinates),
      build: coordinates => createSimpleArrow(coordinates),
    },
    _feature,
  )
  parent.start = () => {
    _draw.start({ min_point: 3, max_point: 4 })
  }
  parent.draw_type = 'ArrowSimpleDraw'
  return parent
}

function createArrow(coordinates) {
  if (!coordinates || coordinates.length < 3) {
    return []
  }
  let _bottom_bearing = bearing(coordinates[0], coordinates[1])

  let _bottom_line = lineString([coordinates[0], coordinates[1]])
  let _bottom_len = length(_bottom_line)
  let _bottom_center = center(_bottom_line).geometry.coordinates

  let _mid_bearing = bearing(_bottom_center, coordinates[2])

  let _dif_bearing = _bottom_bearing - _mid_bearing
  if ((_dif_bearing > -180 && _dif_bearing < 0) || (_dif_bearing > 180 && _dif_bearing < 360)) {
    let temp = coordinates[0]
    coordinates[0] = coordinates[1]
    coordinates[1] = temp
  }

  let max_offset_len = _bottom_len / 2
  // coordinates.unshift(_head_tail_coordinates)
  let _arrow_line_coordinates = convertArrowLineByBottom(coordinates.slice(2), _bottom_line)
  // let _arrow_line_coordinates = [_bottom_center, ...coordinates.slice(2)]
  let { _coordinates_1, _coordinates_2 } = createArrowBody(_arrow_line_coordinates, max_offset_len, RATIO)
  let _offset_len = max_offset_len * (1 - RATIO)
  let { _tail_1_coordinates, _tail_2_coordinates } = createArrowHeadTail(
    coordinates[coordinates.length - 1],
    _arrow_line_coordinates[_arrow_line_coordinates.length - 2],
    _offset_len,
  )
  _coordinates_1.unshift(coordinates[1])
  _coordinates_1.push(_tail_1_coordinates)
  _coordinates_2.unshift(coordinates[0])
  _coordinates_2.push(_tail_2_coordinates)

  let _array_1 = _coordinates_1
  let _array_2 = _coordinates_2.reverse()
  let { _head_arrow_coordinates, _wing_1_coordinates, _wing_2_coordinates } = createArrowHead(
    coordinates[coordinates.length - 1],
    bearing(
      _arrow_line_coordinates[_arrow_line_coordinates.length - 2],
      _arrow_line_coordinates[_arrow_line_coordinates.length - 1],
    ),
    _offset_len * RATIO_ARROW,
  )
  let poly = polygon([
    [
      ...bezierSpline(lineString(_array_1), { sharpness: 0.5 }).geometry.coordinates,
      _wing_1_coordinates,
      _head_arrow_coordinates,
      _wing_2_coordinates,
      ...bezierSpline(lineString(_array_2), { sharpness: 0.5 }).geometry.coordinates,
      _array_1[0],
    ],
  ])

  let res = poly
  // let _kinks = kinks(poly);
  // let _total_area = area(poly);
  // if (_kinks.features.length) {
  //   let polys = unkinkPolygon(poly);
  //   res = union(...polys.features.filter((x) => area(x) > _total_area * 0.01));
  // }
  return [res]
}

function createSimpleArrow(coordinates) {
  if (!coordinates || coordinates.length < 3) {
    return []
  }

  let _bottom_bearing = bearing(coordinates[0], coordinates[1])

  let _bottom_line = lineString([coordinates[0], coordinates[1]])
  let _bottom_len = length(_bottom_line)
  let _bottom_center = center(_bottom_line).geometry.coordinates

  let _mid_bearing = bearing(_bottom_center, coordinates[2])

  let _dif_bearing = _bottom_bearing - _mid_bearing
  if ((_dif_bearing > -180 && _dif_bearing < 0) || (_dif_bearing > 180 && _dif_bearing < 360)) {
    let temp = coordinates[0]
    coordinates[0] = coordinates[1]
    coordinates[1] = temp
  }

  let max_offset_len = _bottom_len / 2

  // coordinates.unshift(_head_tail_coordinates)
  let _arrow_line_coordinates = convertArrowLineByBottom(coordinates.slice(2), _bottom_line)
  let _offset_len = max_offset_len * (1 - RATIO)

  let { _head_arrow_coordinates, _wing_1_coordinates, _wing_2_coordinates } = createArrowHead(
    _arrow_line_coordinates[_arrow_line_coordinates.length - 1],
    bearing(
      _arrow_line_coordinates[_arrow_line_coordinates.length - 2],
      _arrow_line_coordinates[_arrow_line_coordinates.length - 1],
    ),
    _offset_len * RATIO_ARROW,
  )
  let { _coordinates_1, _coordinates_2 } = createArrowBody(_arrow_line_coordinates, max_offset_len, RATIO)
  let { _tail_1_coordinates, _tail_2_coordinates } = createArrowHeadTail(
    coordinates[coordinates.length - 1],
    _arrow_line_coordinates[_arrow_line_coordinates.length - 2],
    _offset_len,
  )
  _coordinates_1.unshift(coordinates[1])
  _coordinates_1.push(_tail_1_coordinates)
  _coordinates_2.unshift(coordinates[0])
  _coordinates_2.push(_tail_2_coordinates)
  let res = [
    lineString([coordinates[0], coordinates[1]]),
    lineString(_arrow_line_coordinates),
    lineString([_wing_1_coordinates, _head_arrow_coordinates, _wing_2_coordinates]),
    lineString(_coordinates_1),
    lineString(_coordinates_2),
  ]
  return res
}
