<template>
  <div style="display: inline-flex;flex-direction: column;">
    <v-btn-toggle dense v-model="toggle_exclusive">
      <v-btn color="#1e1e48" small @click="openSaveDialog()">
        <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
        Save
      </v-btn>
      <v-btn color="#1e1e48" small @click="cancelDraw()">
        <v-icon class="mr-1" small>mdi-cancel</v-icon>
        Cancel
      </v-btn>
    </v-btn-toggle>
    <CreateAOI ref="createAOI" />
  </div>
</template>

<script>
import CreateAOI from '@/views/aoi/CreateAOI.vue'

export default {
  name: 'DrawTool',
  components: { CreateAOI },
  props: {
    type: { type: String, default: 'polygon' },
    isDrawing: { type: Boolean, default: false },
    typeCreateAOI: { type: String, default: '' },
  },
  data() {
    return {
      toggle_exclusive: undefined,
      layerName: '',
      vectorType: '',
    }
  },
  methods: {
    createLayer(name, vectorType) {
      this.layerName = name
      this.vectorType = vectorType
    },
    openSaveDialog() {
      let geometry = this.$parent.getAllDrawFeature()
      this.$refs.createAOI.openDialog(geometry, this.layerName, this.vectorType, this.typeCreateAOI)
    },
    cancelDraw() {
      this.$parent.isDrawing = false
      this.$parent.resetDraw()
      this.layerName = undefined
      this.vectorType = undefined
    },
  },
  watch: {
    isDrawing(val) {
      if (val) this.toggle_exclusive = undefined
    }
  }
}
</script>
