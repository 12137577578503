<template>
  <div class="d-flex flex-column align-center">
    <v-tooltip bottom v-if="!isBottom">
      <template v-slot:activator="{ attrs, on }">
        <v-btn small icon v-on="on" v-bind="attrs" @click="scrollToBottom" class="btn-scroll-down">
          <v-icon small>
            mdi-arrow-down
          </v-icon>
        </v-btn>
      </template>
      <span>Scroll to bottom</span>
    </v-tooltip>

    <div class="chat-box-footer d-flex align-center">
      <form class="chat-box-footer" @submit.prevent>
        <v-textarea
          ref="textarea"
          class="chat-textarea"
          v-model="message"
          placeholder="Type a message"
          outlined
          dense
          hide-details
          no-resize
          rows="1"
          row-height="24"
          auto-grow
          color="primary"
          autofocus
          dark
          @keydown="handleKeydown"
          @click:append="scrollToBottom"
        >
          <template v-slot:append>
            <v-tooltip bottom v-if="!typingText">
              <template v-slot:activator="{ attrs, on }">
                <v-btn type="submit" class="btn-send" small :disabled="message.length === 0" @click="sendMessage" icon v-on="on" v-bind="attrs">
                  <v-icon small>mdi-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>Send</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ attrs, on }">
                <v-btn class="btn-send" small icon v-on="on" v-bind="attrs" @click="stopAnswer">
                  <v-icon small>mdi-stop</v-icon>
                </v-btn>
              </template>
              <span>Stop typing</span>
            </v-tooltip>
          </template>
        </v-textarea>
      </form>
    </div>
    <span class="mt-1">
      Press <code>Enter</code> to send message, <code>Shift + Enter</code> to break new line
    </span>
  </div>
</template>
<script>
import { sendMessage } from '@/api/chat-api'
import { mapState } from '@/store/ults'

export default {
  props: {
    scrollToBottom: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState('chat', ['isBottom', 'messages', 'typingText', 'loadingAnswer']),
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    stopAnswer() {
      this.$store.commit('chat/SET_STATE_PROPERTY', { property: 'typingText', value: false })
    },
    sendMessage() {
      if (this.message.length > 0 && !this.typingText && !this.loadingAnswer) {
        this.$store.dispatch('chat/sendMessage', { message: this.message, projectUuid: this.$route.params.id })
        this.message = ''
      }
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.sendMessage()
      }
    },
    makeNewConversation() {
      this.$emit('makeNewConversation')
    },
    focusTextarea() {
      this.$nextTick(() => {
        this.$refs.textarea.focus()
      })
    }
  },
  watch: {
    messages: {
      handler() {
        this.focusTextarea()
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  &.theme--dark.v-icon:focus::after {
    background-color: transparent !important;
  }
  &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
    padding-right: 6px !important;
  }
  &.v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense .v-input__append-inner {
    margin-top: 5px !important;
  }
}
$padding: 8px;

.chat-box-footer {
  z-index: 1;
  display: flex;
  align-items: flex-end;
  width: calc(100% - #{$padding});
  height: auto;
  max-height: 150px;
  overflow: hidden;
  .chat-textarea {
    margin-bottom: -2px;
  }
  .btn-send {
    background-color: #5d5f6b;
    border-radius: 50%;
    border: none;
  }
}
.btn-scroll-down {
  border: 1px solid #656565;
  margin-top: -34px;
  margin-bottom: 4px;
  background-color: #2f3241;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
