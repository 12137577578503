<template>
  <v-row @click="showDetail" @mouseover="showActions = true" @mouseleave="showActions = false">
    <!-- <v-col cols="2" class="d-flex align-center justify-end pr-0">
      <v-avatar size="36">
        <img :src="imageSource">
      </v-avatar>
    </v-col> -->
    <v-col cols="12" class="pl-5">
      <div class="title-noti">
        <span class="reference_type" :style="reference_type[item.reference_type].styles">{{ reference_type[item.reference_type].name }}</span>
        <span class="project ml-4" v-if="Object.prototype.hasOwnProperty.call(item.reference_data, 'project')">Project: {{ item.reference_data.project.name }}</span>
      </div>
      <div class="description-noti">{{ item.description }}</div>
      <div class="time">
        <v-icon x-small>mdi-clock-outline</v-icon>
        <span class="ml-2">{{ item.created_at | calculateDateDifference }}</span>
      </div>
    </v-col>
    <div class="alert-icon">
      <v-icon v-if="item.is_alert" class="alert">mdi-alert-outline</v-icon>
    </div>
    <div v-if="showActions" class="actions d-flex justify-space-around">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="clickable" x-small icon width="60" height="60" v-on="on" v-bind="attrs"
            @click.stop="showDetail">
            <v-icon small>mdi-send-outline</v-icon>
          </v-btn>
        </template>
        <span>Show detail</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="clickable" x-small icon width="60" height="60" v-on="on" v-bind="attrs"
            @click.stop="markAsRead">
            <v-icon small v-if="!item.read">mdi-circle-outline</v-icon>
            <v-icon small v-else>mdi-check-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ item.read ? 'Mark as unread' : 'Mark as read' }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="clickable" x-small icon width="60" height="60" v-on="on" v-bind="attrs"
            @click.stop="deleteNoti">
            <v-icon small>icon-trash</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </div>
  </v-row>
</template>
<script>
import { REFERENCE_TYPE, STATUS_TYPE } from '@/constants/notification';
import calculateDateDifference from '@/utils/calculateDateDifference';
import { checkGrammar } from '@/utils/stringHandle';

export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    menuServices() {
      return this.$store.getters['notification/menuServices']
    }
  },
  data: () => {
    return {
      status_type: STATUS_TYPE,
      reference_type: REFERENCE_TYPE,
      showActions: false,
      // imageSource: `https://www.fao.org/images/newsroomlibraries/breafing-notes/36949400340_030e4ae5f9_oab4ccd35-fd6a-4230-bd2e-f0113f50357d.jpg?sfvrsn=426ca1c_3`,
    }
  },
  methods: {
    showDetail() {
      let nameRoute = ''
      switch (this.item.reference_type) {
        case 'result':
          nameRoute = this.menuServices[this.item.reference_data.service.id].replace('_', '-')
          break
        case 'forest_fire':
          nameRoute = 'forest-fire'
          break
        case 'earthquake':
          nameRoute = 'earthquake'
          break
        case 'earthquake':
          nameRoute = 'earthquake'
          break
        case 'tasking_order':
          nameRoute = 'tasking-orders'
          break
        case 'order':
          nameRoute = 'orders'
          break
        default:
          nameRoute = 'error-404'
      }

      if (Object.prototype.hasOwnProperty.call(this.item.reference_data, 'project')) {
        const currentRouter = this.$router.name
        if (currentRouter == nameRoute) {
          this.$router.replace({
            name: nameRoute,
            params: { id: this.item.reference_data.project.uuid },
            query: { notification: true, noti: this.item.uuid }
          })
        } else {
          this.$router.push({
            name: nameRoute,
            params: { id: this.item.reference_data.project.uuid },
            query: { notification: true, noti: this.item.uuid }
          })
        }
        this.$emit('close')
      } else {
        console.warn('Notification has no project')
        this.$store.commit('message/SHOW_ERROR', 'No result for show detail')
      }
      if (!this.item.read) this.$store.dispatch('notification/markAsRead', this.item.uuid)
    },
    markAsRead() {
      this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'isOpenChildElement', value: true })
      if (this.item.read) {
        this.$store.dispatch('notification/markAsUnread', this.item.uuid)
      } else {
        this.$store.dispatch('notification/markAsRead', this.item.uuid)
      }
    },
    deleteNoti() {
      this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'isOpenChildElement', value: true })
      this.$store.dispatch('notification/deleteNotification', this.item.uuid)
    }
  },
  filters: {
    calculateDateDifference(value) {
      const timeDifference = calculateDateDifference(value)
      if (timeDifference.years > 0) {
        return `${checkGrammar(timeDifference.years, 'year')} ago`;
      } else if (timeDifference.months > 0) {
        return `${checkGrammar(timeDifference.months, 'month')} ago`;
      } else if (timeDifference.days > 0) {
        return `${checkGrammar(timeDifference.days, 'day')} ago`;
      } else if (timeDifference.hours > 0) {
        return `${checkGrammar(timeDifference.hours, 'hour')} ago`;
      } else if (timeDifference.minutes > 0) {
        return `${checkGrammar(timeDifference.minutes, 'minute')} ago`;
      } else {
        return 'Just now';
      }
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .alert.theme--dark.v-icon {
  color: #db1212 !important;
}

.reference_type {
  font-size: 14px;
  border-radius: 6px;
  padding: 0px 6px;
}

.actions {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.title-noti {
  font-size: 16px !important;
  margin-bottom: 6px;
}

.description-noti {
  color: #b9b9b9;
}

.time {
  color: #b9b9b9;
  font-size: 12px !important;
  margin-top: 5px;
}

.alert-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
