export default class RasterLayer {
  constructor(obj) {
    this._layerType = obj.layerType ? obj.layerType : 'Raster_Image'
    this._legend = false
    this._data = obj.data
    this._showLegend = false
    this._dataId = obj.dataId
    this._id = obj.id
    this._name = obj.name ? obj.name : obj.id
    this._paint = obj.paint ? obj.paint : this.getDefaultPaint(obj.opacity)
    this._tileUrl = obj.tiles
    this._layerLeft = obj.layerLeft
    this._layerRight = obj.layerRight
    this._minZoom = obj.min_zoom ? obj.min_zoom : 0
    this._maxZoom = obj.max_zoom ? obj.max_zoom : 22
    this._type = 'raster'
    this._opacity = obj.opacity ? obj.opacity : 0
    this._bbox = obj.bbox ? obj.bbox : [-180.0, -85.0511, 180.0, 85.0511]
  }

  get legend() {
    return this._legend
  }

  set legend(newValue) {
    this._legend = newValue
  }

  get dataId() {
    return this._dataId
  }

  set dataId(newValue) {
    this._dataId = newValue
  }

  get isRaster() {
    return true
  }

  get showLegend() {
    return this._showLegend
  }

  set showLegend(newValue) {
    this._showLegend = newValue
  }

  get id() {
    return this._id
  }

  get type() {
    return this._type
  }

  get name() {
    return this._name
  }

  set name(newValue) {
    this._name = newValue
  }

  get layerRight() {
    return this._layerRight
  }

  set layerRight(newValue) {
    this._layerRight = newValue
  }

  get layerLeft() {
    return this._layerLeft
  }

  set layerLeft(newValue) {
    this._layerLeft = newValue
  }

  get opacity() {
    return this._opacity
  }

  set opacity(newValue) {
    this._opacity = newValue
  }

  get paint() {
    return this._paint
  }

  set paint(newValue) {
    this._paint = newValue
  }

  get tileUrl() {
    return this._tileUrl
  }

  get bbox() {
    return this._bbox
  }

  set bbox(newValue) {
    this._bbox = newValue
  }

  getDefaultPaint(opacity) {
    return {
      'raster-opacity': opacity ? opacity : 0,
      'raster-contrast': 0,
      'raster-brightness-min': 0,
      'raster-brightness-max': 1,
    }
  }

  getMapboxLayer() {
    return {
      maxzoom: 22,
      minzoom:  this._minZoom,
      opacity: this._opacity,
      layerLeft: this._layerLeft,
      layerRight: this._layerRight,
      id: this._id,
      data:this._data? this._data : undefined,
      dataId: this._dataId,
      name: this._name,
      layerType: this._layerType,
      isProperties: this._layerType === 'Raster_Image',
      isRaster: true,
      typeStyle: 'raster',
      type: 'raster',
      bbox: this._bbox,
      source: this._id,
      layout: {
        visibility: this._layerRight ? 'visible' : 'none',
      },
      paint: this._paint,
    }
  }

  getTiles() {}
}
