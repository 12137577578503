<template>
  <v-layout class="fill-height overflow-y-hidden pa-2" column>
    <div style="width: 100%; height: 50px; flex: none">
      <v-text-field
        v-model="search"
        append-icon="icon-search"
        append-outer-icon="mdi-filter-cog-outline"
        dense
        hide-details
        label="Search"
        outlined
        placeholder="Search"
      >
        <template v-slot:append-outer>
          <v-menu v-model="filter" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                  <v-icon
                    :class="filters.from_date || filters.to_date ? 'active-filter' : ''"
                    v-bind="attrs"
                    v-on="{ ...on, ...tooltipOn }"
                  >
                    mdi-filter-cog-outline
                  </v-icon>
                </template>
                <span>Filter Settings</span>
              </v-tooltip>
            </template>
            <v-card class="pa-3 elevation-4" style="width: 320px">
              <v-layout class="fill-height" column>
                <div class="pb-1">
                  <DatePicker v-model="filters.from_date" :max="filters.to_date" label="From date" left outlined />
                </div>
                <div class="pt-1">
                  <DatePicker v-model="filters.to_date" :min="filters.from_date" label="To date" left outlined />
                </div>
                <v-layout class="pt-3">
                  <v-spacer />
                  <v-btn class="mr-2" color="error" small @click="resetFilter">
                    Reset
                  </v-btn>
                  <v-btn color="primary" small @click="applyFilter">
                    Apply
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </div>
    <div style="width: 100%; height: 50px; flex: none">
      <v-layout align-center class="fill-height">
        Group by:
        <v-spacer />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-capitalize" color="primary" small v-bind="attrs" v-on="on">
              {{ groupBy }}
              <v-icon class="ml-1">
                icon-chevron_down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="groupBy = 'Date'">
              <v-list-item-title class="pl-1">
                Date
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="groupBy = 'Type'">
              <v-list-item-title class="pl-1">
                Type
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="groupBy = 'Data type'">
              <v-list-item-title class="pl-1">
                Data type
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>
    </div>
    <v-layout class="overflow-y-auto pt-2" column style="height: calc(100% - 50px)">
      <v-expansion-panels class="custom-expansion" multiple>
        <v-expansion-panel v-for="(group, index) in availableData" :key="'group' + index">
          <v-expansion-panel-header>
            <div class="pr-4" style="flex: none">
              <CustomIcon icon="folder" />
            </div>
            <span class="uppercase">{{ group.name }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pb-2">
            <div
              v-for="(item, index) in group.items"
              :key="index"
              class="my-2 custom-card-bg-1 pa-2"
              style="width: 100%; min-height: 75px;"
            >
              <v-layout align-center fill-height>
                <div style="height: 70px; width: 70px; border-radius: 8px; text-align: center; flex: none">
                  <v-layout align-center class="fill-height">
                    <v-img
                      v-if="item.tile_info.thumbnail_url"
                      :lazy-src="item.tile_info.thumbnail_url"
                      :src="item.tile_info.thumbnail_url"
                      alt="tbn"
                      contain
                      max-height="100%"
                      max-width="100%"
                    >
                      <template v-slot:placeholder>
                        <v-row align="center" class="fill-height ma-0" justify="center">
                          <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon v-else-if="item.type === 'aoi'" size="30" style="margin: auto">
                      mdi-vector-square
                    </v-icon>
                    <v-icon v-else size="30" style="margin: auto">icon-layers</v-icon>
                  </v-layout>
                </div>
                <v-layout class="pl-4" column style="flex: 1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" v-bind="attrs" v-on="on">{{ item.name }}</div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <div v-if="item.type !== 'aoi'" class="caption pt-2">AOI: {{ item.aoi ? item.aoi.name : '' }}</div>
                  <v-layout align-center class="pt-0" style="flex: 1">
                    <div class="caption pt-0">Date: {{ item.date }}</div>
                    <v-spacer />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small v-bind="attrs" v-on="on" @click="addToMap(item)">
                          <v-icon>mdi-map</v-icon>
                        </v-btn>
                      </template>
                      <span>Add to map</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon target="_blank" x-small v-bind="attrs" v-on="on" @click="downloadData(item)">
                          <v-icon>icon-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </v-layout>
                </v-layout>
              </v-layout>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>
    <DownloadVector ref="downloadVector" />
  </v-layout>
</template>
<script>
import convertDate from '@/utils/convertDate'
import CustomIcon from '@/components/CustomIcon.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getDetailAOI } from '@/api/aoi-api'
import DownloadVector from '@/components/DownloadVector.vue'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
export default {
  components: { DownloadVector, DatePicker, CustomIcon },
  data() {
    return {
      filter: false,
      search: undefined,
      filters: {
        from_date: undefined,
        to_date: undefined,
      },
      groupBy: 'Date',
    }
  },
  watch: {
    filter(val) {
      if (!val) this.applyFilter()
    },
  },
  props: {
    data: { type: Array, default: () => [] },
  },
  computed: {
    listData: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      },
    },
    filteredItems() {
      if (this.search) {
        return this.listData.filter(
          data =>
            data.name.toUpperCase().includes(this.search.toUpperCase()) ||
            data.date.toUpperCase().includes(this.search.toUpperCase()),
        )
      } else return this.listData
    },
    availableData() {
      let groups = []
      switch (this.groupBy) {
        case 'Date':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === convertDate.yearMonthFormatted(item.acquired_date))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: convertDate.yearMonthFormatted(item.acquired_date),
                items: [item],
              })
          })
          groups.sort((a, b) => {
            if (a.name < b.name) return 1
            if (a.name > b.name) return -1
            return 0
          })
          return groups
        case 'Type':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === item.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
        case 'Data type':
          this.filteredItems.forEach(item => {
            let index = groups.findIndex(group => group.name === item.tile_info.type.replaceAll('_', ' '))
            if (index >= 0) groups[index].items.push(item)
            else
              groups.push({
                name: item.tile_info.type.replaceAll('_', ' '),
                items: [item],
              })
          })
          return groups
      }
    },
  },
  methods: {
    async addToMap(data) {
      if (data.tile_info.type === 'aoi') {
        data.geojson = await this.getDetailAOI(data)
      }
      this.$emit('addToMap', data)
    },
    downloadData(item) {
      if (item.tile_info.type === 'aoi') this.downloadAOI(item)
      else {
        let tmpData = JSON.parse(JSON.stringify(item))
        tmpData.isRaster = item.tile_info.type === 'images'
        tmpData.data = {
          data: {
            download_url: item.tile_info.download_url,
          },
        }
        this.$refs.downloadVector.openDialog(tmpData)
      }
    },
    async downloadAOI(AOI) {
      this.$refs.downloadVector.openDialog({
        name: AOI.name,
        isGeojson: true,
        geometry: await this.getDetailAOI(AOI),
      })
    },
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    applyFilter() {
      this.filter = false
      this.$emit('onFilter', this.filters)
    },
    resetFilter() {
      this.filters = {
        search: undefined,
        from_date: undefined,
        to_date: undefined,
      }
    },
  },
}
</script>

<style scoped>
.active-filter {
  color: var(--v-primary-base);
}
.uppercase {
  text-transform: uppercase;
}
</style>
