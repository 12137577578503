<template>
  <div class="d-flex flex-column">
    <v-tooltip v-for="(menu, index) in listDrawMenuAvailable" :key="index" color="#000000c4" left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="elevation-0 mb-1"
          :color="selectedDrawMode == menu.mode ? 'primary' : 'bgContainer'"
          height="38"
          width="38"
          min-height="0"
          min-width="0"
          v-bind="attrs"
          v-on="on"
          small
          @click="chooseDrawMode(menu.mode)"
        >
          <v-icon :color="selectedDrawMode == menu.mode ? '#fff' : 'secondary'" size="22">{{ menu.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ menu.text }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    drawType: { type: String, default: 'polygon' },
    selectedDrawMode: { type: String, default: 'polygon' },
  },
  computed: {
    listDrawMenuAvailable() {
      return this.listDrawMenu.filter((menu) => menu.visiable)
    },
    listDrawMenu() {
      return [
        { icon: 'mdi-vector-polygon', text: 'Draw polygon (3)', mode: 'polygon', visiable: !this.drawType || this.drawType === 'polygon' },
        { icon: 'mdi-rectangle-outline', text: 'Draw rectangle (4)', mode: 'rectangle', visiable: !this.drawType || this.drawType === 'polygon' },
        { icon: 'mdi-vector-rectangle', text: 'Draw assist rectangle (5)', mode: 'assist-rectangle', visiable: !this.drawType || this.drawType === 'polygon' },
        { icon: 'mdi-vector-line', text: 'Draw line (6)', mode: 'LineString', visiable: this.drawType === 'line' },
        { icon: 'mdi-record-circle-outline', text: 'Draw point (7)', mode: 'Point', visiable: this.drawType === 'point' },
      ]
    }
  },
  methods: {
    chooseDrawMode(mode) {
      this.$emit('changeMode', mode)
    }
  },
}
</script>
