<template>
  <v-tab-item>
    <v-list v-if="listNotifications.length > 0">
      <div v-for="(notification, i) in listGroupNotifications" :key="i">
        <v-subheader v-if="notification.isGroup">
          {{ notification.group | formatNotification }}
        </v-subheader>
        <v-list-item v-else class="item" :class="notification.read ? 'noti-read' : 'noti-unread'">
          <v-list-item-content>
            <ItemNotification :item="notification" @close="close"/>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <div v-else class="d-flex justify-center">{{ noContent }}</div>
    <infinite-loading v-if="listNotifications.length > perPage - 1" @infinite="loadMoreData" ref="infiniteLoading">
      <span slot="no-more">No more notifications.</span>
      <span slot="no-results"></span>
    </infinite-loading>
  </v-tab-item>
</template>
<script>
import ItemNotification from './item-notification.vue'
import InfiniteLoading from 'vue-infinite-loading'
import ConvertDate from '@/utils/convertDate'
import { OLDER } from '@/constants/categories-date'

export default {
  components: {
    ItemNotification,
    InfiniteLoading
  },
  props: {
    listNotifications: {
      type: Array,
      default: [],
    },
    noContent: {
      type: String,
      default: 'No notification found.',
    },
  },
  computed: {
    perPage() {
      return this.$store.getters['notification/perPage']
    },
    listGroupNotifications() {
      const listNotifications = []
      const groups = []
      this.listNotifications.forEach(notification => {
        const group = ConvertDate.categorizeDates(notification.created_at)
        if (!groups.includes(group)) {
          groups.push(group)
          listNotifications.push({
            group,
            isGroup: true
          })
        }
        listNotifications.push(notification)
      })
      return listNotifications
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async loadMoreData($state) {
      const res = await this.$store.dispatch('notification/getMoreNotifications')
      if (res.length > 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
  filters: {
    formatNotification(noti) {
      if (noti == OLDER) return 'Older notifications'
      return noti
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  &.v-list {
    padding-top: 0;
  }
  &.v-list-item {
    padding: 0 2px !important;
  }
  &.theme--dark.v-list {
    background-color: transparent !important;
  }
  &.v-subheader {
    padding: 0 0 0 4px;
    font-size: 16px;
  }
}
.item {
  margin: 3px 2px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #303249;
  }
}
.noti-unread {
  background-color: #323447;
}
</style>
