export default {
  namespaced: true,
  state: {
    text: '',
    snackBar: false,
    color: '#fc0303',
  },
  getters: {
    text(state) {
      return state.text
    },
    snackBar(state) {
      return state.snackBar
    },
    color(state) {
      return state.color
    },
  },
  mutations: {
    SHOW_SUCCESS(state, newValue) {
      state.text = newValue
      state.snackBar = true
      state.color = '#04c40e'
    },
    SHOW_ERROR(state, newValue) {
      state.text = newValue
      state.snackBar = true
      state.color = '#fc0303'
    },
    SHOW_WARNING(state, newValue) {
      state.text = newValue
      state.snackBar = true
      state.color = '#ec942c'
    },
  },
}
