<template>
  <v-app
    style="background-repeat: no-repeat;
    background-size: auto 100%; background-color: var(--v-background-base)"
    v-bind:style="{ 'background-image': `url(${require('@/assets/images/project/bg.svg')})` }"
  >
    <div class="elevation-2" style="flex: none; height: 64px; width: 100%;">
      <div style="width: 100%; height: 100%">
        <v-layout align-center class="fill-height px-6">
          <router-link
            :to="currentUser.role === SYSADMIN ? '/admin' : '/projects'"
            class="d-flex align-center text-decoration-none"
          >
            <img
              :src="require('@/assets/images/logos/logo.png')"
              alt="logo"
              class="app-logo me-3"
              contain
              eager
              max-height="30px"
              max-width="30px"
              width="85"
            />
            <v-slide-x-transition>
              <div class="text--primary" style="font-size: 18px">
                Sustainability Monitoring Platform
                <div style="font-size: 11px">
                  MEASURE, REPORT & VERIFY
                </div>
                <div style="font-size: 9px">
                  (Everything that matters to our planet)
                </div>
              </div>
            </v-slide-x-transition>
          </router-link>
          <v-spacer />
          <Notification />
          <app-bar-user-menu></app-bar-user-menu>
        </v-layout>
      </div>
    </div>
    <Message />
    <v-main
      style="height: calc(100vh - 65px); overflow-y: auto; overflow-x: hidden; background-repeat: no-repeat;
    background-size: auto 100%;"
    >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Notification from '@/components/notification/Notification.vue'
import Message from '@/components/Message.vue'
import { SYSADMIN } from '@/constants/role'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import { getSettingMenus } from '@/api/menu-api'

export default {
  components: {
    AppBarUserMenu,
    Message,
    Notification
  },
  data: () => ({
    SYSADMIN,
  }),
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  mounted() {
    this.getMenus()
  },
  methods: {
    getMenus() {
      getSettingMenus().then(res => {
        this.$store.commit('menu/SET_MENU', res.data)
      })
    },
  },
}
</script>

<style></style>
