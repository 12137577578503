import { getUUIDv4 } from '@/utils/helper'

import { Marker } from 'mapbox-gl'

export const MarkerDraw = function(map) {
  let _map = map
  let _id = `${getUUIDv4()}`
  let _coordinates = []
  let _markers = []

  this.id = _id
  this.color = '#fbb03b'
  let _max_point = 0
  let _min_point = 0
  let _layers = []
  let _source_id = `marker-draw-source-${_id}`
  _map.addSource(_source_id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  let _init = () => {
    _addLayer({
      type: 'line',
      paint: {
        'line-color': ['coalesce', ['get', 'color'], this.color],
        'line-width': 1,
        'line-dasharray': [0.2, 2],
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
    })

    _addLayer({
      type: 'fill', // For fill
      filter: ['==', '$type', 'Polygon'],
      paint: {
        'fill-color': ['coalesce', ['get', 'color'], this.color],
        'fill-opacity': 0.5,
      },
    })
    _addLayer({
      type: 'circle',
      filter: ['==', '$type', 'Point'],
      paint: {
        'circle-radius': 5,
        'circle-color': ['coalesce', ['get', 'color'], this.color],
      },
    })
  }
  let _addLayer = layer => {
    layer = Object.assign({}, layer, {
      id: `marker-draw-layer-${getUUIDv4()}`,
      source: _source_id,
    })
    _map.addLayer(layer)
    _layers.push(layer)
    return layer
  }
  let _removeLayer = layer => {
    if (_map.getLayer(layer.id)) {
      _map.removeLayer(layer.id)
    }
    _layers.filter(x => x.id != layer.id)
  }

  this.destroy = () => {
    _clear()
    _layers.slice().forEach(_removeLayer)
    if (_map.getSource(_source_id)) {
      _map.removeSource(_source_id)
    }
  }
  this.addMarker = marker => {
    marker.addTo(_map)
    _markers.push(marker)
  }

  let _onMapClick = event => {
    const newCoordinate = [event.lngLat.lng, event.lngLat.lat]
    _coordinates.push(newCoordinate)
    let { marker, element } = this.createMarker(newCoordinate, _coordinates.length)
    element.addEventListener('click', () => {
      _checkDone()
    })
    marker.setLngLat(event.lngLat).addTo(_map)
    _markers.push(marker)

    updateSource(_coordinates)

    marker.on('drag', () => {
      const index = _markers.indexOf(marker)
      const lngLat = marker.getLngLat()
      _coordinates[index] = [lngLat.lng, lngLat.lat]
      updateSource(_coordinates)
    })

    if (_max_point && _coordinates.length == _max_point) {
      _done()
    }
  }

  let _onMapMouseMove = event => {
    const newCoordinate = [event.lngLat.lng, event.lngLat.lat]
    updateSource(_coordinates.concat([newCoordinate]))
  }
  let _onMapRightClick = event => {
    event.originalEvent.preventDefault()
    event.originalEvent.stopPropagation()
    _checkDone()
  }
  let _onMapClickBind = _onMapClick.bind(this)
  let _onMapMouseMoveBind = _onMapMouseMove.bind(this)
  let _onMapRightClickBind = _onMapRightClick.bind(this)
  this.start = ({ max_point = 0, min_point = 0 } = {}) => {
    _max_point = max_point
    _min_point = min_point
    _map.getCanvas().classList.add('cursor-crosshair')
    _map.on('click', _onMapClickBind)
    _map.on('mousemove', _onMapMouseMoveBind)
    _map.on('contextmenu', _onMapRightClickBind)
  }

  this.clear = () => {
    _clear()

    let source = map.getSource(_source_id)
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }
  this.init = coordinates => {
    _markers = []
    _coordinates = coordinates

    updateSource(_coordinates)
    _coordinates.forEach((coordinate, index) => {
      let { marker } = this.createMarker(coordinate, index + 1)
      marker.setLngLat(coordinate).addTo(_map)

      marker.on('drag', () => {
        const index = _markers.indexOf(marker)
        const lngLat = marker.getLngLat()
        _coordinates[index] = [lngLat.lng, lngLat.lat]
        updateSource(_coordinates)
      })
      _markers.push(marker)
    })
    _map.on('contextmenu', _onMapRightClickBind)
    _map.on('dblclick', _onMapRightClickBind)
  }
  let _done = () => {
    let features = this.onDone(_coordinates) || []
    _clear()
    let source = _map.getSource(_source_id)
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features,
      })
    }
  }
  let _cancel = () => {
    _clear()
    let source = _map.getSource(_source_id)
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
    this.onCancel()
  }

  let _checkDone = () => {
    if (
      _coordinates.length < 1 ||
      _min_point > _coordinates.length ||
      (_max_point && _coordinates.length < _max_point)
    ) {
      _cancel()
    } else {
      _done()
    }
  }
  let _clear = () => {
    _map.getCanvas().classList.remove('cursor-crosshair')

    _map.off('click', _onMapClickBind)
    _map.off('mousemove', _onMapMouseMoveBind)
    _map.off('contextmenu', _onMapRightClickBind)
    _map.off('dblclick', _onMapRightClickBind)
    _markers.forEach(marker => {
      marker.remove()
    })
    _markers = []
    _coordinates = []
    _max_point = 0
    this.onClear()
  }
  let updateSource = coordinates => {
    let features = this.convertCoordinatesToFeatures(coordinates)
    if (!features) features = []
    let source = _map.getSource(_source_id)
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features,
      })
    }
  }

  this.createMarker = () => {
    let { marker, element } = createMarkerNode({ color: this.color })
    return {
      marker,
      element,
    }
  }
  // eslint-disable-next-line no-unused-vars
  this.convertCoordinatesToFeatures = coordinates => {}
  // eslint-disable-next-line no-unused-vars
  this.onDone = coordinates => {}
  this.onCancel = () => {}
  this.onClear = () => {}
  this.addLayer = _addLayer
  this.removeLayer = _removeLayer
  _init()
}

function createMarkerNode({ color } = {}) {
  const element = document.createElement('div')
  element.style.width = '12px'
  element.style.height = '12px'
  element.style.borderRadius = '50%'
  element.style.background = '#fff'
  element.style.boxSizing = 'border-box'
  element.style.border = `2px solid ${color}`
  element.style.cursor = 'pointer'
  let marker = new Marker({
    element,
    draggable: true,
  })
  return {
    marker,
    element,
  }
}
