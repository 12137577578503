let isFull = false
const Fullscreen = {
  setFullscreen(id) {
    var elem = document.getElementById(id)
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen()
    }
  },
  setFullscreenRef(ref) {
    var elem = ref
    if (this.status()) this.closeFullscreen()
    else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
      }
    }
    document.addEventListener('fullscreenchange', function() {
      isFull = !!document.fullscreenElement
    })
  },
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
  },
  status() {
    return isFull
  },
}
export default Fullscreen
