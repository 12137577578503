var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center"},[(!_vm.isBottom)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-scroll-down",attrs:{"small":"","icon":""},on:{"click":_vm.scrollToBottom}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-down ")])],1)]}}],null,false,1695700194)},[_c('span',[_vm._v("Scroll to bottom")])]):_vm._e(),_c('div',{staticClass:"chat-box-footer d-flex align-center"},[_c('form',{staticClass:"chat-box-footer",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-textarea',{ref:"textarea",staticClass:"chat-textarea",attrs:{"placeholder":"Type a message","outlined":"","dense":"","hide-details":"","no-resize":"","rows":"1","row-height":"24","auto-grow":"","color":"primary","autofocus":"","dark":""},on:{"keydown":_vm.handleKeydown,"click:append":_vm.scrollToBottom},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.typingText)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-send",attrs:{"type":"submit","small":"","disabled":_vm.message.length === 0,"icon":""},on:{"click":_vm.sendMessage}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up")])],1)]}}],null,false,4289991549)},[_c('span',[_vm._v("Send")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-send",attrs:{"small":"","icon":""},on:{"click":_vm.stopAnswer}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-stop")])],1)]}}])},[_c('span',[_vm._v("Stop typing")])])]},proxy:true}]),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)]),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"mt-1"},[_vm._v(" Press "),_c('code',[_vm._v("Enter")]),_vm._v(" to send message, "),_c('code',[_vm._v("Shift + Enter")]),_vm._v(" to break new line ")])}]

export { render, staticRenderFns }