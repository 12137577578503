<template>
  <v-tooltip bottom color="#000000c4">
    <template #activator="{ on, attrs }">
      <div class="cicon-alert" @click="toggleNotification" :style="styleIcon" v-on="on" v-bind="attrs">
        <v-btn icon :disabled="disabled" :class="unreadNotificationsCount ? 'has-noti' : ''">
          <v-icon>icon-notification</v-icon>
        </v-btn>
        <span class="unread-notifications" v-if="unreadNotificationsCount">{{ unreadNotificationsCount }}</span>
      </div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: 'Notifications'
    },
    unreadNotificationsCount: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    styleIcon: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    toggleNotification() {
      this.$emit('toggleNotification')
    }
  }
}
</script>
<style lang="scss" scoped>
.has-noti {
  animation: ring-bell 1s infinite;
}
.cicon-alert {
  position: relative;
  width: 40px;

  .unread-notifications {
    --size: 13px;
    position: absolute;
    top: 0px;
    right: 4px;
    background-color: #e50707;
    color: white;
    height: var(--size);
    line-height: var(--size);
    border-radius: 3px;
    font-size: var(--size);
    text-align: center;
    padding: 0 2px;
  }
}
</style>
