<template>
  <div>
    <div class="d-flex align-center">
      <span class="ml-2 item" @click="markAllAsRead">Mark all as read</span>
      <v-tooltip bottom color="#000000c4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs" @click="goToSettings" small>
            <v-icon small>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <span>Settings</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuNoti: false,
    }
  },
  methods: {
    callAction(action) {
      action()
      this.menuNoti = false
    },
    markAllAsRead() {
      this.$store.dispatch('notification/markAllAsRead')
    },
    goToSettings() {
      this.$emit('closeNotification')
      this.$store.commit('auth/SET_CURRENT_TAB', 2) // to notification tab
      this.$router.push('/pages/account-settings')
    }
  }
}
</script>
<style scoped lang="scss">
.item {
  cursor: pointer;
  text-decoration: underline;
  &:active {
    color: #7c90ff;
  }
}
</style>
