import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkAuth } from './auth'
import {checkCache} from './cacheRoute'
import { ADMIN, SYSADMIN } from '@/constants/role'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: 'dashboard',
  // },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/MaintenancePage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/views/landing-page/LandingPage.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: checkCache,
  },
  {
    path: '/projects/:id',
    name: 'detail',
    redirect: '/projects/:id/group-view',
  },
  {
    beforeEnter: checkAuth,
    name: 'group-view',
    path: '/projects/:id/group-view',
    component: () => import('@/views/group-view/GroupView.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/dashboard',
    name: 'dashboard',
    component: () => import('@/views/change-detection/dashboard/Dashboard.vue'),
    meta: {
      service: 'Change Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/oil-spill',
    name: 'oil-spill',
    component: () => import('@/views/oil-spill/OilSpill.vue'),
    meta: {
      service: 'Oil Spill Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/top-result',
    name: 'top-result',
    component: () => import('@/views/change-detection/top-result/TopResult.vue'),
    meta: {
      service: 'Change Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/tasking-orders',
    name: 'tasking-orders',
    component: () => import('@/views/tasking-order/TaskingOrders.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/quality-assurance-quality-control',
    name: 'quality-assurance-quality-control',
    component: () => import('@/views/QA&QC/QA&QC.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/aqi',
    name: 'aqi',
    component: () => import('@/views/aqi/index.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/classification',
    name: 'classification',
    component: () => import('@/views/classification/Classification.vue'),
    meta: {
      service: 'Classification',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/aoi-management',
    name: 'aoi-management',
    component: () => import('@/views/aoi/AOI.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/map-view',
    name: 'map-view',
    component: () => import('@/views/map-overview/Overview.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/vegetation-indices',
    name: 'vegetation-indices',
    component: () => import('@/views/monitoring/vegetation/Vegetation.vue'),
    meta: {
      service: 'Crop Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/thermal-index',
    name: 'thermal-index',
    component: () => import('@/views/monitoring/thermal/Thermal.vue'),
    meta: {
      service: 'Thermal Discomfort Monitoring',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/zoning',
    name: 'zoning',
    component: () => import('@/views/monitoring/vegetation/Zoning.vue'),
    meta: {
      service: 'Crop Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/flood-detection',
    name: 'flood-detection',
    component: () => import('@/views/flood-detection/FloodDetection.vue'),
    meta: {
      service: 'Flood Detection',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/water-indices',
    name: 'water-indices',
    component: () => import('@/views/monitoring/water/Water.vue'),
    meta: {
      service: 'Water Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/unlicensed',
    name: 'unlicensed',
    component: () => import('@/views/Unlicensed.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/ai-farm-boundary',
    name: 'ai-farm-boundary',
    component: () => import('@/views/interactiveAI/FarmBoundary.vue'),
    meta: {
      service: 'ai-farm-boundary',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/ai-tree-counting',
    name: 'ai-tree-counting',
    component: () => import('@/views/interactiveAI/TreeCounting.vue'),
    meta: {
      service: 'ai-tree-counting',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/weather',
    name: 'weather',
    component: () => import('@/views/weather/Weather.vue'),
    meta: {
      service: 'weather',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/ground-deformation',
    name: 'ground-deformation',
    component: () => import('@/views/ground-deformation/GroundDeformation.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/deforestation',
    name: 'deforestation',
    component: () => import('@/views/forest-fire/Deforestation.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/volume',
    name: 'volume',
    component: () => import('@/views/volume/Volume.vue'),
    meta: {
      service: 'Volume',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/test',
    name: 'a',
    component: () => import('@/views/test/Resize.vue'),
    meta: {
      service: 'Change Detection',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/land-subsidence',
    name: 'land-subsidence',
    component: () => import('@/views/land-subsidence/LandSubsidence.vue'),
    meta: {
      service: 'Land Subsidence',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/soil-moisture',
    name: 'soil-moisture',
    component: () => import('@/views/soil-moisture/SoilMoisture.vue'),
    meta: {
      service: 'Soil Moisture',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/lst',
    name: 'lst',
    component: () => import('@/views/land-surface-temperature/LandSurfaceTemperature.vue'),
    meta: {
      service: 'Land Surface Temperature',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/forest-fire',
    name: 'forest-fire',
    component: () => import('@/views/forest-fire/MapView.vue'),
    meta: {
      service:undefined,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/earthquake',
    name: 'earthquake',
    component: () => import('@/views/forest-fire/MapView.vue'),
    meta: {
      service: undefined,
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/land-indices',
    name: 'land-indices',
    component: () => import('@/views/monitoring/land-cover/land-cover.vue'),
    meta: {
      service: 'Land Cover Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/resource-indices',
    name: 'resource-indices',
    component: () => import('@/views/monitoring/natural-resources/natural-resources.vue'),
    meta: {
      service: 'Resources Indices',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/biodiversity',
    name: 'biodiversity',
    component: () => import('@/views/monitoring/natural-resources/biodiversity.vue'),
    meta: {
      service: 'Biodiversity',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/burn-detection',
    name: 'burn-detection',
    component: () => import('@/views/burn-detection/BurnDetection.vue'),
    meta: {
      service: 'Burn Area Detection',
    },
  },
  {
    path: '/projects/:id/ship-detection',
    name: 'ship-detection',
    component: () => import('@/views/object-detection/ShipDetection.vue'),
    beforeEnter: checkAuth,
    meta: {
      service: 'Ship Detection',
    },
  },
  {
    path: '/projects/:id/aircraft-detection',
    name: 'aircraft-detection',
    component: () => import('@/views/object-detection/AircraftDetection.vue'),
    beforeEnter: checkAuth,
    meta: {
      service: 'Aircraft Detection',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/projects/:id/orders',
    name: 'orders',
    component: () => import('@/views/order/Orders.vue'),
    meta: {},
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/slope',
    name: 'slope',
    component: () => import('@/views/Slope/Slope.vue'),
    meta: {
      service: 'slope',
    },
  },

  {
    beforeEnter: checkAuth,
    path: '/projects/:id/unlicensed',
    name: 'projects-unlicensed',
    component: () => import('@/views/Unlicensed.vue'),
  },

  {
    beforeEnter: checkAuth,
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/project/Project.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/demo-dashboard',
    name: 'demo-dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    beforeEnter: checkAuth,
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/users',
    name: 'users',
    component: () => import('@/views/user-management/users.vue'),
    meta: {
      layout: 'content',
      role: [ADMIN],
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
    beforeEnter: checkCache,
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  //Admin side
  {
    beforeEnter: checkAuth,
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin-side/index.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin-side/user-management/users.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/organizations',
    name: 'admin-organizations',
    component: () => import('@/views/admin-side/organizations/organizations.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/log-debug',
    name: 'admin-log-debug',
    component: () => import('@/views/admin-side/logview/index.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms',
    name: 'admin-cms',
    component: () => import('@/views/admin-side/cms/index.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/domains',
    name: 'admin-cms-domains',
    component: () => import('@/views/admin-side/cms/domain-management/domains.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/groups',
    name: 'admin-cms-groups',
    component: () => import('@/views/admin-side/cms/group-management/groups.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/menus',
    name: 'admin-cms-menus',
    component: () => import('@/views/admin-side/cms/menu-management/menus.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    beforeEnter: checkAuth,
    path: '/admin/cms/structure',
    name: 'admin-cms-structure',
    component: () => import('@/views/admin-side/cms/structure.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  // {
  //   beforeEnter: checkAuth,
  //   path: '/admin/cms/groups',
  //   name: 'admin-cms-groups',
  //   component: () => import('@/views/admin-side/cms/groups.vue'),
  //   meta: {
  //     layout: 'content',
  //     roles: [SYSADMIN],
  //   },
  // },
  // {
  //   beforeEnter: checkAuth,
  //   path: '/admin/cms/menus',
  //   name: 'admin-cms-menus',
  //   component: () => import('@/views/admin-side/cms/menus.vue'),
  //   meta: {
  //     layout: 'content',
  //     roles: [SYSADMIN],
  //   },
  // },
  {
    beforeEnter: checkAuth,
    path: '/admin/orders',
    name: 'admin-order',
    component: () => import('@/views/admin-side/order/order.vue'),
    meta: {
      layout: 'content',
      roles: [SYSADMIN],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/401.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    name: 'not-found',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
