import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@/assets/style/main.scss'
import '@/assets/fontIcon/style.css'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import Antd from 'ant-design-vue';
import '@/assets/style/antd.min.css';
import 'leaflet/dist/leaflet.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

}).$mount('#app')
Vue.use(VueMonacoEditorPlugin, {
    paths: {
      // The recommended CDN config
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    },
  })
Vue.use(Antd);

