<template>
  <v-layout style="position: relative">
    <v-menu
      ref="menu"
      v-model="dataMenu"
      :close-on-content-click="!multiple && !isSearch"
      class="select-menu"
      :offset-y="true"
      bottom
      style="width: 100%;"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="dataMenu = true" style="width: 100%;">
          <v-select
            v-model="selectedData"
            :chips="chips"
            :clearable="clearable"
            :dense="dense"
            :disabled="disabled"
            :hide-details="$attrs['hide-details']"
            :item-text="itemText"
            :item-value="itemValue"
            :items="flatItems"
            :label="label"
            :multiple="multiple"
            :outlined="outlined"
            :placeholder="$attrs['placeholder']"
            :prepend-inner-icon="$attrs['prepend-inner-icon']"
            :return-object="returnObject"
            :rules="rules"
            :small-chips="smallChips"
            readonly
          >
          </v-select>
        </div>
      </template>
      <v-card class="pt-2">
        <div style="border-bottom: 1px solid;">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ma-2"
            dense
            hide-details
            label="Search"
            outlined
            @blur="isSearch = false"
            @focus="isSearch = true"
            @input="searchData"
          ></v-text-field>
        </div>
        <v-list dense max-height="275" style="overflow-y: auto;" width="100%">
          <v-list-item-group :multiple="multiple">
            <v-layout v-for="(item, i) in availableData" :key="i" class="px-2" column>
              <v-list-item
                v-if="!item.items"
                class="py-1"
                @click="selectData(returnObject ? item : item[itemValue])"
                :class="{ 'selected-item': isSelected(item) }"
              >
                <v-list-item-content>
                  <v-layout class="item-layout">
                    <div>{{ item[itemText] }}</div>
                    <v-icon v-if="item.starred">mdi-star</v-icon>
                  </v-layout>
                </v-list-item-content>
              </v-list-item>
            </v-layout>
          </v-list-item-group>
        </v-list>
        <div class="d-flex justify-end mt-1" style="border-top: 1px solid;">
          <v-btn width="140" class="ma-2" color="primary" @click="$router.push('/projects')">
            <v-icon class="mr-1">mdi-ballot-outline</v-icon>
            Browse all
          </v-btn>
        </div>
      </v-card>
    </v-menu>
  </v-layout>
</template>
<script>
import sleep from '@/utils/sleep'

export default {
  props: {
    value: {},
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    color: { type: String, default: '' },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'name' },
    returnObject: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      if (val) this.$emit('change', this.value)
      if (!val && this.multiple) this.dataModel = []
      else this.setFirstData()
    },
    dataMenu(val) {
      this.isSearch = false
      if (val) {
        this.search = ''
        this.availableData = [...this.items]
      }
    },
  },
  computed: {
    selectedItems() {
      if (this.multiple) {
        if (this.returnObject) {
          if (!this.selectedData) return ''
          let tmpData = ''
          this.selectedData.forEach(val => (tmpData = tmpData + val[this.itemText] + ', '))
          return tmpData
        } else return this.selectedData.toString()
      } else {
        if (this.returnObject) return this.selectedData[this.itemText]
        return this.selectedData
      }
    },
    selectedData: {
      get() {
        return this.dataModel
      },
      set(val) {
        this.dataModel = val
        this.$emit('input', this.dataModel)
      },
    },
  },
  data(vm) {
    return {
      isFirst: true,
      isSearch: true,
      flatItems: [],
      availableData: [],
      search: '',
      dataMenu: false,
      dataModel: undefined,
    }
  },

  created() {
    this.setFirstData()
  },
  methods: {
    isSelected(item) {
      if (this.multiple) {
        return this.selectedData.some(selectedItem => selectedItem[this.itemValue] === item[this.itemValue])
      } else {
        return this.selectedData && this.selectedData[this.itemValue] === item[this.itemValue]
      }
    },
    async setFirstData() {
      if (this.isFirst) {
        this.flatItems = []
        this.items.forEach(val => {
          if (!val.items) this.flatItems.push(val)
          else this.flatItems = this.flatItems.concat(val.items)
        })
      }
      await sleep(100)
      this.dataModel = this.value
    },
    selectData(data) {
      if (this.multiple) {
        if (!this.selectedData) this.selectedData = []
        let ind = this.selectedData.findIndex(val => val === data)
        if (ind >= 0) this.selectedData.splice(ind, 1)
        else this.selectedData.push(data)
      } else {
        if (this.selectedData === data && this.clearable) this.selectedData = undefined
        else this.selectedData = data
      }
    },
    searchData() {
      if (!this.search) this.availableData = [...this.items]
      else {
        let availItems = []
        this.items.forEach(val => {
          if (
            !val.items &&
            val[this.itemText] &&
            val[this.itemText]
              .toString()
              .toUpperCase()
              .includes(this.search.toUpperCase())
          ) {
            availItems.push({ ...val })
          } else if (val.items) {
            let parent = { ...val }
            parent.items = val.items.filter(
              child =>
                child[this.itemText] &&
                child[this.itemText]
                  .toString()
                  .toUpperCase()
                  .includes(this.search.toUpperCase()),
            )
            if (parent.items.length) availItems.push({ ...parent })
          }
        })
        this.availableData = [...availItems]
      }
    },
  },
}
</script>

<style scoped>
.v-menu__content {
  margin-top: -25px;
}
.selected-item {
  background-color: #585a69;
}
.item-layout{
  display: flex ;
  justify-content: space-between;
  align-items: center;
}
</style>
