<template>
  <v-card :class="transparent ? 'custom-card-bg-0' : 'custom-card-bg-2'" height="100%" min-width="300" width="100%">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex flex-column fill-height pa-2">
      <div style="flex: none; width: 100%; height: 40px;">
        <v-layout align-center justify-center class="fill-height">
          <span style="font-weight: 500; font-size: 18px;">Layers control</span>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="primary"
                min-width="0"
                small
                width="38"
                v-bind="attrs"
                v-on="on"
                @click="savedLayers = layers"
              >
                <v-icon size="20">mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
            <span>Save map</span>
          </v-tooltip>
          <v-tooltip v-if="!isDrawing" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="primary"
                min-width="0"
                small
                :disabled="isDrawing"
                width="38"
                v-bind="attrs"
                v-on="on"
                @click="() => ($refs.createLayer.dialog = true)"
              >
                <v-icon size="20">icon-plus</v-icon>
              </v-btn>
            </template>
            <span>Add annotation</span>
          </v-tooltip>
          <v-tooltip v-if="removable " bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="error"
                min-width="0"
                small
                width="38"
                :disabled="layers.length === 0 || isDrawing"
                v-bind="attrs"
                v-on="on"
                @click="() => $refs.deleteDialog.openDialog()"
              >
                <v-icon size="20">icon-trash</v-icon>
              </v-btn>
            </template>
            <span>Remove all layers</span>
          </v-tooltip>
        </v-layout>
      </div>
      <v-layout class="fill-height pa-1 overflow-y-auto">
        <draggable
          v-model="layers"
          group="row"
          handle=".handle"
          style="width: 100%"
          v-bind="dragOptions"
          @end="moveLayer"
          @start="checkValid"
        >
          <transition-group :name="!drag ? 'flip-list' : null" type="transition">
            <v-layout
              v-for="(layer, i) of layers"
              :key="layer.id"
              class="elevation-4 my-2 custom-card-bg-1"
              style="min-height: 75px; width: 100%; border-radius: 4px"
            >
              <v-layout class="fill-height">
                <div
                  :style="{ 'background-color': getPreviewColor(layer) }"
                  class="handle"
                  style="width: 16px; min-height: 75px; flex: none; cursor: move; border-top-left-radius: 4px; border-bottom-left-radius: 4px"
                >
                  <v-layout align-center class="fill-height" justify-center>
                    <v-icon color="#e9e9e9" size="15">icon-line_space</v-icon>
                  </v-layout>
                </div>

                <v-layout class="fill-height pa-2" column justify-center>
                  <div class="d-flex" style="width: 100%; flex: 1; align-items: center">
                    <v-layout align-center class="fill-height">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="text-truncate"
                            style="width: fit-content; font-size: 70%"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ layer.name }}
                          </div>
                        </template>
                        <span>{{ layer.name }}</span>
                      </v-tooltip>
                    </v-layout>
                    <div style="height: 100%; width: fit-content; flex: none">
                      <v-layout align-center class="fill-height" justify-end>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="compare || isDraw || sync"
                              class="mr-1"
                              height="26"
                              icon
                              width="26"
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              @click="changeVisibleRightLayer(layer)"
                            >
                              <v-icon size="19">
                                {{ layer.layerRight ? 'icon-eye' : 'icon-eye_off' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ layer.layerRight ? 'Hide' : 'Visible' }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              height="26"
                              icon
                              width="26"
                              x-small
                              v-bind="attrs"
                              v-on="on"
                              @click="() => $emit('zoomTo', layer.bbox)"
                            >
                              <v-icon size="18">icon-gps</v-icon>
                            </v-btn>
                          </template>
                          <span>Zoom to</span>
                        </v-tooltip>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="removable" height="26" icon v-bind="attrs" width="26" x-small v-on="on">
                              <v-icon size="18">
                                mdi-dots-vertical
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list dense width="100">
                            <v-list-item
                              v-if="layer.isVector || layer.isGeojson"
                              dense
                              @click="() => $refs.vectorStyle.openDialog(layer)"
                            >
                              <v-list-item-title>Style</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="checkDownload(layer)" dense @click="download(layer)">
                              <v-list-item-title>Download</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="removable" dense @click="removeLayer(layer.id)">
                              <v-list-item-title>
                                Remove
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-layout>
                    </div>
                  </div>
                  <div class="d-flex align-center" style="width: 100%; flex: 1">
                    <v-icon color="primary" size="20">
                      {{ layer.type === 'raster' ? 'icon-image' : 'icon-layers' }}
                    </v-icon>
                    <v-slider
                      v-show="layer.paint[layer.typeStyle + '-opacity'] !== undefined"
                      v-model="layer.paint[layer.typeStyle + '-opacity']"
                      class="custom-slider mx-1"
                      color="primary"
                      hide-details
                      hide-spin-buttons
                      max="1"
                      min="0"
                      step="0.1"
                      track-color="#83D1FF"
                      @change="updateStyle(layer)"
                    ></v-slider>
                    <v-spacer />
                    <div style="height: 100%; flex: none; width: 100px">
                      <v-layout align-center class="fill-height">
                        <v-btn
                          v-show="compare || sync"
                          :style="{
                            'background-color': layer.layerLeft ? 'var(--v-info-base)' : '',
                          }"
                          style="border-radius: 2px"
                          text
                          width="50"
                          x-small
                          @click="changeVisibleLeftLayer(layer)"
                        >
                          L
                        </v-btn>
                        <v-btn
                          v-show="compare || sync"
                          :style="{
                            'background-color': layer.layerRight ? 'var(--v-info-base)' : '',
                          }"
                          class="ml-1"
                          style="border-radius: 2px"
                          text
                          width="50"
                          x-small
                          @click="changeVisibleRightLayer(layer)"
                        >
                          R
                        </v-btn>
                      </v-layout>
                    </div>
                  </div>
                  <!--                <Legend ref="legendControl" :currentLayer.sync="layer" v-if="layer.showLegend"/>-->
                </v-layout>
              </v-layout>
            </v-layout>
          </transition-group>
        </draggable>
      </v-layout>
      <div
        style="
        height: 48px;
        width: 100%;
        flex: none;
        border-top: 1px solid #5e5669;
        font-size: 18px;
      "
      >
        <v-layout align-center class="fill-height pa-2">
          <v-switch
            v-model="switchMapMode"
            color="#1966f7"
          >
            <template #label>
              <span style="font-size: 14px;">Compare map</span>
            </template>
          </v-switch>
          <v-spacer></v-spacer>
          <v-checkbox
            v-if="switchMapMode"
            v-model="checkboxMapMode"
            color="#1966f7"
            label="Sync"
          >
            <template #label>
              <span style="font-size: 14px;">Sync</span>
            </template>
          </v-checkbox>
        </v-layout>
      </div>
    </div>
    <CreateLayer
      ref="createLayer"
      @addToMap="(data)=> $emit('addToMap',data)"
      @createLayer="(name, type) => $emit('createLayer', name, type)"
      @setLayer="(name, geojson) => $emit('setLayer', name, geojson)"
    />
    <VectorStyle ref="vectorStyle" @updateStyle="updateStyle" />
    <DownloadVector ref="download" />
    <DeleteDialog
      ref="deleteDialog"
      :submit-delete="removeAllLayers"
      message="All the layers will be deleted. Are you sure you want to delete them?"
    />
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import sleep from '@/utils/sleep'
import CreateLayer from '@/components/layer-control/create-layer/CreateLayer.vue'
import VectorStyle from '@/components/layer-control/vector-style/VectorStyle.vue'
import { mapState } from '@/store/ults'
import DownloadVector from '@/components/DownloadVector.vue'
import { debounce } from 'lodash'
import DeleteDialog from '@/components/DeleteDialog.vue'

export default {
  name: 'LayerControl',
  components: { DownloadVector, VectorStyle, CreateLayer, draggable, DeleteDialog },
  data() {
    return {
      switchMapMode: false,
      checkboxMapMode: false,
      mapMode: 'overlay',
      loading: false,
      compare: false,
      sync: false,
      isDraw: false,
      drag: false,
      currentDrag: undefined,
    }
  },
  props: {
    isDrawing: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    mapControl: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false },
    removable: { type: Boolean, default: true },
    currentLayers: { type: Array, default: () => [] },
    withoutLayers: { type: Array, default: () => [] },
  },
  watch: {
    switchMapMode: debounce(function (val) {
      this.mapMode = val ? this.checkboxMapMode ? 'sync' : 'slide' : 'overlay'
    }, 300),
    checkboxMapMode: debounce(function (val) {
      this.mapMode = val ? 'sync' : 'slide'
    }, 300),
    mapMode(val) {
      this.changeMapDisplay()
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('layers', ['savedLayers']),
    layers: {
      get() {
        return this.currentLayers.filter(layer => !this.withoutLayers.includes(layer.id))
      },
      set(val) {
        this.$emit('update:currentLayers', val)
      },
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    removeAllLayers() {
      this.$emit('removeAllLayer')
    },
    updateStyle(layer) {
      this.$emit('updateStyleProperties', layer.id)
    },
    checkDownload(layer) {
      return layer.isGeojson || (!!layer.data.data && !!layer.data.data.download_url)
    },
    download(layer) {
      this.$refs.download.openDialog(layer)
    },
    async changeMapDisplay(mapMode = this.mapMode) {
      await sleep(200)
      this.mapMode = mapMode
      switch (mapMode) {
        case 'overlay':
          this.sync = false
          this.compare = false
          this.$emit('changeToSyncMap', false)
          this.$emit('changeCompare', false)
          break
        case 'slide':
          this.sync = false
          this.compare = true
          this.$emit('changeToSyncMap', false)
          this.$emit('changeCompare', true)
          break
        case 'sync':
          this.sync = true
          this.compare = false
          this.$emit('changeToSyncMap', true)
          this.$emit('changeCompare', false)
          break
      }
    },
    getPreviewColor(layer) {
      if (layer.typeStyle && layer.paint[layer.typeStyle + '-color']) {
        if (typeof layer.paint[layer.typeStyle + '-color'] === 'string') {
          return layer.paint[layer.typeStyle + '-color']
        } else {
          return 'var(--v-accent-base)'
        }
      } else return 'var(--v-accent-base)'
    },
    checkValid(e) {
      this.drag = true
      this.currentDrag = this.layers[e.oldIndex]
    },
    changeVisibleRightLayer(layer) {
      layer.layerRight = !layer.layerRight
      this.$emit('changeVisible', layer)
    },
    changeVisibleLeftLayer(layer) {
      layer.layerLeft = !layer.layerLeft
      this.$emit('changeVisibleLeftLayer', layer, this.sync)
    },
    moveLayer(e) {
      this.currentDrag = undefined
      this.$emit('reloadMap')
      this.drag = false
    },
    removeLayer(id) {
      this.$emit('removeLayer', id)
      let index = this.layers.findIndex(layer => layer.id === id)
      if (index >= 0) this.layers.splice(index, 1)
    },
  }
}
</script>

<style scoped></style>
