var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","min-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"color-icon",staticStyle:{"border-radius":"6px"},style:({
        'min-width': (_vm.size + "px"),
        width: (_vm.width + "px"),
        height: (_vm.height + "px"),
        backgroundColor: _vm.iconColor,
      }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return on.click.apply(null, arguments)}}})]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticClass:"pt-4"},[_c('v-card-text',[_c('v-color-picker',{attrs:{"hide-inputs":"","hide-mode-switch":"","mode":"hex"},model:{value:(_vm.iconColor),callback:function ($$v) {_vm.iconColor=$$v},expression:"iconColor"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(_vm.removable)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" REMOVE ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }