<template>
  <v-app
    style="background-color: var(--v-background-base)"
    v-bind:style="{
      'background-image': `url(${require('@/assets/images/project/bg.svg')})`,
      'background-repeat': 'no-repeat',
      'background-size': 'auto 100%',
    }"
  >
    <Message />
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar absolute app flat style="z-index: 110">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mr-3">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <div style="width: 280px">
            <CustomSelect
              v-model="currentProject"
              :items="projects"
              class="app-bar-search flex-grow-0 custom-solo"
              dense
              item-text="name"
              label="Project"
              placeholder="Project"
              return-object
              outlined
              rounded
              solo
              @change="changeProject"
            >
            </CustomSelect>
          </div>

          <div class="d-flex ml-4">
            <div class="group-path" v-for="(item, index) in currentMenuLink" :key="index">
              <span class="clickable" @click="goTo(item)">{{ item.name }}</span>
              <v-icon v-if="index < currentMenuLink.length - 1">{{
                item.type !== 'menu' ? 'mdi-chevron-right' : ''
              }}</v-icon>
            </div>
          </div>
          <v-spacer></v-spacer>

          <Chatgpt />
          <Notification />

          <!-- <v-menu v-model="settingMenu" :close-on-content-click="false" bottom offset-y>
            <template #activator="menu">
              <v-tooltip bottom color="#000000c4">
                <template #activator="tooltip">
                  <v-btn icon v-on="{ ...menu.on, ...tooltip.on }">
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>

                <span>Settings</span>
              </v-tooltip>
            </template>
            <v-card v-if="settingMenu" class="custom-card-bg-gradient" width="400">
              <v-card-text>
                <v-layout align-center class="pb-2">
                  <h3 class="mr-2">
                    Settings
                  </h3>
                  <v-divider />
                </v-layout>
                <v-text-field
                  v-show="showPlanetKey"
                  v-model="planetKey"
                  dense
                  hide-details
                  label="Planet key"
                  outlined
                  placeholder="Planet key"
                  readonly
                >
                </v-text-field>
                <v-select
                  v-model="unit"
                  :items="units"
                  class="mt-3"
                  dense
                  hide-details
                  item-text="name"
                  item-value="value"
                  label="Unit area"
                  outlined
                  placeholder="Unit area"
                ></v-select>
              </v-card-text>
            </v-card>
          </v-menu> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main
      class="pb-4"
      style="
      height: calc(100vh - 65px); overflow-y: auto; overflow-x: hidden; background-repeat: no-repeat;
    background-size: auto 100%;"
    >
      <div class="app-content-container boxed-container pa-0 fill-height">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiBellOutline, mdiGithub, mdiMagnify } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Message from '@/components/Message.vue'
import { mapState } from '@/store/ults'
import { getServices } from '@/api/services'
import { getListProject } from '@/api/project-api'
import ConvertDate from '@/utils/convertDate'
import sleep from '@/utils/sleep'
import planetApi from '@/utils/planet/planetApi'
import { getProjection, getUserSetting } from '@/api/auth-api'
import PlanetTaskingApi from '@/utils/planet/planetTaskingApi'
import BlackskyTasking from '@/utils/blacksky/blackskyTasking'
import { getMenus, getSettingMenus } from '@/api/menu-api'
import Notification from '@/components/notification/Notification.vue'
import CustomSelect from '@/components/Select2/CustomSelect.vue'
import Chatgpt from '@/views/chat-guide/Chatgpt.vue'

export default {
  components: {
    Message,
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Notification,
    CustomSelect,
    Chatgpt,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
  data() {
    return {
      showPlanetKey: false,
      settingMenu: false,
      currentProject: undefined,
      currentMenuLink: [],
      aoisChannel: undefined,
      units: [
        {
          name: 'Square kilometer',
          value: 'km',
        },
        {
          name: 'Square meter',
          value: 'm',
        },
        {
          name: 'Hectare',
          value: 'ha',
        },
      ],
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.query.notification && val.params.id != oldVal.params.id) location.reload()
    },
    settingMenu(val) {
      this.getSetting()
    },
    menu() {
      this.currentMenuLink = this.findGroupItem(this.menu, this.group)
    },
    group(val) {
      this.currentMenuLink = this.findGroupItem(this.menu, this.group)
    },
  },
  computed: {
    group() {
      return this.$route.query.group
    },
    ...mapState('menu', ['menu']),
    ...mapState('auth', ['currentUser']),
    ...mapState('layers', ['savedLayers']),
    ...mapState('setting', ['unit', 'keys', 'planetKey']),
    ...mapState('project', ['projects', 'projectArea']),
    ...mapState('service', ['services', 'firstLoad', 'projections']),
    ...mapState('socket', ['echoChannel']),
    ...mapState('AOI', ['queryData']),
  },
  mounted() {
    this.initListen()
    this.getMenus()
    this.getSetting()
    this.initProject()
    this.getServices()
    this.getProjection()
  },
  methods: {
    initListen() {
      if (this.aoisChannel) {
        this.echoChannel.private(this.aoisChannel).unsubscribe()
      }
      this.aoisChannel = `project.${this.$route.params.id}`
      this.echoChannel.private(`project.${this.$route.params.id}`).listen('.aoi.changed', e => {
        this.$store.dispatch('AOI/getAllGroup', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta?.service },
        })
        this.$store.dispatch('AOI/getListAOI', {
          isLazy: true,
          projectId: this.$route.params.id,
          payload: { ...this.queryData, group: '' },
        })
        this.$store.dispatch('AOI/getListAOI', {
          isLazy: false,
          projectId: this.$route.params.id,
          payload: { ...this.queryData, group: '' },
        })
      })
    },
    getDefaultLink() {
      if (this.$route.name === 'group-view') return
      const path = this.$route.path
      const newQuery = this.getDefaultMenuLink(this.menu, this.$route.name)
      if (this.$route.query.group === newQuery) {
        return
      }
      const query = { ...this.$route.query, group: newQuery }
      this.$router.push({ path, query }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
        console.warn(err)
      })
    },
    getDefaultMenuLink(menus, menuCode, parentCodes = '') {
      for (const menu of menus) {
        const currentPath = `${parentCodes}/${menu.code}`
        if (menu.code === menuCode) {
          return currentPath
        }
        if (menu.children && menu.children.length) {
          const foundLink = this.getDefaultMenuLink(menu.children, menuCode, currentPath)
          if (foundLink) {
            if (foundLink) {
              return foundLink.startsWith('/') ? foundLink.slice(1) : foundLink
            }
          }
        }
      }
      return null
    },
    getMenus() {
      getSettingMenus().then(res => {
        this.$store.commit('menu/SET_MENU', res.data)
      })
    },

    goTo(item) {
      const groupParts = this.group.split('/')
      const itemCode = item.code
      if (itemCode === groupParts[groupParts.length - 1]) return

      const itemIndex = groupParts.indexOf(itemCode)
      if (itemIndex === -1) return

      const link = groupParts.slice(0, itemIndex + 1).join('/')
      this.$router.push({ name: 'group-view', query: { group: link } })
    },
    findGroupItem(menu, groupPath) {
      let groupNames = []
      if (!groupPath) this.getDefaultLink()
      else {
        let groupIds = groupPath.split('/')
        if (groupIds[0] === 'home') groupIds.shift()

        for (let groupId of groupIds) {
          let foundItem = menu.find(item => item.code === groupId)
          if (foundItem) {
            groupNames.push(foundItem)
            menu = foundItem.children || []
          } else {
            this.getDefaultLink()
          }
        }
      }
      return groupNames
    },
    async getProjection() {
      try {
        this.loading = true
        const res = await getProjection()
        this.projections = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getSetting() {
      try {
        this.loading = true
        const res = await getUserSetting({
          payload: {
            properties: ['planet_key', 'planet_tasking_key', 'blacksky_tasking_key'],
          },
        })
        if (res.data.planet_key) {
          this.showPlanetKey = true
          this.planetKey = res.data.planet_key
        }
        planetApi.setApikey(this.planetKey)
        PlanetTaskingApi.setApikey(res.data.planet_tasking_key)
        BlackskyTasking.setApikey(res.data.blacksky_tasking_key)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getServices() {
      try {
        this.loading = true
        const res = await getServices()
        this.services = res.data
        this.firstLoad = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async initProject() {
      try {
        this.loading = true
        const res = await getListProject({ per_page: 'all', search: this.keyword })
        this.projects = res.data
        this.projects.forEach(project => {
          project.created_at = ConvertDate.dateFormatted(project.created_at)
        })
        this.currentProject = this.projects.find(project => project.uuid === this.$route.params.id)
        if (!this.currentProject) this.$router.push('/projects')
        else {
          this.projectArea = this.currentProject.geometry
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async changeProject(project) {
      if (
        this.$route.path === `/projects/${project.uuid}/${this.$route.name}` ||
        this.$route.path == `/projects/${project.uuid}/group-view/`
      )
        return
      this.savedLayers = []
      let historyName = this.$route.name
      await this.$router.push(`/projects/${project.uuid}/${this.$route.name}?group=${this.group}`)
      await sleep(0)
      this.$nextTick(() => {
        if (this.$route.name === historyName) location.reload()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}

.group-path {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
