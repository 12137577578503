<template>
  <a :href="url" class="custom-link" :target="target">{{text}}</a>
</template>

<script>
export default {
  name: 'CustomLink',
  props: {
    text: {
      type: String,
      default: 'Link'
    },
    url: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_blank'
    }
  },
}
</script>
<style scoped>

</style>
