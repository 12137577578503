<template>
  <v-layout class="fill-height" column style="width: 100%; border-radius: inherit">
    <v-tabs v-model="tab" fixed-tabs height="48" style="max-height: 48px; border-radius: inherit">
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab class="ml-0 custom-tab-name custom-card-bg-2"> Layers </v-tab>
      <v-tab class="ml-0 custom-tab-name custom-card-bg-2"> Data </v-tab>
    </v-tabs>
    <v-divider />
    <v-tabs-items
      v-model="tab"
      class="custom-menu-tab mt-0"
      style="height: calc(100% - 57px); background-color: transparent"
    >
      <v-tab-item style="height: 100%">
        <v-card class="custom-card-bg-0" flat height="100%">
          <LayerControl
            ref="layerControl"
            :comparable="comparable"
            :current-layers.sync="layers"
            :isDrawing.sync="isDraw"
            :map-control="mapControl"
            :removable="removable"
            :transparent="transparent"
            @cancel="() => $emit('cancel')"
            @changeCompare="show => $emit('changeCompare', show)"
            @changeToSyncMap="status => $emit('changeToSyncMap', status)"
            @changeVisible="layer => $emit('changeVisible', layer)"
            @changeVisibleLeftLayer="(layer, isSync) => $emit('changeVisibleLeftLayer', layer, isSync)"
            @createLayer="(name, type) => $emit('createLayer', name, type)"
            @reloadMap="() => $emit('reloadMap')"
            @removeAllLayer="() => $emit('removeAllLayer')"
            @removeLayer="layerId => $emit('removeLayer', layerId)"
            @saveLayer="() => $emit('saveLayer')"
            @setLayer="(name, geojson) => $emit('setLayer', name, geojson)"
            @updateStyleProperties="() => layerId => $emit('updateStyleProperties', layerId)"
            @zoomTo="bbox => $emit('zoomTo', bbox)"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item style="height: 100%; overflow-y: hidden">
        <v-card :loading="loading" class="custom-card-bg-2 overflow-y-hidden" flat height="100%">
          <ListData :data.sync="allData" @addToMap="data => $emit('addToMap', data)" @onFilter="getDataExplorer" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-layout>
</template>
<script>
import LayerControl from '@/components/layer-control/LayerControl.vue'
import Daily from '@/views/map-overview/Daily.vue'
import ListData from '@/components/data-control/ListData.vue'
import { getDataExplorer } from '@/api/explorer-api'
// import DataExplorerModel from '@/models/data/data-explorer'
import config from '@/config'

export default {
  components: { ListData, Daily, LayerControl },
  data() {
    return {
      search: undefined,
      loading: false,
      tab: 0,
      allData: [],
    }
  },
  props: {
    isDraw: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    mapControl: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false },
    removable: { type: Boolean, default: true },
    currentLayers: { type: Array, default: () => [] },
    showLayerControl: { type: Boolean, default: true },
  },
  computed: {
    layers: {
      get() {
        return this.currentLayers
      },
      set(val) {
        this.$emit('update:currentLayers', val)
      },
    },
  },
  mounted() {
    if (this.showLayerControl) this.getDataExplorer()
  },
  methods: {
    async getDataExplorer(payload = {}) {
      try {
        this.loading = true
        payload.includes = ['type']
        const res = await getDataExplorer({
          payload: payload,
          projectId: this.$route.params.id,
        })
        // this.allData = res.data.map(item => new DataExplorerModel(item))
        this.allData = res.data.map(item => {
          if (item.tile_info.type == 'aoi')
            item.tile_info.thumbnail_url = `${config.backend_pub}${item.tile_info.thumbnail_url}`
          return item
        })
      } catch (e) {
        console.log('Error when get data explorer: ', e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
