<template>
  <div>
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-text-field
        dense
        label="Layer Name"
        :rules="[rules.required('Layer name')]"
        v-model="layer.name"
        outlined
        placeholder="Layer Name"
      ></v-text-field>
      <v-text-field
        dense
        label="Tile URL"
        :rules="[rules.required('Tile URL'), rules.tileUrl]"
        v-model="layer.tile_info.tile_url"
        outlined
        prepend-inner-icon="mdi-link-variant"
        placeholder="Tile URL"
      ></v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            dense
            label="Min Longitude"
            :rules="[rules.required('Min Longitude')]"
            v-model.number="layer.tile_info.bbox[0]"
            outlined
            placeholder="Min Longitude"
          ></v-text-field>
          <v-text-field
            dense
            label="Max Longitude"
            :rules="[rules.required('Max Longitude')]"
            v-model.number="layer.tile_info.bbox[2]"
            outlined
            placeholder="Max Longitude"
          ></v-text-field>
          <v-text-field
            dense
            type="number"
            :rules="[rules.required('Min zoom')]"
            v-model.number="layer.MIN_ZOOM"
            label="Min zoom"
            outlined
            placeholder="Min zoom"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            label="Min Latitude"
            :rules="[rules.required('Min Latitude')]"
            v
            v-model.number="layer.tile_info.bbox[1]"
            outlined
            placeholder="Min Latitude"
          ></v-text-field>
          <v-text-field
            dense
            label="Max Latitude"
            :rules="[rules.required('Max Latitude')]"
            v-model.number="layer.tile_info.bbox[3]"
            outlined
            placeholder="Max Latitude"
          ></v-text-field>
          <v-text-field
            dense
            type="number"
            :rules="[rules.required('Max zoom')]"
            v-model.number="layer.MAX_ZOOM"
            label="Max zoom"
            outlined
            placeholder="Max zoom"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      valid: false,
      rules: {
        required: fieldName => value => {
          return (value !== null && value !== undefined && value !== '') || `${fieldName} is required`
        },
        tileUrl: v => {
          const pattern = /^(https?:\/\/[^\s\/]+)(\/[^\s\/]+)*\/\{z\}\/\{x\}\/\{y\}(\/?.*)?$/
          return pattern.test(v) || 'Invalid tile URL format'
        },
      },
    }
  },
  props: {
    layer: {
      type: Object,
      default: {
        name: undefined,
        tile_info: {
          tile_url: undefined,
          type: 'images',
          bbox: [-180, -85.051129, 180, 85.051129],
        },
        MIN_ZOOM: 0,
        MAX_ZOOM: 22,
      },
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped></style>
