import axios from 'axios'
import Store from '@/store'
import configEnv from '@/config'

// axios.defaults.withCredentials = true;
// axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
// axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

export default {
  getToken() {
    return localStorage.getItem('jwt_token')
  },

  setToken(token) {
    localStorage.setItem('jwt_token', token)
  },

  deleteToken() {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('cacheRoute')
  },

  processHeaders(header) {
    let token = this.getToken()
    if (token) {
      return header === true ? { Authorization: `${token}` } : {}
    } else {
      return {}
    }
  },

  getConfigMethodGet(currentConfig) {
    let defaultConfig = {
      configHeader: {},
      notification: false,
      auth: true,
      callback: undefined,
    }
    if (currentConfig)
      for (const key in currentConfig) {
        defaultConfig[key] = currentConfig[key]
      }
    return defaultConfig
  },

  getConfig(currentConfig) {
    let defaultConfig = {
      configHeader: {},
      notification: true,
      auth: true,
      callback: undefined,
      preventNotification: false
    }
    if (currentConfig)
      for (const key in currentConfig) {
        defaultConfig[key] = currentConfig[key]
      }
    return defaultConfig
  },

  get(url, params = {}, config = {}) {
    config = this.getConfigMethodGet(config)
    return this.submit('get', url, params, config)
  },

  post(url, data = {}, config = {}) {
    config = this.getConfig(config)
    return this.submit('post', url, data, config)
  },

  put(url, data = {}, config = {}) {
    config = this.getConfig(config)
    return this.submit('put', url, data, config)
  },

  patch(url, data = {}, config = {}) {
    config = this.getConfig(config)
    return this.submit('patch', url, data, config)
  },

  delete(url, data = {}, config = {}) {
    config = this.getConfig(config)
    return this.submit('delete', url, data, config)
  },

  submitOnce(method, url, params = {}, config = this.getConfig()) {
    let header = this.processHeaders(config.auth)
    if (params instanceof FormData) {
      header['Content-Type'] = 'multipart/form-data'
    }
    let obj = {
      timeout: config.timeout ? config.timeout : 60000,
      method: method,
      url: url,
      headers: header,
      onUploadProgress: progressEvent => {
        if (config.callback) {
          let loaded = Math.ceil((progressEvent.loaded * 100) / progressEvent.total)
          config.callback(loaded)
        }
      },
    }
    obj = { ...obj, ...config.configHeader }
    if (String(method).toLowerCase() === 'post' && Object.keys(config.configHeader).length !== 0) {
      obj.headers = config.configHeader
    }
    if (String(method).toLowerCase() === 'get') {
      obj.params = params
    } else {
      obj.data = params
    }
    return axios(obj)
  },

  trimEnv(url) {
    let trimUrl = url
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1){
      const prefix_url = configEnv.backend.lastIndexOf('/') === (configEnv.backend.length - 1) ? configEnv.backend : (configEnv.backend + '/')
      url = url.indexOf('/') === 0 ? url.replace('/', '') : url
      trimUrl = prefix_url + url
    }

    return trimUrl
  },

  submit(method, url, params = {}, config = this.getConfig()) {
    if (config.notification) Store.commit('s', ['progress', true])
    url = this.trimEnv(url)
    return new Promise((resolve, reject) => {
      this.submitOnce(method, url, params, config)
        .then(response => {
          if (!config.preventNotification) this._handleSuccess(response, config.notification)
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('cacheRoute')
            this.deleteToken()
            if (!location.pathname.includes('/pages/login')) {
              location.href = `${location.origin}/pages/login`
            }
          }
          if (this._shouldRefreshToken(error)) {
            this.submitOnce('get', '/api/auth/refresh', {}, true)
              .then(response => {
                this.setToken('Bearer ' + response.data.access_token)
                this.submitOnce(method, url, params, config)
                  .then(response => {
                    this._handleSuccess(response, config.notification)
                    resolve(response)
                  })
                  .catch(error => {
                    if (config.notification) this._handleError(error, config.notification)
                    reject(error)
                  })
              })
              .catch(error => {
                if (config.notification) this._handleError(error, config.notification)
                reject(error)
              })
          } else {
            if (!error.response)
              return reject({
                message: 'Something Went Wrong, Please try again',
              })
            let errors = error.response.data.errors
            let message = ''
            for (let key in errors) {
              let error = errors[key]
              error.forEach(e => {
                message += e + ' '
              })
            }
            this._handleError(error, config.notification)
            reject({
              message: message,
              code: error.response.status,
            })
          }
        })
    })
  },

  _shouldRefreshToken(error) {
    return error.response && error.response.status === 401 && error.response.data.code === 'token_expire'
  },

  _handleError(error, notify) {
    if (notify) Store.commit('message/SHOW_ERROR', notify)
    Store.commit('s', ['progress', false])
    if (!error.response || !error.response.status) {
      Store.commit('message/SHOW_ERROR', 'Something Went Wrong, Please try again')
    } else {
      let code = error.response.status
      if (code === 401 && error.response.data.message) {
        notify ? Store.commit('message/SHOW_ERROR', error.response.data.message) : ''
      } else if (code === 403 && error.response.data.code === 'validation') {
        let data = error.response.data.data
        let key = Object.keys(data)[0]
        Store.commit('message/SHOW_ERROR', data[key][0])
      } else if (code === 422 && error.response.data.errors) {
        let errors = error.response.data.errors
        let message = ''
        for (let key in errors) {
          let error = errors[key]
          error.forEach(e => {
            message += e + ' '
          })
        }
        Store.commit('message/SHOW_ERROR', message)
      } else if (code === 401) {
        let errors = error.response.data.errors
        let message = ''
        for (let key in errors) {
          let error = errors[key]
          error.forEach(e => {
            message += e + ' '
          })
        }
        Store.commit('message/SHOW_ERROR', message)
      } else {
        Store.commit(
          'message/SHOW_ERROR',
          error.response.data.message ? error.response.data.message : 'Something Went Wrong, Please try again',
        )
      }
    }
  },

  _handleSuccess(success, notify) {
    // Store.commit('s', ['progress', false])
    if (notify) {
      Store.commit('message/SHOW_SUCCESS', success.data.message || 'Success')
    }
  },
}
