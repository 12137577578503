const FILTER_NOTIFICATIONS = [
  {
    name: 'Reference type',
    value: 'reference_type',
    items: [
      { name: 'Forest Fire', value: 'forest_fire' },
      { name: 'Earthquake', value: 'earthquake' },
      { name: 'Order', value: 'order' },
      { name: 'Tasking Order', value: 'tasking_oder' },
      { name: 'Result', value: 'result' },
    ]
  },
  {
    name: 'Status',
    value: 'status',
    items: [
      { name: 'New', value: 'new' },
      { name: 'Processing', value: 'processing' },
      { name: 'Completed', value: 'completed' },
    ]
  }
]

export default FILTER_NOTIFICATIONS
