export default {
  namespaced: true,
  state: {
    currentUser: undefined,
    currentTab: 0
  },
  getters: {
    currentUser(state) {
      return state.currentUser
    },
    currentTab(state) {
      return state.currentTab
    },
  },
  mutations: {
    SET_USER(state, newValue) {
      state.currentUser = newValue
    },
    SET_CURRENT_TAB(state, newValue) {
      state.currentTab = newValue
    },
  },
}
