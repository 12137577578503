<template>
  <div>
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-text-field
        dense
        label="Tile URL"
        :rules="[rules.required('Tile URL')]"
        v-model="layer.tile_info.tile_url"
        outlined
        placeholder="Tile URL"
        @blur="getListLayers()"
        prepend-inner-icon="mdi-link-variant"
      ></v-text-field>
      <v-select
        v-model="layer.layer"
        :items="layers"
        dense
        hide-details
        label="WMS layer"
        outlined
        :rules="[rules.required('layer')]"
        placeholder="Select a WMS layer"
        clearable
        item-text="name"
        return-object
      ></v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      valid: false,
      rules: {
        required: fieldName => value => !!value || `${fieldName} is required`,
      },
      layers: [],
    }
  },
  props: {
    layer: {
      type: Object,
      default: {
        name: '',
        tile_info: {
          tile_url: undefined,
          type: 'wms',
        },
        MIN_ZOOM: 0,
        // layer: '',
        MAX_ZOOM: 22,
      },
    },
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    async getListLayers() {
      const wmsUrl = `${this.layer.tile_info.tile_url}?service=WMS&request=GetCapabilities&version=1.3.0`
      try {
        const response = await fetch(wmsUrl)
        const text = await response.text()
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(text, 'application/xml')
        const layers = xmlDoc.getElementsByTagName('Layer')
        const layerList = []
        for (let i = 0; i < layers.length; i++) {
          const layer = layers[i]
          const name = layer.getElementsByTagName('Name')[0]?.textContent
          const title = layer.getElementsByTagName('Title')[0]?.textContent
          const boundingBoxes = layer.getElementsByTagName('BoundingBox')
          let bbox4326 = null
          for (let bbox of boundingBoxes) {
            const crs = bbox.getAttribute('CRS')
            if (crs == 'CRS:84') {
              bbox4326 = [
                bbox.getAttribute('minx'),
                bbox.getAttribute('miny'),
                bbox.getAttribute('maxx'),
                bbox.getAttribute('maxy'),
              ]
              break
            }
          }
          if (name && title) {
            layerList.push({ name, title, bbox4326 })
          }
        }
        this.layers = layerList
        if (this.layers) this.layer.layer = this.layers[0]
      } catch (error) {
        this.$store.commit('message/SHOW_ERROR', 'error fetching WMS layers ' + error)
      }
    },
  },
}
</script>

<style scoped></style>
