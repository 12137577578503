<template>
  <v-layout style="position: relative">
    <v-menu
      ref="menu"
      v-model="dataMenu"
      :close-on-content-click="!multiple && !isSearch"
      class="select-menu"
      :offset-y="true"
      bottom
      style="width: 100%;"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="dataMenu = true" style="width: 100%;">
          <v-select
            v-model="selectedData"
            :chips="chips"
            :clearable="clearable"
            :dense="dense"
            :disabled="disabled"
            :hide-details="$attrs['hide-details']"
            :item-text="itemText"
            :item-value="itemValue"
            :items="flatItems"
            :label="label"
            :multiple="multiple"
            :outlined="outlined"
            :placeholder="$attrs['placeholder']"
            :prepend-inner-icon="$attrs['prepend-inner-icon']"
            :return-object="returnObject"
            :rules="rules"
            :small-chips="smallChips"
            readonly
          >
          </v-select>
        </div>
      </template>
      <v-card class="pt-2 "  style="z-index:9998">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ma-2"
          dense
          hide-details
          label="Search"
          outlined
          @blur="isSearch = false"
          @focus="isSearch = true"
          @input="searchData"
        ></v-text-field>
        <v-list v-if="items.length > 0" dense max-height="275" style="overflow-y: auto;" width="100%">
          <v-list-item-group :mandatory="!multiple && selectedData && !clearable" :multiple="multiple">
            <v-layout v-for="(item, i) in availableData" :key="i" class="px-2" column>
              <v-list-item v-if="!item.items" class="py-1" @click="selectData(returnObject ? item : item[itemValue])">
                <v-list-item-content>
                  <v-list-item-title v-text="item[itemText]"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-layout
                v-if="item.items"
                column
                style="border-bottom: 1px solid rgba(103,103,103,0.6); border-top: 1px solid rgba(103,103,103,0.6)"
              >
                <v-list-item-subtitle class="px-2 py-1">
                  <v-layout align-center>
                    <v-icon class="mr-2">mdi-folder-outline</v-icon>
                    <b style="font-size: 15px"> {{ item[itemText] }}</b>
                  </v-layout>
                </v-list-item-subtitle>
                <v-list-item
                  v-for="(child, i) in item.items"
                  :key="'child' + i"
                  class="px-7 py-1"
                  @click="selectData(returnObject ? child : child[itemValue])"
                >
                  <v-list-item-content v-if="!child.items">
                    <v-list-item-title v-text="child[itemText]"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-layout>
            </v-layout>
          </v-list-item-group>
        </v-list>
        <div v-else style="text-align:center;padding-bottom:4px;">No data available.</div>
      </v-card>
    </v-menu>
  </v-layout>
</template>
<script>
import sleep from '@/utils/sleep'

export default {
  props: {
    value: {},
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    color: { type: String, default: '' },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    chips: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'name' },
    returnObject: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      if (!val && this.multiple) this.dataModel = []
      else this.setFirstData()
      this.$emit('change', this.value)
    },
    dataMenu(val) {
      this.isSearch = false
      if (val) {
        this.search = ''
        this.availableData = [...this.items]
      }
    },
  },
  computed: {
    selectedItems() {
      if (this.multiple) {
        if (this.returnObject) {
          if (!this.selectedData) return ''
          let tmpData = ''
          this.selectedData.forEach(val => (tmpData = tmpData + val[this.itemText] + ', '))
          return tmpData
        } else return this.selectedData.toString()
      } else {
        if (this.returnObject) return this.selectedData[this.itemText]
        return this.selectedData
      }
    },
    selectedData: {
      get() {
        return this.dataModel
      },
      set(val) {
        this.dataModel = val
        this.$emit('input', this.dataModel)
      },
    },
  },
  data(vm) {
    return {
      isFirst: true,
      isSearch: true,
      flatItems: [],
      availableData: [],
      search: '',
      dataMenu: false,
      dataModel: undefined,
    }
  },

  created() {
    this.setFirstData()
  },
  methods: {
    async setFirstData() {
      if (this.isFirst) {
        this.flatItems = []
        this.items.forEach(val => {
          if (!val.items) this.flatItems.push(val)
          else this.flatItems = this.flatItems.concat(val.items)
        })
      }
      await sleep(100)
      this.dataModel = this.value
    },
    selectData(data) {
      if (this.multiple) {
        if (!this.selectedData) this.selectedData = []
        let ind = this.selectedData.findIndex(val => val === data)
        if (ind >= 0) this.selectedData.splice(ind, 1)
        else this.selectedData.push(data)
      } else {
        if (this.selectedData === data && this.clearable) this.selectedData = undefined
        else this.selectedData = data
      }
    },
    searchData() {
      if (!this.search) this.availableData = [...this.items]
      else {
        let availItems = []
        this.items.forEach(val => {
          if (
            !val.items &&
            val[this.itemText] &&
            val[this.itemText]
              .toString()
              .toUpperCase()
              .includes(this.search.toUpperCase())
          ) {
            availItems.push({ ...val })
          } else if (val.items) {
            let parent = { ...val }
            parent.items = val.items.filter(
              child =>
                child[this.itemText] &&
                child[this.itemText]
                  .toString()
                  .toUpperCase()
                  .includes(this.search.toUpperCase()),
            )
            if (parent.items.length) availItems.push({ ...parent })
          }
        })
        this.availableData = [...availItems]
      }
    },
  },
}
</script>

<style scoped>
.v-menu__content {
  margin-top: -25px;
  z-index:10000!important;
}
</style>
