<template>
  <div>
    <v-navigation-drawer
      :mini-variant="miniDrawer"
      :right="$vuetify.rtl"
      :value="isDrawerOpen"
      app
      style="overflow: visible;"
      class="app-navigation-menu"
      floating
      mini-variant-width="85"
      width="280"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <v-layout class="fill-height pr-4 pb-4" column style="position: relative;overflow: visible;">
        <!-- Navigation Header -->
        <div class="vertical-nav-header items-center mb-1" style="flex: none; height: 56px">
          <v-layout align-center class="fill-height" justify-center>
            <router-link class="d-flex align-center text-decoration-none" to="/projects">
              <img
                :src="require('@/assets/images/logos/logo.png')"
                alt="logo"
                class="app-logo"
                contain
                eager
                max-height="30px"
                max-width="30px"
                width="60"
              />
              <v-slide-x-transition v-if="!mini">
                <div class="text--primary" style="font-size: 12.5px">
                  Sustainability Monitoring Platform
                  <div style="font-size: 10px">
                    MEASURE, REPORT & VERIFY
                  </div>
                  <div style="font-size: 9px">
                    (Everything that matters to our planet)
                  </div>
                </div>
              </v-slide-x-transition>
            </router-link>
          </v-layout>
        </div>

        <!-- Navigation Items -->
        <v-list
          v-if="currentUser.menu_grid"
          class="vertical-nav-menu-items mt-1 overflow-y-auto custom-card-bg-1"
          dense
          expand
          height="100%"
          shaped
          style="border-top-right-radius: 16px;"
        >
          <div v-for="(item, index) in menu" :key="index">
            <div
              v-if="checkDisplay(item)"
              :key="index"
              :icon="item.icon"
              class="custom-menu"
              :style="{ 'background-color': isSelect(item) ? 'rgba(255,255,255,0.1)' : '' }"
              @click="goToMenu(item)"
              style="font-size: 14px!important;position: relative;"
            >
              <v-icon class="mr-5">{{ item.icon }}</v-icon>
              <span v-if="!mini" style="font-weight: bold;">{{ item.name }}</span>
            </div>
          </div>
        </v-list>
        <v-list
          v-else
          class="vertical-nav-menu-items mt-1 overflow-y-auto custom-card-bg-1"
          dense
          expand
          height="100%"
          shaped
          style="border-top-right-radius: 16px;"
        >
          <div v-for="(item, index) in listMenu" :key="index">
            <nav-menu-link
              v-if="checkDisplay(item) && item.type == 'menu'"
              :key="index"
              :icon="item.icon"
              :mini.sync="mini"
              :target="item.target"
              :title="item.name"
              :to="{ name: item.code }"
              class="px-6 mt-1"
              bold
            >
            </nav-menu-link>
            <nav-menu-group
              v-if="(item.type === 'group' || item.type == 'domain') && checkDisplay(item)"
              :key="index"
              :icon="item.icon"
              :title="item.name"
              class="custom-icon"
              style="font-size: 12px !important;"
            >
              <nav-menu-link
                v-for="(subMenu, index) in item.children"
                v-if="checkDisplay(subMenu)"
                :key="'sub-' + index"
                :icon="subMenu.icon"
                :target="subMenu.target"
                :title="subMenu.name"
                :to="{ name: subMenu.code }"
                class="pl-7 pr-6 mt-1"
              >
              </nav-menu-link>
            </nav-menu-group>
          </div>
        </v-list>

        <v-spacer />
        <searchmenu v-if="!mini" :items="menu" item-text="name"></searchmenu>
        <div style="width: 100%; height: 50px; cursor: pointer;" @click="changeStatus()">
          <v-card class="fill-height mini-draw custom-card-bg-1 d-flex justify-center align-center">
            <v-btn icon width="100%">
              <v-icon>
                {{ mini ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left' }}
              </v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiAccountCogOutline,
  mdiAlphaTBoxOutline,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiFileOutline,
  mdiFormSelect,
  mdiHomeOutline,
  mdiTable,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import searchmenu from '@/components/SearchMenu.vue'
export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
    searchmenu,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
      },
    }
  },
  computed: {
    ...mapState('menu', ['menu']),
    ...mapState('auth', ['currentUser']),
    ...mapState('project', ['projects']),
    listMenu() {
      let menuList = []
      this.menu.forEach(menu => {
        if (menu.children) {
          if (!menu.children.some(child => child.type !== 'menu')) {
            menuList.push(menu)
            return
          }
          menu.children.forEach(child => {
            menuList.push(child)
          })
        }
      })
      return menuList
    },
    group() {
      return this.$route.query.group
    },
  },
  data() {
    return {
      miniDrawer: false,
      mini: false,
      currentMenu: null,
    }
  },
  watch: {},
  methods: {
    goToMenu(item) {
      this.currentMenu = item
      if (!this.currentUser.menu_grid) {
        this.$router.push(`/projects/${this.$route.params.id}/${item.code}`)
        return
      }
      if (this.$route.query.group == item.code) return
      this.$router.push('/projects/' + this.$route.params.id + '/group-view/?group=' + item.code)
    },

    isSelect(item) {
      if (this.$route.name == item.code) return true
      if (this.$route.name === 'group-view' && item.code == this.$route.query.group) return true
      if (!this.$route.query.group) return false
      return this.$route.query.group.split('/')[0] == 'home'
        ? this.$route.query.group.split('/')[1] === item.code
        : this.$route.query.group.split('/')[0] === item.code
    },
    async changeStatus() {
      if (!this.mini) {
        this.mini = !this.mini
        await sleep(300)
        this.miniDrawer = this.mini
      } else {
        this.miniDrawer = !this.miniDrawer
        await sleep(300)
        this.mini = this.miniDrawer
      }
    },
    checkDisplay(item) {
      if (item.code === 'home') return true
      let currentProject = this.projects.find(project => project.uuid === this.$route.params.id)
      if (item.type == 'domain' || item.type == 'group') {
        let isChildrenViewable = false
        for (let i = 0; i < item.children.length; i++) {
          if (this.checkDisplay(item.children[i])) {
            isChildrenViewable = true
            break
          }
        }
        return isChildrenViewable
      }
      if (item.type === 'menu' && currentProject) {
        return currentProject.menus.some(menu => menu.code === item.code && menu.viewable)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-menu {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
