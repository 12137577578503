<template>
  <div class="list-notifications">
    <v-tabs-items v-model="tab">
      <TypeNotification :tab="tab" :listNotifications="listNotifications" noContent="No notifications found." @close="close"/>
      <TypeNotification :tab="tab" :listNotifications="listUnreadNotifications" noContent="All notifications already read." @close="close"/>
      <TypeNotification :tab="tab" :listNotifications="listAlertNotifications" noContent="No alerts found." @close="close"/>
    </v-tabs-items>
  </div>
</template>
<script>
import TypeNotification from './type-notification.vue'

export default {
  components: {
    TypeNotification,
  },
  props: {
    tab: {
      type: Number,
      default: null,
    },
  },
  computed: {
    listNotifications() {
      return this.$store.getters['notification/listNotifications']
    },
    listUnreadNotifications() {
      return this.$store.getters['notification/listUnreadNotifications']
    },
    listAlertNotifications() {
      return this.$store.getters['notification/listAlertNotifications']
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
<style lang="scss" scoped>
.list-notifications {
  background-color: transparent;
  max-height: calc(100vh - 235px);
  overflow-y: auto;
}
</style>
