import { lineString, point } from 'turf/turf'

import { getUUIDv4 } from '@/utils/helper'

export function PointMarker(_draw, _feature = {}) {
  let parent = new Base('PointMarker', _draw, createPoint, _feature)

  parent.start = () => {
    _draw.start({ max_point: 1 })
  }
  return parent
}

function createPoint(coordinates) {
  if (!coordinates || coordinates.length < 1) {
    return []
  }
  return [point(coordinates[0])]
}

export function Base(type, _draw, createFnc, _feature = {}) {
  this.draw_type = type
  let _createOnDone = createFnc.done || createFnc
  // let _createOnBuild = createFnc.build || createFnc;

  _draw.onDone = coordinates => {
    let features = _createOnDone(coordinates)
    let feature = features[0]
    feature.properties = {}
    feature.properties.draw_coordinates = coordinates
    feature.properties.id =
      _feature && _feature.properties
        ? _feature.id
          ? _feature.id
          : _feature.properties.id
        : `${getUUIDv4()}` || `${getUUIDv4()}`
    feature.properties.draw_type = this.draw_type
    feature.id = feature.properties.id
    if (!_feature || !_feature.properties || (!_feature.id && !_feature.properties.id)) {
      this.onCreate(features)
    } else {
      this.onUpdate(features)
    }
  }

  _draw.onCancel = () => {
    this.onCancel()
  }
  _draw.convertCoordinatesToFeatures = coordinates => {
    if (!coordinates) return []
    if (coordinates.length == 1) return [point(coordinates[0])]
    if (coordinates.length > 1) return [lineString(coordinates)]
  }

  this.onCreate = () => {}
  this.onUpdate = () => {}
  this.onDelete = () => {}
  this.onCancel = () => {}
  this.destroy = () => {
    _draw.clear()
  }
  this.trash = () => {
    if (_feature) this.onDelete([_feature])
    _draw.clear()
    return [_feature]
  }

  this.start = () => {
    _draw.start()
  }
  if (_feature && _feature.properties && _feature.properties.draw_coordinates) {
    let draw_coordinates = JSON.parse(_feature.properties.draw_coordinates)
    _draw.init(draw_coordinates)
  }
}
