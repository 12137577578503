<template>
  <div class="date-range-picker" @click="isPickDate = 'custom'">
    <a-range-picker
      :class="[{ 'is-disabled': isPickDate !== 'custom' }]"
      :format="format"
      :value="date"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    flat: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    backgroundColor: { type: String, default: '' },
    isPick: { type: String, default: 'custom' },
    defaultNull: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      if (!this.value.length) this.date = this.defaultTime
      this.dateModel = this.value.length ? this.value : this.defaultTime
      if (this.date[0] > this.date[1]) {
        let tmpDate = this.date[1]
        this.date[1] = this.date[0]
        this.date[0] = tmpDate
      }
    },
  },

  computed: {
    isPickDate: {
      get() {
        return this.isPick
      },
      set(val) {
        this.$emit('update:isPick', val)
      },
    },
    dateRangeText: {
      get() {
        if (this.date[0] && this.date[1]) {
          return this.date.join(' , ')
        }
        return '-- / --'
      },
      set(val) {
        if (!val) {
          this.date = this.defaultTime
          this.dateModel = this.defaultTime
        }
      },
    },
    defaultTime() {
      if (this.defaultNull) {
        return [undefined, undefined]
      }
      return [this.getBeforeDate(), this.getCurrentDate()]
    },
    date: {
      get() {
        return this.dateModel
      },
      set(val) {
        this.dateModel = val
        this.$emit('input', this.dateModel)
      },
    },
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined,
    format: 'YYYY-MM-DD',
  }),

  created() {
    if (!this.value.length) this.date = this.defaultTime
    this.dateModel = this.value.length ? this.value : this.defaultTime
  },
  methods: {
    handleChange(value) {
      this.date = [value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')]
    },
    getCurrentDate() {
      let currentDate = new Date()
      let day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()
      let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
      let year = currentDate.getFullYear()

      return year + '-' + month + '-' + '01'
    },
    getBeforeDate() {
      let beforeDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3)
      let beforeMonth = beforeDate.getMonth() + 1 < 10 ? '0' + (beforeDate.getMonth() + 1) : beforeDate.getMonth() + 1
      let beforeYear = beforeDate.getFullYear()
      return beforeYear + '-' + beforeMonth + '-' + '01'
    },
  },
}
</script>
<style scoped>
.date-range-picker {
  width: 100%;
}
.date-picker {
  cursor: pointer;
  align-items: center;
  min-width: 265px;
}
.is-disabled {
  opacity: 0.5;
  /* border-color: #ececec3c; ant-input*/
}
::v-deep .ant-input {
  background-color: transparent;
  color: white;
  border: 2px solid #42455f;
  border-radius: 8px;
}
::v-deep .ant-calendar-picker-clear {
  background: transparent;
  color: white;
}
::v-deep .ant-calendar-range-picker-separator {
  color: white;
}
::v-deep .ant-calendar {
  background-color: rgba(31, 31, 73) !important;
  background: rgba(31, 31, 73) !important;
}
</style>
