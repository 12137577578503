<template>
  <v-dialog v-if="dialog" v-model="dialog" fullscreen>
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-layout column fill-height>
        <DialogHeader :is-close="true" :title="'Create an order'" @close="dialog = false" />
        <v-card-text class="fill-height overflow-y-hidden">
          <v-layout align-center class="overflow-y-hidden" fill-height justify-center>
            <div v-if="showMap" class="mr-2" style="height: 100%; flex: 1">
              <CardMapView
                ref="mapPreview"
                :inspect="false"
                :show-layer-control="false"
                @updateFeatureHandle="setRef"
              />
            </div>
            <div class="ml-2 overflow-y-hidden" style="height: 100%; flex: 1">
              <v-card class="pa-3 overflow-y-auto" height="100%" width="100%">
                <v-layout align-center>
                  <v-layout align-center class="pr-2" style="flex: 1">
                    <v-checkbox v-model="is_ref" :disabled="is_ref" label="" @change="revertGeometry"></v-checkbox>
                    <div v-html="refMessage"></div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="mx-3"
                          style="border: 1px solid var(--v-info-base); border-radius: 50%; text-align: center; width: 20px ; height: 20px"
                        >
                          <v-icon class="mb-2" color="info" size="18" v-bind="attrs" v-on="on">
                            mdi-information-variant
                          </v-icon>
                        </div>
                      </template>
                      <span>Info: If you edit the geometry, the reference type becomes "Custom".</span>
                    </v-tooltip>
                  </v-layout>
                  <v-layout align-center class="pl-2" style="flex: 1">
                    <v-select
                      v-model="imageSource"
                      :items="['Skysat', 'BlackSky']"
                      hide-details
                      label="Image source"
                      outlined
                      placeholder="Image source"
                    ></v-select>
                  </v-layout>
                </v-layout>
                <div class="pt-4" style="width: 100%" @keyup.enter="createTasking">
                  <PlanetCreateOrder
                    v-if="imageSource === 'Skysat'"
                    ref="formData"
                    :data.sync="currentData"
                    @addDrawToMap="featureCollections => $refs.mapPreview.drawAOI(featureCollections)"
                  />
                  <BlackSkyCreateOrder
                    v-if="imageSource === 'BlackSky'"
                    ref="formData"
                    :data.sync="currentData"
                    @addDrawToMap="featureCollections => $refs.mapPreview.drawAOI(featureCollections)"
                  />
                </div>
              </v-card>
            </div>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2">
          <v-spacer />
          <v-btn :loading="loading" color="primary" rounded @click="createTasking">Submit</v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import sleep from '@/utils/sleep'
import DatePicker from '@/components/DatePicker.vue'
import PlanetCreateOrder from '@/views/createOrder/PlanetCreateOrder.vue'
import turf from 'turf'
import { createTasking } from '@/api/tasking-api'
import BlackSkyCreateOrder from '@/views/createOrder/BlackSkyCreateOrder.vue'

export default {
  components: { BlackSkyCreateOrder, PlanetCreateOrder, DatePicker, CardMapView, DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      showMap: false,
      currentData: {},
      is_ref: true,
      imageSource: 'Skysat',
    }
  },
  props: {
    // refData: { type: String, default: 'The current geometry is referenced by selecting "Forest Fire"' },
    // The current geometry is referenced by a Change Detection Result
  },
  watch: {
    dialog(val) {
      if (!val) this.showMap = false
      this.imageSource = 'Skysat'
    },
  },
  computed: {
    refMessage() {
      switch (this.currentData.dataType) {
        case 'aoi':
          return `The current geometry is referenced by AOI: <b>${this.currentData.name}</b>`
        case 'forest_fire':
          return `The current geometry is referenced by selecting "Forest Fire": <b>${this.currentData.name}</b>`
        case 'earthquake':
          return `The current geometry is referenced by selecting "Earthquake": <b>${this.currentData.name}</b>`
        case 'vector-result':
          return `The current geometry is referenced by a Change Detection Result: <b>${this.currentData.name}</b>`
        default:
          return 'No referenced'
      }
    },
  },
  methods: {
    async openDialog(data) {
      this.dialog = true
      await sleep(250)
      this.currentData = JSON.parse(JSON.stringify(data))
      await this.displayMap()
    },
    setRef(feature) {
      this.is_ref = false
      if (feature.geometry.type === 'Point') this.$refs.formData.coordinates = feature.geometry.coordinates
    },
    revertGeometry() {
      if (this.currentData.geojson.features[0].geometry.type.includes(this.$refs.formData.geometryType)) {
        this.$refs.mapPreview.drawAOI(this.currentData.geojson)
      } else if (this.$refs.formData.geometryType === 'Point') {
        this.$refs.mapPreview.drawAOI({
          type: 'FeatureCollection',
          features: [turf.center(this.currentData.geojson)],
        })
      }
    },
    async displayMap() {
      await sleep(250)
      this.showMap = true
      await sleep(350)
      this.$refs.mapPreview.drawAOI(this.currentData.geojson)
    },
    async createTasking() {
      try {
        this.loading = true
        if (!this.$refs.mapPreview.getDraw()[0] || !this.$refs.mapPreview.getDraw()[0].geometry) {
          this.$store.commit('message/SHOW_ERROR', 'No data')
          this.dialog = false
          return
        }
        let params = this.$refs.formData.getData()
        if (!params) return
        params.image_source = this.imageSource
        params.geometry = this.$refs.mapPreview.getDraw()[0].geometry
        if (!this.is_ref) {
          params.ref_id = null
          params.ref_type = 'custom'
        }
        if (!params.ref_type) params.ref_type = 'custom'
        await createTasking({
          projectId: this.$route.params.id,
          params: params,
        })
        this.dialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
