<template>
  <v-snackbar
    v-model="snackBar"
    :color="color"
    :timeout="timeout"
    bottom
    center
    class="text-capitalize"
    style="z-index: 999"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
import { mapState } from '@/store/ults'

export default {
  name: 'message',
  data: () => ({
    timeout: 3000,
    snackbarColor: '#fc0303',
  }),
  computed: {
    ...mapState('message', ['text', 'snackBar', 'color']),
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped></style>
