<template>
  <div class="notifications" ref="notifications" @blur="handleBlur" @click="handleClick" tabindex="0">
    <IconNotification :unreadNotificationsCount="unreadNotificationsCountAll" @toggleNotification="toggleNotification" />
    <div v-if="notificationMenu" class="content-notifications" transition="scroll-x-transition">
      <v-card class="custom-card-bg-gradient" height="100%">
        <v-card-text>
          <v-layout align-center class="pb-2">
            <h3 class="mr-2 ml-2">
              Notifications
            </h3>
            <v-spacer></v-spacer>
            <MenuNotification @closeNotification="closeNotification"/>
            <span>|</span>
            <v-tooltip bottom color="#000000c4">
              <template #activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" @click="notificationMenu = false" icon small>
                  <v-icon small class="ml-1 mr-1">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-layout>
          <v-tabs v-model="currentTab">
            <v-tab>All</v-tab>
            <v-tab>Unread</v-tab>
            <v-tab>Alert</v-tab>
          </v-tabs>

          <BarFindNotification @searchData="getListNotifications" />
          <v-progress-linear
            indeterminate
            color="primary secondary"
            v-if="loading"
          ></v-progress-linear>
          <ListNotifications :tab="currentTab" @close="closeNotification"/>
        </v-card-text>
      </v-card>
    </div>

    <AlertBar ref="alertBar" @showDetail="showDetailAlert"/>
  </div>
</template>
<script>
import MenuNotification from './menu-notification.vue'
import ListNotifications from './list-notifications.vue'
import BarFindNotification from './bar-find-notifications.vue'
import IconNotification from './icon-notification.vue'
import AlertBar from './alert-bar.vue'

export default {
  components: {
    MenuNotification,
    ListNotifications,
    BarFindNotification,
    IconNotification,
    AlertBar,
  },
  data: () => {
    return {
      notificationMenu: false,
      loading: false
    }
  },
  computed: {
    isOpenChildElement() {
      return this.$store.getters['notification/isOpenChildElement']
    },
    unreadNotificationsCountAll() {
      return this.$store.getters['notification/unreadNotificationsCountAll']
    },
    alertNotification() {
      return this.$store.getters['notification/alertNotification']
    },
    currentTab: {
      get() {
        return this.$store.getters['notification/currentTab']
      },
      set(val) {
        this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'currentTab', value: val})
      }
    }
  },
  methods: {
    handleClick() {
      this.$refs.notifications.focus(); // Ensure the div is focused
    },
    handleBlur(e) {
      setTimeout(() => {
        // Check if the new focused element is within the menu
        if (!this.$refs.notifications?.contains(document.activeElement) && !this.isOpenChildElement) {
          this.notificationMenu = false
        }
      }, 100);
    },
    // data
    async getListNotifications() {
      this.loading = true
      await this.$store.dispatch('notification/getListNotifications')
      this.loading = false
    },
    showDetailAlert() {
      this.notificationMenu = true
      this.currentTab = 2 // to alert
    },
    // handle
    toggleNotification() {
      this.notificationMenu = !this.notificationMenu
    },
    closeNotification() {
      this.notificationMenu = false
    }
  },
  mounted() {
    this.$store.dispatch('notification/getUnreadNotificationsCount')
    this.getListNotifications({ alert: true }) // get alert notification
    this.$store.dispatch('notification/getMenuServices')
  },
  watch: {
    async notificationMenu(val) {
      if (val) {
        this.$store.dispatch('notification/getUnreadNotificationsCount')
        this.getListNotifications()
      }
    },
    currentTab() {
      this.getListNotifications()
    },
    alertNotification: {
      handler(newAlert) {
        if (Object.keys(newAlert).length !== 0) {
          this.$refs.alertBar.show(this.alertNotification.description, true)
        } else {
          this.$refs.alertBar.close()
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  &.v-card__text {
    padding: 16px 0 4px !important;
  }
  &.v-card__text > .v-application.theme--dark .v-tabs:not(.v-tabs--vertical) {
    box-shadow: none !important;
  }

  &.theme--dark.v-list, .theme--dark.v-tabs > .v-tabs-bar, .theme--dark.v-tabs-items {
    background-color: transparent !important;
  }

  &.v-tab {
    text-transform: none !important;
  }
}

.notifications {
  .content-notifications {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100vh - 64px);
    width: 25vw;
    background-color: black;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0 6px 10px 0 #383838;

    .handle-find {
      padding: 10px 2px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .notifications {
    .content-notifications {
      width: 35vw;
    }
  }
}
</style>
