var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{on:{"click":_vm.showDetail,"mouseover":function($event){_vm.showActions = true},"mouseleave":function($event){_vm.showActions = false}}},[_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12"}},[_c('div',{staticClass:"title-noti"},[_c('span',{staticClass:"reference_type",style:(_vm.reference_type[_vm.item.reference_type].styles)},[_vm._v(_vm._s(_vm.reference_type[_vm.item.reference_type].name))]),(Object.prototype.hasOwnProperty.call(_vm.item.reference_data, 'project'))?_c('span',{staticClass:"project ml-4"},[_vm._v("Project: "+_vm._s(_vm.item.reference_data.project.name))]):_vm._e()]),_c('div',{staticClass:"description-noti"},[_vm._v(_vm._s(_vm.item.description))]),_c('div',{staticClass:"time"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-clock-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("calculateDateDifference")(_vm.item.created_at)))])],1)]),_c('div',{staticClass:"alert-icon"},[(_vm.item.is_alert)?_c('v-icon',{staticClass:"alert"},[_vm._v("mdi-alert-outline")]):_vm._e()],1),(_vm.showActions)?_c('div',{staticClass:"actions d-flex justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"clickable",attrs:{"x-small":"","icon":"","width":"60","height":"60"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send-outline")])],1)]}}],null,false,1297589528)},[_c('span',[_vm._v("Show detail")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"clickable",attrs:{"x-small":"","icon":"","width":"60","height":"60"},on:{"click":function($event){$event.stopPropagation();return _vm.markAsRead.apply(null, arguments)}}},'v-btn',attrs,false),on),[(!_vm.item.read)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle-outline")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")])],1)]}}],null,false,1493482168)},[_c('span',[_vm._v(_vm._s(_vm.item.read ? 'Mark as unread' : 'Mark as read'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"clickable",attrs:{"x-small":"","icon":"","width":"60","height":"60"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteNoti.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("icon-trash")])],1)]}}],null,false,1971903561)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }