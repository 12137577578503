<template>
  <v-card height="100%" style="background-color: transparent" width="100%">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="fill-height pa-2" column>
      <div class="py-2">
        <v-select
          v-model="filter.aoi_id"
          :items="AOIs"
          dense
          hide-details
          item-text="name"
          item-value="uuid"
          label="Select AOI"
          outlined
          placeholder="AOI"
          @change="zoomToAOI"
        />
      </div>
      <div class="py-2">
        <DateRangerPicker v-model="filter.date_range" dense label="Select time" left outlined />
      </div>
      <div class="py-2">
        <v-text-field
          v-model.number="filter.cloud"
          dense
          hide-details
          label="Cloud percent"
          max="100"
          min="0"
          outlined
          placeholder="Cloud percent"
          suffix="%"
          type="number"
          @change="cloudChange"
        />
      </div>
      <div class="py-2 align-center text-center">
        <v-btn :loading="loading" color="primary" rounded width="100%" @click="searchImage">
          Search
        </v-btn>
      </div>
      <v-divider class="my-2" />
      <v-layout class="overflow-y-auto" column fill-height>
        <v-expansion-panels class="custom-expansion">
          <v-expansion-panel v-for="(item, i) in group" :key="i">
            <v-expansion-panel-header class="py-1">
              <v-layout align-center>
                <div class="mr-3" style="width: 75px; height: 75px">
                  <v-img :lazy-src="item.thumbnail" :src="item.thumbnail">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-layout column justify-center>
                  <div>Date: {{ item.date }}</div>
                  <div class="caption pt-2">Total item: {{ item.images.length }}</div>
                </v-layout>
                <v-spacer />
                <v-btn
                  :color="
                    !item.images.filter(image => image.display).length
                      ? ''
                      : item.images.filter(image => image.display).length === item.images.length
                      ? 'info'
                      : 'warning'
                  "
                  icon
                  small
                  @click.native.stop="changeDisplayGroup(item)"
                >
                  <v-icon size="22">icon-eye</v-icon>
                </v-btn>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout column>
                <div
                  v-for="(image, index) in item.images"
                  :key="index"
                  class="my-1 custom-card-bg-1"
                  style="width: 100%; min-height: 75px; position: relative"
                >
                  <BorderArrow v-if="image.filter" />
                  <v-layout align-center class="pa-2" fill-height>
                    <div style="width: 75px; height: 75px">
                      <v-img :lazy-src="image.thumbnail" :src="image.thumbnail">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <v-layout class="pl-4" column fill-height justify-center>
                      <div>Acquired: {{ convertDate(image.properties.acquired) }}</div>
                      <div class="caption pt-2">
                        <v-layout align-center>
                          Cloud percent: {{ image.properties.cloud_percent }}%
                          <v-spacer />
                          <div>
                            <v-btn icon small @click="zoomTo(image)">
                              <v-icon size="21">icon-gps</v-icon>
                            </v-btn>
                            <v-btn :color="image.display ? 'info' : ''" icon small @click="changeDisplay(image)">
                              <v-icon size="22">icon-eye</v-icon>
                            </v-btn>
                          </div>
                        </v-layout>
                      </div>
                      <div class="caption pt-2">Area coverage: {{ image.area_coverage.toFixed(2) }}%</div>
                    </v-layout>
                  </v-layout>
                </div>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </v-layout>
  </v-card>
</template>
<script>
import { mapState } from '@/store/ults'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import { getDetailAOI } from '@/api/aoi-api'
import PlanetApi from '@/utils/planet/planetApi'
import BorderArrow from '@/components/BorderArrow.vue'
import ConvertDate from '@/utils/convertDate'
import bbox from '@turf/bbox'
import CustomIcon from '@/components/CustomIcon.vue'
import sleep from '@/utils/sleep'
import polygonClipping from 'polygon-clipping'
import turf from 'turf'

export default {
  name: 'LayerControl',
  components: { CustomIcon, BorderArrow, DateRangerPicker },
  data() {
    return {
      loading: false,
      aoiGeojson: {},
      group: [],
      addedImage: [],
      oldAOI: undefined,
      filter: {
        cloud: 100,
        geojson: undefined,
        aoi_id: undefined,
        date_range: undefined,
        types: [
          {
            display_description: '8-band PlanetScope imagery that is framed as captured.',
            display_name: 'PlanetScope Scene',
            id: 'PSScene',
          },
        ],
      },
    }
  },
  props: {
    currentLayers: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    'filter.aoi_id'() {
      this.group = []
      this.$emit('removeSearchLayer')
    },
    currentLayers() {
      let removedLayer = []
      this.addedImage.forEach(image => {
        if (!this.currentLayers.some(layer => layer.dataId === image.id)) {
          removedLayer.push(image)
        }
      })
      removedLayer.forEach(layer => {
        layer.display = false
        let index = this.addedImage.findIndex(image => image.id === layer.id)
        if (index >= 0) this.addedImage.splice(index, 1)
      })
    },
  },
  computed: {
    ...mapState('AOI', ['AOIs']),
  },
  methods: {
    cloudChange(val) {
      if (this.filter.cloud === null || this.filter.cloud < 0) this.filter.cloud = 0
      else if (this.filter.cloud > 100) this.filter.cloud = 100
    },
    async searchImage() {
      try {
        if (!this.filter.aoi_id) {
          this.$store.commit('message/SHOW_ERROR', 'AOI is required')
          return
        }
        await this.getGeometry()
        this.group = []
        this.$emit('removeSearchLayer')
        const images = await PlanetApi.quickSearch(this.filter)
        images.forEach(image => {
          let polygonIntersection = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  coordinates: polygonClipping.intersection(
                    image.geometry.coordinates,
                    this.aoiGeojson.features[0].geometry.coordinates,
                  )[0],
                  type: 'Polygon',
                },
              },
            ],
          }
          image.area_coverage = (turf.area(polygonIntersection) / turf.area(this.aoiGeojson)) * 100
          let index = this.group.findIndex(val => val.date === ConvertDate.dateFormatted(image.properties.acquired))
          if (index >= 0) {
            this.group[index].images.push(image)
          } else {
            this.group.push({
              date: ConvertDate.dateFormatted(image.properties.acquired),
              images: [image],
              thumbnail: image.thumbnail,
            })
          }
        })
        this.group = this.group.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    zoomToAOI() {
      let aoi = this.AOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      this.$emit('zoomTo', aoi.bbox)
      this.addToMap(aoi)
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.aoiGeojson = res.geojson
        this.$emit('addGeoJsonToMap', res)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
        this.oldAOI = AOI.uuid
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getGeometry() {
      try {
        this.loading = true
        let res = await getDetailAOI({
          projectId: this.$route.params.id,
          id: this.filter.aoi_id,
          payload: { weather: false },
        })
        this.filter.geojson = res.data.geojson
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    changeDisplayGroup(group) {
      group.images.forEach(image => this.changeDisplay(image))
    },
    async changeDisplay(image) {
      let index = this.addedImage.findIndex(img => img.id === image.id)
      if (index >= 0) {
        this.addedImage.splice(index, 1)
        this.$emit('removeLayer', image.id)
        image.display = false
      } else {
        this.addedImage.push(image)
        this.$emit('addThumbnailToMap', image, this.filter.aoi_id)
        this.loading = true
        await sleep(400)
        this.loading = false
        this.zoomTo(image)
        image.display = true
      }
    },
    zoomTo(image) {
      this.$emit('zoomTo', bbox(image.geometry))
    },
    convertDate(date) {
      return ConvertDate.dateTimeFormatted(date)
    },
  },
}
</script>

<style scoped></style>
