import Vue from 'vue'
import Vuex from 'vuex'
import { s } from './ults'

import auth from '@/store/modules/auth'
import message from '@/store/modules/message'
import menu from '@/store/modules/menu'
import layers from '@/store/modules/layers'
import AOI from '@/store/modules/AOI'
import tab from '@/store/modules/tabs'
import legend from '@/store/modules/legend'
import project from '@/store/modules/project'
import service from '@/store/modules/service'
import setting from '@/store/modules/setting'
import socket from '@/store/modules/socket'
import notification from './modules/notification'
import boundary from './modules/boundary'
import chat from './modules/chat'
import detection from './modules/detection'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    s,
  },
  actions: {
    s,
  },
  modules: {
    namespaced: true,
    auth,
    message,
    menu,
    layers,
    AOI,
    tab,
    legend,
    project,
    service,
    setting,
    socket,
    notification,
    boundary,
    chat,
    detection
  },
})
