<template>
  <v-dialog v-model="deleteDialog" persistent width="550">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="titleDialog" @close="deleteDialog = false" />
      <v-card-text>
        <v-layout align-center justify-center>
          <div style="width: 275px; height: 220px">
            <v-img :src="require('@/assets/svg/delete.svg')" />
          </div>
        </v-layout>
        <div v-if="!message" style="text-align: center; width: 100%">
          Are you sure, you want to delete item
          <b style="color: red; text-decoration: underline">{{ currentItem.name }}</b> !
        </div>
        <div v-else style="text-align: center; width: 100%">
          {{ message }}
        </div>
        <v-layout class="pt-5">
          <v-spacer />
          <v-btn color="error" :loading="loading" rounded @click="submitData">
            Delete
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  name: 'DeleteDialog',
  components: { DialogHeader },
  data() {
    return {
      deleteDialog: false,
      currentItem: {},
      loading: false,
    }
  },
  props: {
    titleDialog: { type: String, default: 'Delete' },
    submitDelete: Function,
    message: { type: String, default: '' },
  },
  watch: {
    deleteDialog(val) {
      if (!val) this.currentItem = {}
    },
  },
  methods: {
    openDialog(item) {
      this.currentItem = { ...item }
      this.deleteDialog = true
    },
    async submitData() {
      try {
        this.loading = true
        await this.submitDelete(this.currentItem)
      } catch(e) {
        console.warn('Error when call delete function: ', e)
      } finally {
        this.loading = false
        this.deleteDialog = false
      }
    },
  },
}
</script>

<style scoped></style>
